import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignUp from 'components/SignUp';
import { signUpAction } from 'js/actions/userActions';

const SignUpContainer = (props) => {
  const {
    signUp,
    error,
    isLoading,
  } = props;

  return (
    <SignUp
      invite={{}}
      isLoading={isLoading}
      isWaitlist={false}
      error={error}
      onSignUp={signUp}
      title="Sign up"
    />
  );
};

SignUpContainer.propTypes = {
  signUp: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { signUpError },
    loading: { isSignUpLoading },
  } = state;

  return {
    error: signUpError || {},
    isLoading: isSignUpLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signUp: (payload) => dispatch(signUpAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpContainer);
