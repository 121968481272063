import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UploadForm from 'components/UploadForm';
import { uploadContactsAction } from 'js/actions/contactActions';
import { csvHeaders, fieldOptions } from 'js/constants/contacts';

const UploadContactsContainer = (props) => {
  const {
    error,
    isLoading,
    isVisible,
    onClose,
    uploadContacts,
  } = props;

  return (
    <UploadForm
      error={error}
      fieldOptions={fieldOptions}
      headers={csvHeaders}
      template="/files/contacts.csv"
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSubmit={uploadContacts}
    />
  );
};

UploadContactsContainer.propTypes = {
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadContacts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { uploadContactsError },
    loading: { isUploadContactsLoading },
  } = state;

  return {
    error: uploadContactsError || {},
    isLoading: isUploadContactsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadContacts: (payload) => dispatch(uploadContactsAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadContactsContainer);
