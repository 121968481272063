import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import CloseButton from 'components/CloseButton';
import FormSelect from 'components/FormSelect';
import Modal from 'components/Modal';
import {
  frequencies,
  stages,
  models,
  types,
} from 'js/constants/contacts';
import { formatExternalLink } from 'js/utils';
import './styles.scss';

const ContactForm = (props) => {
  const {
    contact: {
      firstName: existingFirstName,
      lastName: existingLastName,
      email: existingEmail,
      company: existingCompany,
      type: existingType,
      frequency: existingFrequency,
      checkSmall: existingCheckSmall,
      checkBig: existingCheckBig,
      stage: existingStage,
      model: existingModel,
      calendly: existingCalendly,
      linkedin: existingLinkedin,
    } = {},
    error,
    header,
    submitText,
    isLoading,
    isVisible,
    onClose,
    onSubmit,
  } = props;

  const [firstName, setFirstName] = useState(existingFirstName || '');
  const [lastName, setLastName] = useState(existingLastName || '');
  const [email, setEmail] = useState(existingEmail || '');
  const [company, setCompany] = useState(existingCompany || '');
  const [type, setType] = useState(existingType || '');
  const [frequency, setFrequency] = useState(existingFrequency || '');
  const [checkSmall, setCheckSmall] = useState(existingCheckSmall / 1000 || '');
  const [checkBig, setCheckBig] = useState(existingCheckBig / 1000 || '');
  const [stage, setStage] = useState(existingStage || '');
  const [model, setModel] = useState(existingModel || '');
  const [calendly, setCalendly] = useState(existingCalendly || '');
  const [linkedin, setLinkedin] = useState(existingLinkedin || '');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isButtonDisabled = (isLoading || !(firstName && lastName));

  const {
    detail,
    errors: {
      first_name: [firstNameError] = [],
      last_name: [lastNameError] = [],
      email: [emailError] = [],
      company: [companyError] = [],
      type: [typeError] = [],
      frequency: [frequencyError] = [],
      check_small: [checkSmallError] = [],
      check_big: [checkBigError] = [],
      stage: [stageError] = [],
      model: [modelError] = [],
      calendly: [calendlyError] = [],
      linkedin: [linkedinError] = [],
    } = {},
  } = error;

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);

      if (!detail) {
        onClose();
      }
    }
  }, [isLoading]);

  const shouldRenderFormError = detail && !(
    firstNameError
    || lastNameError
    || emailError
    || companyError
    || typeError
    || frequencyError
    || checkSmallError
    || checkBigError
    || stageError
    || modelError
    || calendlyError
    || linkedinError
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      firstName,
      lastName,
      email,
      company,
      type,
      frequency,
      checkSmall: checkSmall * 1000,
      checkBig: checkBig * 1000,
      stage,
      model,
      calendly: formatExternalLink(calendly),
      linkedin: formatExternalLink(linkedin),
    };

    setIsSubmitted(true);
    onSubmit(payload);
  };

  return (
    <Modal centered show={isVisible} onHide={onClose}>
      <Container fluid className="add-contact-container">
        <CloseButton onClick={onClose} />
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-4">
            <h3 className="bold-text dark-blue-text">
              {header}
            </h3>
          </div>
          <Form.Group controlId="first-name">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              First Name
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!firstNameError}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {firstNameError && (
              <Form.Control.Feedback type="invalid">
                {firstNameError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="last-name">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Last Name
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!lastNameError}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {lastNameError && (
              <Form.Control.Feedback type="invalid">
                {lastNameError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Email (Optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!emailError}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="company">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Company (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!companyError}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            {companyError && (
              <Form.Control.Feedback type="invalid">
                {companyError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="type">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Type (optional)
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={types}
              isInvalid={!!typeError}
              size="medium"
              value={type}
              onChange={setType}
            />
            {typeError && (
              <Form.Control.Feedback type="invalid">
                {typeError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="frequency">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Contact frequency (optional)
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={frequencies}
              isInvalid={!!frequencyError}
              size="medium"
              value={frequency}
              onChange={setFrequency}
            />
            {frequencyError && (
              <Form.Control.Feedback type="invalid">
                {frequencyError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="check-small">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Smallest check (in thousands, optional)
            </Form.Label>
            <Form.Control
              type="number"
              isInvalid={!!checkSmallError}
              value={checkSmall}
              onChange={(e) => setCheckSmall(e.target.value)}
            />
            {checkSmallError && (
              <Form.Control.Feedback type="invalid">
                {checkSmallError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="check-big">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Largest check (in thousands, optional)
            </Form.Label>
            <Form.Control
              type="number"
              isInvalid={!!checkBigError}
              value={checkBig}
              onChange={(e) => setCheckBig(e.target.value)}
            />
            {checkBigError && (
              <Form.Control.Feedback type="invalid">
                {checkBigError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="stage">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Stage preference (optional)
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={stages}
              isInvalid={!!stageError}
              size="medium"
              value={stage}
              onChange={setStage}
            />
            {stageError && (
              <Form.Control.Feedback type="invalid">
                {stageError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="model">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Model preference (optional)
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={models}
              isInvalid={!!modelError}
              size="medium"
              value={model}
              onChange={setModel}
            />
            {modelError && (
              <Form.Control.Feedback type="invalid">
                {modelError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="calendly">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Calendly (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!calendlyError}
              value={calendly}
              onChange={(e) => setCalendly(e.target.value)}
            />
            {calendlyError && (
              <Form.Control.Feedback type="invalid">
                {calendlyError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="linkedin">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              LinkedIn (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!linkedinError}
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
            />
            {linkedinError && (
              <Form.Control.Feedback type="invalid">
                {linkedinError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="pt-3 text-center">
            <span className="pr-3">
              <Button
                variant="border"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
            </span>
            <span className="pl-3">
              <Button
                variant="gray"
                type="submit"
                disabled={isButtonDisabled}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  submitText
                )}
              </Button>
            </span>
            {shouldRenderFormError && (
              <div className="invalid-feedback text-right">{detail}</div>
            )}
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

ContactForm.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    type: PropTypes.string,
    frequency: PropTypes.string,
    checkSmall: PropTypes.number,
    checkBig: PropTypes.number,
    stage: PropTypes.string,
    model: PropTypes.string,
    calendly: PropTypes.string,
    linkedin: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      first_name: PropTypes.arrayOf(PropTypes.string),
      last_name: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.arrayOf(PropTypes.string),
      company: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.arrayOf(PropTypes.string),
      frequency: PropTypes.arrayOf(PropTypes.string),
      check_small: PropTypes.arrayOf(PropTypes.string),
      check_big: PropTypes.arrayOf(PropTypes.string),
      stage: PropTypes.arrayOf(PropTypes.string),
      model: PropTypes.arrayOf(PropTypes.string),
      calendly: PropTypes.arrayOf(PropTypes.string),
      linkedin: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  header: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
