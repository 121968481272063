import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Footer from 'components/Footer';

const FooterContainer = (props) => {
  const { user: { token } } = props;
  const theme = token ? 'light' : 'dark';

  return <Footer theme={theme} />;
};

FooterContainer.propTypes = {
  user: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps)(FooterContainer);
