import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import CloseButton from 'components/CloseButton';
import Modal from 'components/Modal';
import './styles.scss';

const FilterForm = (props) => {
  const {
    fields,
    filters,
    isVisible,
    onClose,
    onSubmit,
  } = props;

  const [newFilters, setNewFilters] = useState({ ...filters });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(newFilters);
    onClose();
  };

  const handleClose = () => {
    setNewFilters({ ...filters });
    onClose();
  };

  const updateFilters = (field, option) => {
    const { [field]: [...prevSelected], ...restOfFilters } = newFilters;

    let selected;
    if (prevSelected.includes(option)) {
      selected = prevSelected.filter((item) => item !== option);
    } else {
      selected = [...prevSelected, option];
    }

    setNewFilters({ ...restOfFilters, [field]: selected });
  };

  const clearFilters = () => {
    const noFilters = Object.keys(newFilters).reduce((acc, field) => {
      acc[field] = [];
      return acc;
    }, {});

    setNewFilters(noFilters);
  };

  const content = Object.keys(fields).map((field) => {
    const { [field]: { display, options } } = fields;

    const checks = options.map(([option, optionDisplay]) => (
      <Form.Group key={option} controlId={`${field}-${option}`}>
        <Form.Check type="checkbox">
          <Form.Check.Input
            type="checkbox"
            checked={newFilters[field].includes(option)}
            onChange={() => updateFilters(field, option)}
            className="form-check-gray"
          />
          <Form.Check.Label className="dark-blue-text ml-3">
            {optionDisplay || option}
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    ));

    return (
      <Col key={field} xs={6} className="py-2">
        <div className="pb-3">
          <h4 className="bold-text dark-blue-text">{display}</h4>
        </div>
        {checks}
      </Col>
    );
  });

  return (
    <Modal centered show={isVisible} onHide={handleClose}>
      <Container fluid className="filter-form-container">
        <CloseButton onClick={handleClose} />
        <button
          type="button"
          className="clear-button"
          onClick={clearFilters}
        >
          Clear All
        </button>
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-3">
            <h3 className="bold-text dark-blue-text">
              Filters
            </h3>
          </div>
          <Row>
            {content}
          </Row>
          <div className="pt-3 text-center">
            <span className="pr-3">
              <Button
                variant="border"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </span>
            <span className="pl-3">
              <Button variant="gray" type="submit">Apply Filters</Button>
            </span>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

FilterForm.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FilterForm;
