import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import './styles.scss';

const TaskInput = (props) => {
  const {
    isCompleted,
    onToggle,
    onSave,
    text,
  } = props;

  const [newText, setNewText] = useState(text);

  const handleBlur = () => {
    if (newText !== text) {
      onSave(newText);
    }
  };

  let className = 'task-input';
  if (isCompleted) {
    className += ' completed';
  }

  return (
    <Form.Group className="task-input-group" controlId="task-input">
      <Form.Check type="checkbox">
        <Form.Check.Input
          type="checkbox"
          checked={isCompleted}
          onChange={onToggle}
          className="form-check-pale"
        />
      </Form.Check>
      <Form.Control
        type="text"
        value={newText}
        onBlur={handleBlur}
        onChange={(e) => setNewText(e.target.value)}
        className={className}
      />
    </Form.Group>
  );
};

TaskInput.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default TaskInput;
