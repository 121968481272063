import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import './styles.scss';

const CustomToggle = forwardRef(({ children, className, onClick }, ref) => (
  <button
    ref={ref}
    type="button"
    className={className}
    onClick={(e) => {
      onClick(e);
      e.stopPropagation();
    }}
  >
    {children}
  </button>
));

const CustomMenu = forwardRef(({ children, className }, ref) => (
  <div ref={ref} className={className}>{children}</div>
));

const FormSelect = ({
  placeholder,
  options,
  isInvalid,
  size,
  value,
  onChange,
}) => {
  let className = 'text-left form-control select';

  if (!value) {
    className += ' placeholder';
  }

  if (isInvalid) {
    className += ' is-invalid';
  }

  const [, displayText] = options.find(([val]) => value === val) || [];

  const styleSize = size === 'small' ? ' form-select-small' : '';

  return (
    <Dropdown className={`form-select form-select-custom${styleSize}`}>
      <Dropdown.Toggle
        as={CustomToggle}
        className={className}
      >
        {displayText || value || placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        {options.map(([val, display]) => (
          <Dropdown.Item
            key={val}
            onClick={(e) => {
              onChange(val);
              e.stopPropagation();
            }}
          >
            {display || val}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomToggle.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

CustomMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

FormSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.array).isRequired,  // eslint-disable-line
  isInvalid: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormSelect;
