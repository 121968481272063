import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  receiveCompaniesSearchResultsAction,
  receiveSearchResultCompanyDetailsAction,
} from 'js/actions/companyActions';
import { getLoggedInHeaders } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const searchCompanies = async (action, { dispatch, getState }) => {
  const {
    payload: { searchTerm, context },
  } = action;

  dispatch(setLoadingAction({ isSearchCompaniesLoading: true }));
  const {
    user: { token, id },
  } = getState();

  let query = `?q=${searchTerm}`;
  if (context) {
    query += `&context=${context}`;
  }

  const response = await fetch(`${API_URL}/companies/search/${query}`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
    user: {
      id,
    },
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ searchCompaniesError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveCompaniesSearchResultsAction(responseJson));
  }

  dispatch(setLoadingAction({ isSearchCompaniesLoading: false }));
};

const getCompany = async (action, { dispatch, getState }) => {
  const {
    payload: { id, source },
  } = action;

  dispatch(setLoadingAction({ isGetCompanyLoading: true }));
  const {
    user: { token },
  } = getState();

  const response = await fetch(`${API_URL}/companies/${id}?source=${source}`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getCompanyError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(
      receiveSearchResultCompanyDetailsAction({ ...responseJson, source }),
    );
  }

  dispatch(setLoadingAction({ isGetCompanyLoading: false }));
};

const companyMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'SEARCH_COMPANIES':
      await searchCompanies(action, store);
      break;
    case 'GET_COMPANY':
      await getCompany(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default companyMiddleware;
