import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const ChangeEmail = (props) => {
  const {
    changeEmail,
    error,
    isAlternate,
    isLoading,
    updateUser,
    user: {
      alternateEmail,
      email: prevEmail,
    },
  } = props;

  const [email, setEmail] = useState(isAlternate ? alternateEmail : prevEmail);

  const isButtonDisabled = isLoading || !(email || isAlternate);

  const {
    detail,
    errors: {
      email: [emailError] = [],
      alternate_email: [alternateEmailError] = [],
    } = {},
  } = error;

  const currentError = isAlternate ? alternateEmailError : emailError;

  const shouldRenderFormError = detail && !currentError;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isAlternate) {
      updateUser({ alternateEmail: email });
    } else {
      changeEmail({ email });
    }
  };

  return (
    <Container fluid className="change-email-container">
      <div className="change-email-view-container">
        <div className="pb-4">
          <h3 className="bold-text dark-blue-text">
            {isAlternate && 'Alternate'} Email
          </h3>
        </div>
        <Form noValidate onSubmit={handleSubmit} className="change-email-form">
          <div className="pb-3">
            <h4 className="bold-text dark-blue-text">
              Change {isAlternate && 'Alternate'} Email Address
            </h4>
          </div>
          <Form.Group controlId="email">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Email
            </Form.Label>
            <Form.Control
              type="email"
              isInvalid={!!currentError}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {currentError && (
              <Form.Control.Feedback type="invalid">
                {currentError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="pt-4 text-right">
            <Button
              variant="border"
              type="submit"
              disabled={isButtonDisabled}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Save'
              )}
            </Button>
            {shouldRenderFormError && (
              <div className="invalid-feedback text-right">{detail}</div>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

ChangeEmail.propTypes = {
  changeEmail: PropTypes.func.isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      alternate_email: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isAlternate: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    alternateEmail: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default ChangeEmail;
