import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Sidebar from 'components/Sidebar';
import { useLocation } from 'react-router-dom';

const SidebarContainer = (props) => {
  const { tasks } = props;

  const location = useLocation();

  // Access URL pathname.
  const pathname = location.pathname.replace(/^\//, '');

  // states
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedNav, setSelectedNav] = useState(pathname);

  // Set the selected navigation as per the pathname.
  useEffect(() => {
    setSelectedNav(pathname);
  }, [pathname]);

  // Close the sidebar when location changes.
  useEffect(() => {
    setIsExpanded(false);
  }, [location]);

  const onToggle = () => setIsExpanded(!isExpanded);

  const onSelect = (eventKey) => setSelectedNav(eventKey);

  return (
    <Sidebar
      isExpanded={isExpanded}
      onToggle={onToggle}
      selectedNav={selectedNav}
      onSelect={onSelect}
      tasks={tasks}
    />
  );
};

SidebarContainer.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

// Get the tasks from the redux store.
const mapStateToProps = ({ tasks: { tasks } }) => ({ tasks });

// export default SidebarContainer;
export default connect(mapStateToProps)(SidebarContainer);
