import {
  createPersistedReducer,
  formatCompany,
  formatCompanySearchResult,
  formatSearchResultCompanyDetails,
} from './utils';

const receiveCompaniesSearchResults = (companyState, { payload }) => ({
  ...companyState,
  companiesSearchResult: {
    ownCompanies: payload.data.own_companies
      ? payload.data.own_companies.map(formatCompanySearchResult)
      : [],
    crunchBaseCompanies: payload.data.crunchbase_companies
      ? payload.data.crunchbase_companies.map(formatCompanySearchResult)
      : [],
  },
});

const receiveSearchResultCompanyDetails = (companyState, { payload }) => ({
  ...companyState,
  searchResultCompanyDetail:
    payload.source === 'user'
      ? formatCompany(payload.data)
      : formatSearchResultCompanyDetails(payload.data.properties),
});

const receiveCompany = (companyState, { payload }) => {
  const { companies: prevCompanies } = companyState;

  const company = formatCompany(payload.data);

  const companies = prevCompanies.filter((cmp) => cmp.id !== company.id);

  return {
    ...companyState,
    companies: [...companies, company],
  };
};

const clearCompanies = (companyState) => ({
  ...companyState,
  companies: [],
});

const clearCompaniesSearchResults = (companyState) => ({
  ...companyState,
  companiesSearchResult: {
    ownCompanies: [],
    crunchBaseCompanies: [],
  },
});

const clearSearchResultCompanyDetails = (companyState) => ({
  ...companyState,
  searchResultCompanyDetail: {},
});

const companyReducer = createPersistedReducer(
  {
    companies: [],
    companiesSearchResult: {
      ownCompanies: [],
      crunchBaseCompanies: [],
    },
    searchResultCompanyDetail: {},
  },
  {
    RECEIVE_COMPANIES_SEARCH_RESULTS: receiveCompaniesSearchResults,
    RECEIVE_SEARCH_RESULT_COMPANY_DETAILS: receiveSearchResultCompanyDetails,
    CLEAR_COMPANIES: clearCompanies,
    CLEAR_COMPANIES_SEARCH_RESULTS: clearCompaniesSearchResults,
    CLEAR_SEARCH_RESULT_COMPANY_DETAILS: clearSearchResultCompanyDetails,
    RECEIVE_COMPANY: receiveCompany,
  },
  'companies',
);

export default companyReducer;
