import React from 'react';
import PropTypes from 'prop-types';
import SideNav from '@trendmicro/react-sidenav';
import { NavLink } from 'react-router-dom';
import { ContactsIcon, TasksIcon } from 'assets/icons';
import dealsIcon from 'assets/images/deals.svg';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './styles.scss';

const Sidebar = (props) => {
  const {
    isExpanded, onToggle, selectedNav, onSelect, tasks,
  } = props;

  const incompleteTasksCount = tasks.filter(
    ({ isCompleted }) => !isCompleted,
  )?.length;

  return (
    <SideNav
      expanded={isExpanded}
      className="sidenav position-relative bg-white"
      onToggle={onToggle}
    >
      <SideNav.Toggle className="sidenav-toggle" />
      <SideNav.Nav
        defaultSelected={selectedNav}
        onSelect={onSelect}
        className="sidenav-nav"
      >
        <SideNav.NavItem eventKey="deals" id="sidenav-navitem" className="my-4">
          <SideNav.NavIcon id="sidenav-navicon">
            <NavLink to="/deals" className="dark-blue-text no-underline">
              <div id="navicon">
                <div id={`${selectedNav === 'deals' ? 'navicon-active' : ''}`}>
                  <img src={dealsIcon} alt="D" />
                </div>
              </div>
            </NavLink>
          </SideNav.NavIcon>
          <SideNav.NavText id={`${isExpanded && 'sidenav-navtext-active'}`}>
            <NavLink to="/livedeals" className="dark-blue-text no-underline">
              <div id="sidenav-navtext" className="full-width">
                Deals
              </div>
            </NavLink>
          </SideNav.NavText>
        </SideNav.NavItem>
        <SideNav.NavItem
          eventKey="contacts"
          id="sidenav-navitem"
          className="my-4"
        >
          <SideNav.NavIcon id="sidenav-navicon">
            <NavLink to="/contacts" className="dark-blue-text no-underline">
              <div id="navicon">
                <div
                  id={`${selectedNav === 'contacts' ? 'navicon-active' : ''}`}
                >
                  <ContactsIcon />
                </div>
              </div>
            </NavLink>
          </SideNav.NavIcon>
          <SideNav.NavText id={`${isExpanded && 'sidenav-navtext-active'}`}>
            <NavLink to="/contacts" className="dark-blue-text no-underline">
              <div id="sidenav-navtext" className="full-width">
                CONTACTS
              </div>
            </NavLink>
          </SideNav.NavText>
        </SideNav.NavItem>
        <SideNav.NavItem eventKey="tasks" id="sidenav-navitem" className="my-4">
          <SideNav.NavIcon id="sidenav-navicon">
            <NavLink to="/tasks" className="dark-blue-text no-underline">
              <div id="navicon">
                <div id={`${selectedNav === 'tasks' ? 'navicon-active' : ''}`}>
                  <TasksIcon />
                  <span className="badge badge-pill badge-warning position-absolute">
                    {incompleteTasksCount}
                  </span>
                </div>
              </div>
            </NavLink>
          </SideNav.NavIcon>
          <SideNav.NavText id={`${isExpanded && 'sidenav-navtext-active'}`}>
            <NavLink to="/tasks" className="dark-blue-text no-underline">
              <div id="sidenav-navtext" className="full-width">
                TASKS
              </div>
            </NavLink>
          </SideNav.NavText>
        </SideNav.NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

Sidebar.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  selectedNav: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Sidebar;
