import { contactsTableFields } from 'js/constants/contacts';
import { createPersistedReducer } from './utils';

const initialState = {
  contactSort: {
    column: contactsTableFields[0].key,
    isAscending: true,
  },
  dealSort: {
    column: 'status',
    isAscending: true,
  },
  taskSort: {
    column: 'dateAdded',
    isAscending: true,
  },
};

const setSort = (sortState, { payload }) => ({
  ...sortState,
  ...payload,
});

const sortReducer = createPersistedReducer(initialState, {
  SET_SORT: setSort,
}, 'sort');

export default sortReducer;
