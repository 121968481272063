import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { useHistory } from 'react-router-dom';
import CloseButton from 'components/CloseButton';
import TaskInput from 'components/TaskInput';
import { formatDate } from 'js/utils';
import './styles.scss';

const Tasks = (props) => {
  const {
    contacts,
    deals,
    tasks,
    onDelete,
    onSetSort,
    onUpdate,
    sort,
  } = props;

  const history = useHistory();

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      history.push('/deals');
    }

    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const { column: sortColumn, isAscending: isSortAscending } = sort;

  const getRelatedObject = (task) => {
    const { contactId, dealId } = task;

    let objectName;
    if (contactId) {
      const relatedContact = contacts.find((contact) => contactId === contact.id);
      objectName = relatedContact.fullName;
    } else {
      const relatedDeal = deals.find((deal) => dealId === deal.id);
      objectName = relatedDeal.company;
    }

    return objectName;
  };

  const getHeader = (key, name) => {
    let className;
    if (key === sortColumn) {
      className = (
        isSortAscending
          ? 'sorted ascending'
          : 'sorted descending'
      );
    }

    return (
      <th key={key}>
        <button
          type="button"
          className={className}
          onClick={() => {
            if (key === sortColumn) {
              onSetSort({ column: sortColumn, isAscending: !isSortAscending });
            } else {
              onSetSort({ column: key, isAscending: true });
            }
          }}
        >
          {name}
        </button>
      </th>
    );
  };

  const incompleteTasks = tasks.filter(({ isCompleted }) => !isCompleted);
  const completedTasks = tasks.filter(({ isCompleted }) => isCompleted);

  const rows = (items) => items.sort((a, b) => {
    let aValue;
    let bValue;

    if (sortColumn === 'relation') {
      aValue = getRelatedObject(a);
      bValue = getRelatedObject(b);
    } else {
      aValue = a[sortColumn];
      bValue = b[sortColumn];
    }

    if (typeof aValue === 'string') {
      aValue = aValue.toLowerCase();
    }

    if (typeof bValue === 'string') {
      bValue = bValue.toLowerCase();
    }

    if (aValue === bValue) {
      return a.id < b.id ? -1 : 1;
    } else if (aValue === null) {
      return 1;
    } else if (bValue === null) {
      return -1;
    } else {
      return ((
        (isSortAscending && aValue < bValue)
        || (!isSortAscending && aValue > bValue)
      ) ? -1 : 1);
    }
  }).map((task) => {
    const {
      id,
      dateAdded,
      description,
      isCompleted,
    } = task;

    return (
      <tr key={id}>
        <td>
          <TaskInput
            isCompleted={isCompleted}
            onToggle={() => onUpdate({ id, isCompleted: !isCompleted })}
            onSave={(text) => onUpdate({ id, description: text })}
            text={description}
          />
        </td>
        <td>
          <div className={isCompleted ? 'completed' : ''}>
            {getRelatedObject(task)}
          </div>
        </td>
        <td>
          {formatDate(dateAdded)}
          <CloseButton onClick={() => onDelete(id)} />
        </td>
      </tr>
    );
  });

  return (
    <Container fluid className="tasks-container">
      <Table responsive>
        <thead>
          <tr>
            {getHeader('description', 'Task')}
            {getHeader('relation', 'Contact/Deal')}
            {getHeader('dateAdded', 'Date Added')}
          </tr>
        </thead>
        <tbody>
          {rows(incompleteTasks)}
          {rows(completedTasks)}
        </tbody>
      </Table>
    </Container>
  );
};

Tasks.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetSort: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.string,
    isAscending: PropTypes.bool,
  }).isRequired,
};

export default Tasks;
