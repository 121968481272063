import { createPersistedReducer } from './utils';

const initialState = {
  contactFilters: {
    frequency: [],
    isFlagged: [],
    stage: [],
    type: [],
  },
  dealFilters: {
    model: [],
    stage: [],
    status: [],
    referral: [],
  },
};

const setFilters = (filterState, { payload }) => ({
  ...filterState,
  ...payload,
});

const filterReducer = createPersistedReducer(initialState, {
  SET_FILTERS: setFilters,
}, 'filters');

export default filterReducer;
