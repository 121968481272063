import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import CloseButton from 'components/CloseButton';
import Modal from 'components/Modal';
import './styles.scss';

const DealMemo = (props) => {
  const {
    deal: {
      memo: oldMemo,
      user: { fullName },
    },
    error,
    isEditable,
    isLoading,
    isVisible,
    onClose,
    onSave,
  } = props;

  const [memo, setMemo] = useState(oldMemo);

  const {
    detail,
    errors: {
      memo: [memoError] = [],
    } = {},
  } = error;

  const shouldRenderFormError = detail && !memoError;

  const handleSave = (e) => {
    e.preventDefault();
    onSave({ memo });
  };

  const handleClose = () => {
    setMemo(oldMemo);
    onClose();
  };

  return (
    <Modal
      centered
      show={isVisible}
      onHide={handleClose}
      dialogClassName="modal-lg"
    >
      <Container fluid className="deal-memo-container">
        <CloseButton onClick={handleClose} />
        <Form noValidate onSubmit={handleSave} disabled={!isEditable}>
          <div className="pb-4">
            <h3 className="bold-text dark-blue-text">
              Memo from {fullName}
            </h3>
          </div>
          <Form.Group controlId="memo">
            <Form.Control
              as="textarea"
              disabled={!isEditable}
              placeholder="Your memo about this deal goes here (optional)"
              isInvalid={!!memoError}
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </Form.Group>
          <div className="pt-3 text-center">
            {oldMemo !== memo ? (
              <>
                <span className="pr-3">
                  <Button
                    variant="border"
                    type="button"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </span>
                <span className="pl-3">
                  <Button
                    variant="gray"
                    type="submit"
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </span>
                {shouldRenderFormError && (
                  <div className="invalid-feedback text-right">{detail}</div>
                )}
              </>
            ) : (
              <span>
                <Button
                  variant="border"
                  type="button"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </span>
            )}
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

DealMemo.propTypes = {
  deal: PropTypes.shape({
    memo: PropTypes.string,
    user: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }).isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      memo: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isEditable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DealMemo;
