import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { statusCategories } from 'js/constants/deals';
import './styles.scss';

const CustomToggle = forwardRef(
  ({
    children, className, color, onClick, type,
  }, ref) => (
    <button
      ref={ref}
      type="button"
      className={`${className} ${type}`}
      onClick={(e) => {
        onClick(e);
        e.stopPropagation();
      }}
    >
      <span
        className="status-circle"
        style={{ backgroundColor: `rgb(${color})` }}
      />
      {children}
    </button>
  ),
);

const CustomMenu = forwardRef(({ children, className }, ref) => (
  <div ref={ref} className={className}>
    {children}
  </div>
));

const CustomItem = forwardRef(
  ({
    key, children, className, color, onClick,
  }, ref) => (
    <button
      ref={ref}
      key={key}
      type="button"
      className={`${className}`}
      onClick={(e) => {
        onClick(e);
        e.stopPropagation();
      }}
    >
      <span
        className="status-circle"
        style={{ backgroundColor: `rgb(${color})` }}
      />
      {children}
    </button>
  ),
);

const PillSelect = ({
  options, currentValue, onChange, className,
}) => {
  const {
    color: currentColor,
    display: currentDisplay,
    type,
  } = options.find(({ value }) => currentValue === value) || [];

  const statusWithCategories = statusCategories.map((category) => ({
    category,
    statuses: options.filter((op) => op.value.includes(category)),
  }));

  return (
    <Dropdown className="pill-select">
      <Dropdown.Toggle
        as={CustomToggle}
        color={currentColor}
        type={type}
        className={className}
      >
        {currentDisplay || currentValue}
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        {statusWithCategories.map(({ category, statuses }) => (
          <>
            <div className="status-category">{category}</div>
            {statuses.map(({ value, display, color }) => (
              <Dropdown.Item
                as={CustomItem}
                key={value}
                onClick={() => {
                  onChange(value);
                }}
                color={color}
              >
                {display || value}
              </Dropdown.Item>
            ))}
          </>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

CustomMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

PillSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

CustomItem.propTypes = {
  key: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PillSelect;
