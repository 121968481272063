import { createPersistedReducer, formatShare } from './utils';

const receiveShares = (shareState, { payload }) => ({
  shares: payload.map(formatShare),
});

const clearShares = () => ({ shares: [] });

const receiveShare = (shareState, { payload }) => {
  const { shares: prevShares } = shareState;
  const shares = prevShares.filter((share) => (
    share.id !== payload.id
  ));

  return { shares: [...shares, formatShare(payload)] };
};

const removeShare = (shareState, { payload: id }) => {
  const { shares: prevShares } = shareState;
  const shares = prevShares.filter((share) => share.id !== id);

  return { shares: [...shares] };
};

const shareReducer = createPersistedReducer({ shares: [] }, {
  RECEIVE_SHARES: receiveShares,
  CLEAR_SHARES: clearShares,
  RECEIVE_SHARE: receiveShare,
  REMOVE_SHARE: removeShare,
}, 'shares');

export default shareReducer;
