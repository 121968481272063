import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ReactComponent as SuccessIcon } from 'assets/images/success-icon.svg';
import './styles.scss';

const ForgotPassword = (props) => {
  const {
    error,
    isLoading,
    onForgotPassword,
  } = props;

  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const isButtonDisabled = isLoading || !email;

  const {
    detail,
    errors: { email: [emailError] = [] } = {},
  } = error;

  const shouldRenderFormError = detail && !emailError;

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);

      if (!detail) {
        setEmail('');
        setIsSuccessful(true);
      }
    }
  }, [isLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { email };

    setIsSubmitted(true);
    onForgotPassword(payload);
  };

  const content = isSuccessful ? (
    <div className="text-center align-self-center success-container">
      <div className="success-icon-container">
        <SuccessIcon />
      </div>
      <div className="pt-4">
        <h1 className="bold-text white-text">Success!</h1>
      </div>
      <div className="pt-3 pb-5 white-text">
        We've sent a password reset link to your email. Please follow that link
        to reset your password.
      </div>
    </div>
  ) : (
    <Form noValidate onSubmit={handleSubmit} className="forgot-password-form">
      <div className="pb-2">
        <h1 className="white-text bold-text">Forgot password</h1>
      </div>
      <div className="pt-4 pb-3">
        <hr className="m-0 hr-short" />
      </div>
      <Form.Group controlId="email" className="pt-3">
        <Form.Label className="small-text bold-text transparent-white-text">
          EMAIL
        </Form.Label>
        <Form.Control
          type="email"
          isInvalid={!!emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control-white"
        />
        {emailError && (
          <Form.Control.Feedback type="invalid">
            {emailError}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <div className="pt-4 text-right">
        <Button
          size="large"
          variant="white"
          type="submit"
          disabled={isButtonDisabled}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Submit'
          )}
        </Button>
        {shouldRenderFormError && (
          <div className="invalid-feedback text-right">{detail}</div>
        )}
      </div>
    </Form>
  );

  return (
    <Container
      fluid
      className="forgot-password-container d-flex justify-content-center"
    >
      {content}
    </Container>
  );
};

ForgotPassword.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      email: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onForgotPassword: PropTypes.func.isRequired,
};

export default ForgotPassword;
