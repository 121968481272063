import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Deals from 'components/Deals';
import Loading from 'components/Loading';
import {
  receiveDealAction,
  updateDealAction,
  updateUserDealAction,
} from 'js/actions/dealActions';
import { setFiltersAction } from 'js/actions/filterActions';
import { setSortAction } from 'js/actions/sortActions';
import { tabs } from 'js/constants/deals';

const DealsContainer = (props) => {
  const {
    deals,
    filters,
    isLoading,
    receiveDeal,
    setFilters,
    setSort,
    sort,
    updateDeal,
    updateUserDeal,
  } = props;

  const [prevDeals, setPrevDeals] = useState(deals);
  const [tab, setTab] = useState(Object.keys(tabs)[0]);
  const history = useHistory();

  useEffect(() => {
    if (prevDeals.length < deals.length) {
      const newDeal = deals.find(({ id: dealId }) => (
        !prevDeals.find(({ id }) => id === dealId)
      ));
      setPrevDeals(deals);
      history.push(`/deals/${newDeal.id}`);
    }
  }, [deals]);

  const tabDeals = deals.filter(({ userDeal: { status } }) => (
    tabs[tab].statuses.includes(status)
  ));

  const handleSetFilters = (payload) => {
    setFilters({ dealFilters: payload });
  };

  const handleSetSort = (payload) => {
    setSort({ dealSort: payload });
  };

  const handleUpdateDeal = ({ id: dealId, ...payload }) => {
    const deal = deals.find(({ id }) => dealId === id);
    updateDeal({ id: dealId, ...payload });

    receiveDeal({ ...deal, ...payload });
  };

  const handleUpdateUserDeal = ({ id: dealId, ...payload }) => {
    const deal = deals.find(({ id }) => dealId === id);
    const { userDeal: { id: userDealId, ...restOfUserDeal } } = deal;
    updateUserDeal({ id: userDealId, ...payload });

    receiveDeal({
      ...deal,
      userDeal: {
        id: userDealId,
        ...restOfUserDeal,
        ...payload,
      },
    });
  };

  const handleTabChange = (key) => {
    setTab(key);
  };

  return isLoading && !deals.length ? <Loading theme="light" /> : (
    <Deals
      deals={tabDeals}
      filters={filters}
      tab={tab}
      setTab={handleTabChange}
      onSetFilters={handleSetFilters}
      onSetSort={handleSetSort}
      onUpdateDeal={handleUpdateDeal}
      onUpdateUserDeal={handleUpdateUserDeal}
      sort={sort}
    />
  );
};

DealsContainer.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  receiveDeal: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sort: PropTypes.shape({}).isRequired,
  updateDeal: PropTypes.func.isRequired,
  updateUserDeal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    deals: { deals },
    filters: { dealFilters: filters },
    loading: { isGetDealsLoading },
    sort: { dealSort: sort },
  } = state;

  return {
    deals,
    filters,
    isLoading: isGetDealsLoading,
    sort,
  };
};

const mapDispatchToProps = (dispatch) => ({
  receiveDeal: (payload) => dispatch(receiveDealAction(payload)),
  setFilters: (payload) => dispatch(setFiltersAction(payload)),
  setSort: (payload) => dispatch(setSortAction(payload)),
  updateDeal: (payload) => dispatch(updateDealAction(payload)),
  updateUserDeal: (payload) => dispatch(updateUserDealAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealsContainer);
