import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  receiveDealAction,
  receiveDealsAction,
  removeDealAction,
} from 'js/actions/dealActions';
import { receiveShareAction, removeShareAction } from 'js/actions/shareActions';
import {
  getLoggedInHeaders,
  formatDealPayload,
  formatDealPayloadWithFiles,
} from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const getDeals = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetDealsLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/deals/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getDealsError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveDealsAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetDealsLoading: false }));
};

const addDeal = async (action, { dispatch, getState }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isAddDealLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/deals/`, {
    method: 'POST',
    body: formatDealPayloadWithFiles(payload),
    headers: { Authorization: `Token ${token}` },
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ addDealError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveDealAction(responseJson));
  }

  dispatch(setLoadingAction({ isAddDealLoading: false }));
};

const updateDeal = async (action, { dispatch, getState }) => {
  const { payload: { id, ...restOfPayload } } = action;

  const { user: { token } } = getState();

  dispatch(setLoadingAction({ isUpdateDealLoading: true }));

  const response = await fetch(`${API_URL}/deals/${id}/`, {
    method: 'PATCH',
    body: formatDealPayloadWithFiles(restOfPayload),
    headers: { Authorization: `Token ${token}` },
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateDealError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveDealAction(responseJson));
  }

  dispatch(setLoadingAction({ isUpdateDealLoading: false }));
};

const updateUserDeal = async (action, { dispatch, getState }) => {
  const { payload: { id, loadingKey, ...restOfPayload } } = action;

  const { user: { token } } = getState();

  if (loadingKey) {
    dispatch(setLoadingAction({ [loadingKey]: true }));
  }

  const response = await fetch(`${API_URL}/deals/user_deals/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(restOfPayload),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateUserDealError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveDealAction(responseJson));
  }

  if (loadingKey) {
    dispatch(setLoadingAction({ [loadingKey]: false }));
  }
};

const deleteDeal = async (action, { dispatch, getState }) => {
  const { payload: { id } } = action;

  dispatch(setLoadingAction({ isDeleteDealLoading: true }));
  const { user: { token }, shares: { shares } } = getState();

  const response = await fetch(`${API_URL}/deals/${id}/`, {
    method: 'DELETE',
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ removeDealError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(removeDealAction(id));

    const deletedShares = shares.filter(({ dealId }) => dealId === id);
    deletedShares.forEach(({ id: shareId }) => (
      dispatch(removeShareAction(shareId))
    ));
  }

  dispatch(setLoadingAction({ isDeleteDealLoading: false }));
};

const shareDeal = async (action, { dispatch, getState }) => {
  const { payload: { id, note, recipientIds } } = action;

  dispatch(setLoadingAction({ isShareDealLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/deals/${id}/share/`, {
    method: 'POST',
    body: JSON.stringify({ note, recipient_ids: recipientIds }),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ shareDealError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    responseJson.forEach((share) => dispatch(receiveShareAction(share)));
  }

  dispatch(setLoadingAction({ isShareDealLoading: false }));
};

const uploadDeals = async (action, { dispatch, getState }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isUploadDealsLoading: true }));
  const { user: { token } } = getState();

  const formattedPayload = payload.map((obj) => formatDealPayload(obj));

  const response = await fetch(`${API_URL}/deals/bulk/`, {
    method: 'POST',
    body: JSON.stringify(formattedPayload),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ uploadDealsError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    responseJson.forEach((deal) => dispatch(receiveDealAction(deal)));
  }

  dispatch(setLoadingAction({ isUploadDealsLoading: false }));
};

const dealMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'GET_DEALS':
      await getDeals(action, store);
      break;
    case 'ADD_DEAL':
      await addDeal(action, store);
      break;
    case 'UPDATE_DEAL':
      await updateDeal(action, store);
      break;
    case 'UPDATE_USER_DEAL':
      await updateUserDeal(action, store);
      break;
    case 'DELETE_DEAL':
      await deleteDeal(action, store);
      break;
    case 'SHARE_DEAL':
      await shareDeal(action, store);
      break;
    case 'UPLOAD_DEALS':
      await uploadDeals(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default dealMiddleware;
