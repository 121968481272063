import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import CloseButton from 'components/CloseButton';
import Modal from 'components/Modal';
import './styles.scss';

const NoteForm = (props) => {
  const {
    title,
    description,
    disclaimer,
    initialNote,
    isLoading,
    isVisible,
    onClose,
    onSubmit,
  } = props;

  const [note, setNote] = useState(initialNote);
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);

  useEffect(() => {
    setNote(initialNote);
  }, [initialNote]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(note);
    setNote(initialNote);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal centered show={isVisible} onHide={handleClose}>
      <Container fluid className="note-form-container">
        <CloseButton onClick={handleClose} />
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-3">
            <h3 className="bold-text dark-blue-text">{title}</h3>
          </div>
          <Form.Group controlId="note">
            <Form.Control
              as="textarea"
              placeholder="Add a note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Group>
          <div className="text-center small-text">
            {description}
          </div>
          {disclaimer && (
            <Form.Group className="pt-3">
              <Form.Check type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  checked={isDisclaimerChecked}
                  onChange={() => setIsDisclaimerChecked(!isDisclaimerChecked)}
                  className="form-check-gray"
                />
                <Form.Check.Label className="dark-blue-text ml-3">
                  {disclaimer}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          )}
          <div className="pt-3 text-center">
            <span className="pr-3">
              <Button
                variant="border"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </span>
            <span className="pl-3">
              <Button
                variant="gray"
                type="submit"
                disabled={(
                  !note || (!isDisclaimerChecked && disclaimer) || isLoading
                )}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Confirm'
                )}
              </Button>
            </span>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

NoteForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
  initialNote: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NoteForm;
