import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Contacts from 'components/Contacts';
import Loading from 'components/Loading';
import {
  receiveContactAction,
  updateContactAction,
} from 'js/actions/contactActions';
import { setFiltersAction } from 'js/actions/filterActions';
import { sendInviteAction } from 'js/actions/inviteActions';
import { setSortAction } from 'js/actions/sortActions';

const ContactsContainer = (props) => {
  const {
    contacts,
    filters,
    isLoading,
    receiveContact,
    sendInvite,
    setFilters,
    setSort,
    sort,
    updateContact,
    user,
  } = props;

  const [prevContacts, setPrevContacts] = useState(contacts);
  const history = useHistory();

  useEffect(() => {
    if (prevContacts.length < contacts.length) {
      const newContact = contacts.find(({ id: contactId }) => (
        !prevContacts.find(({ id }) => id === contactId)
      ));
      setPrevContacts(contacts);
      history.push(`/contacts/${newContact.id}`);
    }
  }, [contacts]);

  const handleSetFilters = (payload) => {
    setFilters({ contactFilters: payload });
  };

  const handleSetSort = (payload) => {
    setSort({ contactSort: payload });
  };

  const handleUpdate = ({ id, ...payload }) => {
    const contact = contacts.find(({ id: contactId }) => contactId === id);
    updateContact({ id, ...payload });

    receiveContact({ ...contact, ...payload });
  };

  const handleSendInvite = (contact, note) => {
    const { email } = contact;
    sendInvite({ email, note });
    receiveContact({ ...contact, isInvited: true });
  };

  return isLoading && !contacts.length ? <Loading theme="light" /> : (
    <Contacts
      contacts={contacts}
      filters={filters}
      onSendInvite={handleSendInvite}
      onSetFilters={handleSetFilters}
      onSetSort={handleSetSort}
      onUpdate={handleUpdate}
      sort={sort}
      user={user}
    />
  );
};

ContactsContainer.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  receiveContact: PropTypes.func.isRequired,
  sendInvite: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sort: PropTypes.shape({}).isRequired,
  updateContact: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    contacts: { contacts },
    filters: { contactFilters: filters },
    loading: { isGetContactsLoading },
    sort: { contactSort: sort },
    user,
  } = state;

  return {
    contacts,
    filters,
    isLoading: isGetContactsLoading,
    sort,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  receiveContact: (payload) => dispatch(receiveContactAction(payload)),
  sendInvite: (payload) => dispatch(sendInviteAction(payload)),
  setFilters: (payload) => dispatch(setFiltersAction(payload)),
  setSort: (payload) => dispatch(setSortAction(payload)),
  updateContact: (payload) => dispatch(updateContactAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsContainer);
