import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  removeShareAction,
  receiveShareAction,
  receiveSharesAction,
} from 'js/actions/shareActions';
import { getLoggedInHeaders } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const getShares = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetSharesLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/shares/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getSharesError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveSharesAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetSharesLoading: false }));
};

const sendShare = async (action, { dispatch, getState }) => {
  const { payload: { id, ...restOfPayload } } = action;

  const { user: { token } } = getState();

  dispatch(setLoadingAction({ isSendShareLoading: true }));

  const response = await fetch(`${API_URL}/shares/${id}/share/`, {
    method: 'POST',
    body: JSON.stringify(restOfPayload),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ sendShareError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveShareAction(responseJson));
  }

  dispatch(setLoadingAction({ isSendShareLoading: false }));
};

const updateShare = async (action, { dispatch, getState }) => {
  const { payload: { id, ...restOfPayload } } = action;

  const { user: { token } } = getState();

  dispatch(setLoadingAction({ isUpdateShareLoading: true }));

  const response = await fetch(`${API_URL}/shares/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(restOfPayload),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateShareError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveShareAction(responseJson));
  }

  dispatch(setLoadingAction({ isUpdateShareLoading: false }));
};

const removeShare = async (action, { dispatch, getState }) => {
  const { payload: { id } } = action;

  dispatch(setLoadingAction({ isRemoveShareLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/shares/${id}/`, {
    method: 'DELETE',
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ removeShareError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(removeShareAction(id));
  }

  dispatch(setLoadingAction({ isRemoveShareLoading: false }));
};

const shareMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'GET_SHARES':
      await getShares(action, store);
      break;
    case 'SEND_SHARE':
      await sendShare(action, store);
      break;
    case 'UPDATE_SHARE':
      await updateShare(action, store);
      break;
    case 'REMOVE_SHARE':
      await removeShare(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default shareMiddleware;
