import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import deleteButton from 'assets/images/delete-button.svg';
import editButton from 'assets/images/edit-button.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import FormSelect from 'components/FormSelect';
import NoteForm from 'components/NoteForm';
import RichTextField from 'components/RichTextField';
import ShareForm from 'components/ShareForm';
import RelatedTasksContainer from 'containers/RelatedTasksContainer';
import UpdateContactContainer from 'containers/UpdateContactContainer';
import { getStatusValues, interests } from 'js/constants/deals';
import {
  formatMillions,
  formatMonetaryValue,
  getInviteNote,
  getShareNote,
  isEditorStateEqual,
} from 'js/utils';
import './styles.scss';

const ContactDetails = (props) => {
  const {
    contact,
    contact: {
      id,
      firstName,
      fullName,
      email,
      company,
      type,
      frequency,
      checkSmall,
      checkBig,
      stage,
      model,
      calendly,
      linkedin,
      isInvited,
      isUser,
    },
    deals,
    isDeleteContactLoading,
    isSendInviteLoading,
    isSendShareLoading,
    isShareWithContactLoading,
    isUpdateContactNotesLoading,
    onDelete,
    onSaveNotes,
    onSendInvite,
    onSendShare,
    onShare,
    onUpdateInterest,
    richNotes,
    shares,
    user: { id: userId, fullName: userName },
  } = props;

  const [newNotes, setNewNotes] = useState(richNotes);
  const [selectedShareId, setSelectedShareId] = useState(null);
  const [isDeleteContactVisible, setIsDeleteContactVisible] = useState(false);
  const [isUpdateContactVisible, setIsUpdateContactVisible] = useState(false);
  const [isSendInviteVisible, setIsSendInviteVisible] = useState(false);
  const [isSendShareVisible, setIsSendShareVisible] = useState(false);
  const [isShareWithContactVisible, setIsShareWithContactVisible] = useState(false);

  const history = useHistory();

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      history.push('/contacts');
    }
  };

  const handleRowClick = (dealId) => {
    history.push(`/deals/${dealId}`);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const shareContent = shares.map((share) => {
    const {
      dealId,
      id: shareId,
      interest,
      isShared,
    } = share;

    const {
      company: dealCompany,
      stage: dealStage,
      preMoney: dealPreMoney,
    } = deals.find(({ id: shareDealId }) => shareDealId === dealId);

    return (
      <div
        key={dealId}
        onClick={() => handleRowClick(dealId)}
        role="presentation"
        className="share-row"
      >
        <div className="contact-deal">
          <div
            className="dark-blue-text d-flex justify-content-between"
          >
            <span>
              <span className="bold-text">
                {dealCompany}
              </span>
              {` — ${dealStage}`}
              {dealPreMoney && ` — ${formatMillions(dealPreMoney)}`}
            </span>
            <span className="pl-2">
              {isShared ? (
                <FormSelect
                  placeholder="Interest Level"
                  options={interests}
                  isInvalid={false}
                  size="small"
                  value={interest}
                  onChange={(val) => onUpdateInterest(shareId, val)}
                />
              ) : (
                <Button
                  size="small"
                  variant="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedShareId(shareId);
                    setIsSendShareVisible(true);
                  }}
                >
                  Share
                </Button>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  });

  const shareOptions = deals.filter((deal) => {
    const { id: dealId, user: { id: dealUserId } } = deal;
    return (
      dealUserId === userId
      && !shares.find(({ dealId: shareDealId }) => shareDealId === dealId)
    );
  }).map((deal) => {
    const {
      id: dealId,
      company: dealCompany,
      stage: dealStage,
      preMoney: dealPreMoney,
      userDeal: { status: dealStatus },
    } = deal;

    const display = `${dealCompany} — ${dealStage}`;

    return {
      id: dealId,
      display: (
        dealPreMoney
          ? `${display} — ${formatMillions(dealPreMoney)}`
          : display
      ),
      status: dealStatus,
    };
  });

  let inviteContent;
  if (isUser) {
    inviteContent = <span className="dark-blue-text">Joined</span>;
  } else {
    inviteContent = (
      <Button
        size="small"
        variant="gray"
        onClick={() => setIsSendInviteVisible(true)}
      >
        {isInvited ? 'Resend Invite' : 'Invite to join Pipeline'}
      </Button>
    );
  }

  const selectedDeal = selectedShareId ? deals.find(({ id: dealId }) => {
    const { dealId: selectedDealId } = shares.find((share) => (
      share.id === selectedShareId
    )) || {};
    return dealId === selectedDealId;
  }) : {};

  const {
    company: dealCompany = '',
    sector: dealSector = '',
    stage: dealStage = '',
  } = selectedDeal;

  return (
    <Container fluid className="contact-details-container">
      <UpdateContactContainer
        contact={contact}
        isVisible={isUpdateContactVisible}
        onClose={() => setIsUpdateContactVisible(false)}
      />
      <ConfirmationModal
        cancelText="Cancel"
        confirmText="Delete"
        header="Are you sure?"
        description="Deleting a contact cannot be undone."
        isLoading={isDeleteContactLoading}
        isVisible={isDeleteContactVisible}
        onClose={() => setIsDeleteContactVisible(false)}
        onConfirm={() => onDelete(id)}
      />
      <NoteForm
        title="Confirm invite"
        description="Your contact will receive an email inviting them to join the Pipeline platform."
        disclaimer=""
        initialNote={
          getInviteNote(firstName, userName)
        }
        isLoading={isSendInviteLoading}
        isVisible={isSendInviteVisible}
        onClose={() => setIsSendInviteVisible(false)}
        onSubmit={(note) => onSendInvite({ email, note })}
      />
      <ShareForm
        options={shareOptions}
        filterFields={['status']}
        filterOptions={[[['All Statuses'], ...getStatusValues()]]}
        title="Add deals"
        header="List of deals"
        isLoading={isShareWithContactLoading}
        isVisible={isShareWithContactVisible}
        onClose={() => setIsShareWithContactVisible(false)}
        onSubmit={onShare}
      />
      <NoteForm
        title="Share deal"
        description="This will share Deal Details, Deal Memo, and Supporting Documents
        with recipient(s). It will not share your Personal Notes."
        disclaimer="I agree that I have permission to share this information with this person"
        initialNote={
          getShareNote(firstName, dealCompany, dealSector, dealStage)
        }
        isLoading={isSendShareLoading}
        isVisible={isSendShareVisible}
        onClose={() => {
          setIsSendShareVisible(false);
          setSelectedShareId(null);
        }}
        onSubmit={(note) => onSendShare({ id: selectedShareId, note })}
      />
      <Row noGutters className="py-3 full-width">
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="bold-text dark-blue-text">
              Contact details
            </h4>
          </div>
          <div>
            <span className="mr-3">
              <Button
                variant="icon"
                type="button"
                onClick={() => setIsUpdateContactVisible(true)}
              >
                <img src={editButton} alt="Edit" />
              </Button>
            </span>
            <span>
              <Button
                variant="icon"
                type="button"
                onClick={() => setIsDeleteContactVisible(true)}
              >
                <img src={deleteButton} alt="Delete" />
              </Button>
            </span>
          </div>
        </Col>
      </Row>
      <Row className="mx-md-n4">
        <Col md={5} className="px-md-4">
          <div className="detail-section">
            <div className="detail-row">
              <span className="blue-gray-text">Name</span>
              <span className="dark-blue-text">{fullName}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Company</span>
              <span className="dark-blue-text">{company}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Type</span>
              <span className="dark-blue-text">{type}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Frequency</span>
              <span className="dark-blue-text">{frequency}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Smallest Check</span>
              <span className="dark-blue-text">
                {formatMonetaryValue(checkSmall)}
              </span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Largest Check</span>
              <span className="dark-blue-text">
                {formatMonetaryValue(checkBig)}
              </span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Stage preference</span>
              <span className="dark-blue-text">{stage}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Model preference</span>
              <span className="dark-blue-text">{model}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Email</span>
              <span className="dark-blue-text">
                <a
                  href={`mailto:${email}`}
                  className="bold-text cool-blue-text underlined"
                >
                  {email}
                </a>
              </span>
            </div>
            {linkedin && (
              <div className="detail-row">
                <span className="blue-gray-text">LinkedIn</span>
                <span className="dark-blue-text">
                  <a
                    href={linkedin}
                    target="_blank"
                    rel="noreferrer"
                    className="bold-text cool-blue-text underlined"
                  >
                    View LinkedIn Profile
                  </a>
                </span>
              </div>
            )}
            {calendly && (
              <div className="detail-row">
                <span className="blue-gray-text">Calendly</span>
                <span className="dark-blue-text">
                  <a
                    href={calendly}
                    target="_blank"
                    rel="noreferrer"
                    className="bold-text cool-blue-text underlined"
                  >
                    View Calendly
                  </a>
                </span>
              </div>
            )}
            <div className="detail-row">
              <span className="blue-gray-text">Invite</span>
              {inviteContent}
            </div>
          </div>
        </Col>
        <Col md={7} className="px-md-4">
          <div>
            <h4 className="bold-text dark-blue-text">
              Notes
            </h4>
          </div>
          <div className="notes-section">
            <div className="my-3">
              <RichTextField
                editorState={newNotes}
                onChange={setNewNotes}
                placeholder="Your notes about this contact go here (optional)"
              />
            </div>
            {!isEditorStateEqual(newNotes, richNotes) && (
              <div className="text-right mb-2">
                <span className="mr-4">
                  <Button
                    variant="border"
                    type="button"
                    onClick={() => setNewNotes(richNotes)}
                  >
                    Cancel
                  </Button>
                </span>
                <span>
                  <Button
                    variant="gray"
                    type="button"
                    disabled={isUpdateContactNotesLoading}
                    onClick={() => onSaveNotes(newNotes)}
                  >
                    {isUpdateContactNotesLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : 'Save'}
                  </Button>
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <div className="py-3">
            <div className="pb-3 d-flex align-items-center justify-content-between">
              <span>
                <h4 className="bold-text dark-blue-text">
                  Deals
                </h4>
              </span>
              <span className="align-self-right">
                <Button
                  variant="border"
                  type="button"
                  onClick={() => setIsShareWithContactVisible(true)}
                >
                  Add Deals
                </Button>
              </span>
            </div>
            {!!shareContent.length && (
              <div className="share-section contact-deals">
                {shareContent}
              </div>
            )}
          </div>
        </Col>
        <Col md={7}>
          <div className="related-tasks-section">
            <RelatedTasksContainer contactId={id} dealId="" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

ContactDetails.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    type: PropTypes.string,
    frequency: PropTypes.string,
    checkSmall: PropTypes.number,
    checkBig: PropTypes.number,
    stage: PropTypes.string,
    model: PropTypes.string,
    calendly: PropTypes.string,
    linkedin: PropTypes.string,
    notes: PropTypes.string,
    isInvited: PropTypes.bool,
    isUser: PropTypes.bool,
  }).isRequired,
  deals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.string,
    stage: PropTypes.string,
    preMoney: PropTypes.number,
  })).isRequired,
  isDeleteContactLoading: PropTypes.bool.isRequired,
  isSendInviteLoading: PropTypes.bool.isRequired,
  isSendShareLoading: PropTypes.bool.isRequired,
  isShareWithContactLoading: PropTypes.bool.isRequired,
  isUpdateContactNotesLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSaveNotes: PropTypes.func.isRequired,
  onSendInvite: PropTypes.func.isRequired,
  onSendShare: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onUpdateInterest: PropTypes.func.isRequired,
  richNotes: PropTypes.shape({}).isRequired,
  shares: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
};

export default ContactDetails;
