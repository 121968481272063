const searchDealsAndContactsAction = (payload) => ({
  type: 'SEARCH_DEALS_AND_CONTACTS',
  payload,
});

const receiveDealsAndContactsAction = (payload) => ({
  type: 'RECEIVE_DEALS_AND_CONTACTS',
  payload,
});

export {
  searchDealsAndContactsAction,
  receiveDealsAndContactsAction,
};
