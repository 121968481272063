const getDealsAction = () => ({ type: 'GET_DEALS' });

const clearDealsAction = () => ({ type: 'CLEAR_DEALS' });

const receiveDealsAction = (payload) => ({
  type: 'RECEIVE_DEALS',
  payload,
});

const addDealAction = (payload) => ({ type: 'ADD_DEAL', payload });

const updateDealAction = (payload) => ({ type: 'UPDATE_DEAL', payload });

const updateUserDealAction = (payload) => ({
  type: 'UPDATE_USER_DEAL',
  payload,
});

const deleteDealAction = (payload) => ({ type: 'DELETE_DEAL', payload });

const receiveDealAction = (payload) => ({
  type: 'RECEIVE_DEAL',
  payload,
});

const removeDealAction = (payload) => ({ type: 'REMOVE_DEAL', payload });

const shareDealAction = (payload) => ({ type: 'SHARE_DEAL', payload });

const uploadDealsAction = (payload) => ({ type: 'UPLOAD_DEALS', payload });

export {
  addDealAction,
  clearDealsAction,
  deleteDealAction,
  getDealsAction,
  receiveDealAction,
  receiveDealsAction,
  removeDealAction,
  shareDealAction,
  updateDealAction,
  updateUserDealAction,
  uploadDealsAction,
};
