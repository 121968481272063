import { convertToRaw } from 'draft-js';

const formatNumberWithCommas = (value) => value.toString().replace(
  /\B(?=(\d{3})+(?!\d))/g,
  ',',
);

const formatMonetaryValue = (value) => {
  if (!value) {
    return '';
  } else {
    let digits;
    let suffix;
    if (value >= 1.0e+9) {
      suffix = 'B';
      digits = (value / 1.0e+9).toFixed(1);
    } else if (value >= 1.0e+6) {
      suffix = 'M';
      digits = (value / 1.0e+6).toFixed(1);
    } else {
      suffix = '';
      digits = formatNumberWithCommas(value);
    }

    return `$${digits}${suffix}`;
  }
};

const formatThousands = (value) => {
  if (!value) {
    return '';
  } else if (value >= 1.0e+3) {
    return `$${formatNumberWithCommas((value / 1.0e+3).toFixed(1))}K`;
  } else {
    return `$${formatNumberWithCommas(value)}`;
  }
};

const formatMillions = (value) => {
  if (!value) {
    return '';
  } else if (value >= 1.0e+6) {
    return `$${formatNumberWithCommas((value / 1.0e+6).toFixed(1))}M`;
  } else {
    return `$${formatNumberWithCommas(value)}`;
  }
};

const formatExternalLink = (url) => {
  if (!url) return '';
  return url.startsWith('http') || url.startsWith('https') ? url : `https://${url}`;
};

const formatDate = (date) => (date ? (
  new Date(Date.parse(date)).toLocaleDateString('en-US', { timeZone: 'UTC' })
) : date);

const isEditorStateEqual = (editorStateA, editorStateB) => {
  const aString = JSON.stringify(
    convertToRaw(editorStateA.getCurrentContent()),
  );
  const bString = JSON.stringify(
    convertToRaw(editorStateB.getCurrentContent()),
  );

  return aString === bString;
};

const getInviteNote = (recipientName, userName) => (
  `${recipientName},\n\n${userName} has invited you to join Pipeline.io! Pipeline is helping investors get out of their inbox and track and share deals more securely and efficiently.`
);

const getShareNote = (name, company, sector, stage) => {
  const sectorString = sector ? ` in the ${sector} sector` : '';

  return `${name},\n\nPlease see below for more information on ${company}. ${company} is a ${stage} stage opportunity${sectorString}. I'm sharing this on Pipeline. Pipeline is helping investors get out of their inbox and track and share deals more securely and efficiently.`;
};

export {
  formatDate,
  formatExternalLink,
  formatMillions,
  formatMonetaryValue,
  formatThousands,
  getInviteNote,
  getShareNote,
  isEditorStateEqual,
};
