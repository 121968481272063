import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DealForm from 'components/DealForm';
import { addDealAction } from 'js/actions/dealActions';
import {
  clearCompaniesSearchResultsAction,
  clearSearchResultCompanyDetailsAction,
} from 'js/actions/companyActions';

const AddDealContainer = (props) => {
  const {
    addDeal,
    error,
    isLoading,
    searchResultCompanyDetail,
    isGetCompanyLoading,
    clearCompaniesSearchResults,
    clearSearchResultCompanyDetail,
  } = props;

  return (
    <DealForm
      deal={{}}
      error={error}
      header="Add deal"
      isLoading={isLoading}
      onSubmit={addDeal}
      submitText="Add Deal"
      searchResultCompanyDetail={searchResultCompanyDetail}
      isGetCompanyLoading={isGetCompanyLoading}
      clearCompaniesSearchResults={clearCompaniesSearchResults}
      clearSearchResultCompanyDetail={clearSearchResultCompanyDetail}
    />
  );
};

AddDealContainer.propTypes = {
  addDeal: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchResultCompanyDetail: PropTypes.shape({}).isRequired,
  isGetCompanyLoading: PropTypes.bool.isRequired,
  clearCompaniesSearchResults: PropTypes.func.isRequired,
  clearSearchResultCompanyDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { addDealError },
    loading: {
      isAddDealLoading,
      isGetCompanyLoading,
    },
    companies: { searchResultCompanyDetail },
  } = state;

  return {
    error: addDealError || {},
    isLoading: isAddDealLoading,
    isGetCompanyLoading,
    searchResultCompanyDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addDeal: (payload) => dispatch(addDealAction(payload)),
  clearCompaniesSearchResults: () => dispatch(clearCompaniesSearchResultsAction()),
  clearSearchResultCompanyDetail: () => dispatch(clearSearchResultCompanyDetailsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDealContainer);
