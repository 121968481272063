import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const ResendVerificationEmail = (props) => {
  const {
    resendVerificationEmail,
    error,
    isLoading,
  } = props;

  const [sucess, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await resendVerificationEmail();
    if (!error.detail) {
      setSuccess(true);
    }
  };

  return (
    <Container fluid className="resend-verification-email-container">
      <div className="pb-4">
        <h3 className="bold-text dark-blue-text">
          Email Verification Required
        </h3>
      </div>
      <div className="resend-verification-email-view-container py-4">
        Please verify your email address to enjoy full access to Pipeline.io. If you haven't received the verification email, you can request a new one using the button below.
      </div>
      <Button
        variant="border"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        ) : (
          'Resend Verification Email'
        )}
      </Button>
      {error.detail && (
        <div className="invalid-feedback text-center py-2">{error.detail}</div>
      )}
      {!error.detail && sucess && (
        <div className="text-center py-2">Verification email sent!</div>
      )}
    </Container>
  );
};

ResendVerificationEmail.propTypes = {
  resendVerificationEmail: PropTypes.func.isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ResendVerificationEmail;
