import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ChangeEmail from 'components/ChangeEmail';
import { changeEmailAction, updateUserAction } from 'js/actions/userActions';

const ChangeEmailContainer = (props) => {
  const {
    changeEmail,
    error,
    isLoading,
    updateUser,
    user,
  } = props;

  const { pathname } = useLocation();

  const isAlternate = pathname === '/settings/alternate';

  return (
    <ChangeEmail
      changeEmail={changeEmail}
      error={error}
      isAlternate={isAlternate}
      isLoading={isLoading}
      updateUser={updateUser}
      user={user}
    />
  );
};

ChangeEmailContainer.propTypes = {
  changeEmail: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { changeEmailError, updateUserError },
    loading: { isChangeEmailLoading, isUpdateUserLoading },
    user,
  } = state;

  return {
    error: changeEmailError || updateUserError || {},
    isLoading: isChangeEmailLoading || isUpdateUserLoading,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeEmail: (payload) => dispatch(changeEmailAction(payload)),
  updateUser: (payload) => dispatch(updateUserAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeEmailContainer);
