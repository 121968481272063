import { createPersistedReducer, formatDeal, formatContact } from './utils';

const receiveDealsAndContacts = (dealState, { payload: { deals, contacts } }) => {
  const formattedDeals = deals.map(formatDeal);
  const formattedContacts = contacts.map(formatContact);

  return {
    deals: [...formattedDeals],
    contacts: [...formattedContacts],
  };
};

const searchReducer = createPersistedReducer({ deals: [], contacts: [] }, {
  RECEIVE_DEALS_AND_CONTACTS: receiveDealsAndContacts,
}, 'search');

export default searchReducer;
