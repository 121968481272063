import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import CloseButton from 'components/CloseButton';
import './styles.scss';

const Search = ({ displayValue, onChange }) => (
  <Form.Group className="form-group-search">
    {displayValue && (
      <CloseButton onClick={() => onChange('')} />
    )}
    <Form.Control
      type="text"
      placeholder="Search"
      value={displayValue}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => e.stopPropagation()}
      className="form-control-search"
    />
  </Form.Group>
);

Search.propTypes = {
  displayValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Search;
