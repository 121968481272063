import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  receiveContactAction,
  receiveContactsAction,
  removeContactAction,
} from 'js/actions/contactActions';
import { receiveShareAction, removeShareAction } from 'js/actions/shareActions';
import { getLoggedInHeaders, formatContactPayload } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const getContacts = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetContactsLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/contacts/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getContactsError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveContactsAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetContactsLoading: false }));
};

const addContact = async (action, { dispatch, getState }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isAddContactLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/contacts/`, {
    method: 'POST',
    body: JSON.stringify(formatContactPayload(payload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ addContactError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveContactAction(responseJson));
  }

  dispatch(setLoadingAction({ isAddContactLoading: false }));
};

const updateContact = async (action, { dispatch, getState }) => {
  const { payload: { id, loadingKey, ...restOfPayload } } = action;

  dispatch(setLoadingAction({ [loadingKey]: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/contacts/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(formatContactPayload(restOfPayload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateContactError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveContactAction(responseJson));
  }

  dispatch(setLoadingAction({ [loadingKey]: false }));
};

const deleteContact = async (action, { dispatch, getState }) => {
  const { payload: { id } } = action;

  dispatch(setLoadingAction({ isDeleteContactLoading: true }));
  const { user: { token }, shares: { shares } } = getState();

  const response = await fetch(`${API_URL}/contacts/${id}/`, {
    method: 'DELETE',
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ removeContactError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(removeContactAction(id));

    const deletedShares = shares.filter(({ recipientId }) => recipientId === id);
    deletedShares.forEach(({ id: shareId }) => (
      dispatch(removeShareAction(shareId))
    ));
  }

  dispatch(setLoadingAction({ isDeleteContactLoading: false }));
};

const shareWithContact = async (action, { dispatch, getState }) => {
  const { payload: { id, dealIds, note } } = action;

  dispatch(setLoadingAction({ isShareWithContactLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/contacts/${id}/share/`, {
    method: 'POST',
    body: JSON.stringify({ deal_ids: dealIds, note }),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ shareWithContactError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    responseJson.forEach((share) => dispatch(receiveShareAction(share)));
  }

  dispatch(setLoadingAction({ isShareWithContactLoading: false }));
};

const uploadContacts = async (action, { dispatch, getState }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isUploadContactsLoading: true }));
  const { user: { token } } = getState();

  const formattedPayload = payload.map((obj) => formatContactPayload(obj));

  const response = await fetch(`${API_URL}/contacts/bulk/`, {
    method: 'POST',
    body: JSON.stringify(formattedPayload),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ uploadContactsError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    responseJson.forEach((contact) => dispatch(receiveContactAction(contact)));
  }

  dispatch(setLoadingAction({ isUploadContactsLoading: false }));
};

const contactMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'GET_CONTACTS':
      await getContacts(action, store);
      break;
    case 'ADD_CONTACT':
      await addContact(action, store);
      break;
    case 'UPDATE_CONTACT':
      await updateContact(action, store);
      break;
    case 'DELETE_CONTACT':
      await deleteContact(action, store);
      break;
    case 'SHARE_WITH_CONTACT':
      await shareWithContact(action, store);
      break;
    case 'UPLOAD_CONTACTS':
      await uploadContacts(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default contactMiddleware;
