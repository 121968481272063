import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import PasswordInput from 'components/PasswordInput';
import './styles.scss';

const LogIn = (props) => {
  const {
    ctaText,
    error,
    isLoading,
    onSubmit,
    title,
    emailLabel,
    passwordLabel,
    token,
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isButtonDisabled = (isLoading || !(email && password));

  const {
    detail,
    errors: {
      email: [emailError] = [],
      password: [passwordError] = [],
    } = {},
  } = error;

  const shouldRenderFormError = detail && !(emailError || passwordError);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { email, password };
    if (token) {
      payload.token = token;
    }

    onSubmit(payload);
  };

  return (
    <Container
      fluid
      className="log-in-container d-flex justify-content-center"
    >
      <Form noValidate onSubmit={handleSubmit} className="log-in-form">
        <div className="pb-2">
          <h2 className="white-text bold-text">{title}</h2>
        </div>
        <div className="pb-4">
          <hr className="m-0 hr-short" />
        </div>
        <Form.Group controlId="email">
          <Form.Label className="small-text bold-text transparent-white-text">
            {emailLabel}
          </Form.Label>
          <Form.Control
            type="email"
            isInvalid={!!emailError}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control-white"
          />
          {emailError && (
            <Form.Control.Feedback type="invalid">
              {emailError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group controlId="password" className="pt-1">
          <Form.Label className="small-text bold-text transparent-white-text">
            {passwordLabel}
          </Form.Label>
          <PasswordInput
            theme="dark"
            isInvalid={!!passwordError}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control-white"
          />
          {passwordError && (
            <Form.Control.Feedback type="invalid">
              {passwordError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {!token && (
          <div className="pb-3 mb-3">
            <Link
              to="/password/forgot"
              className="bold-text white-text underlined"
            >
              Forgot your password?
            </Link>
          </div>
        )}
        <div className="pt-2 text-right">
          <Button
            size="sm"
            variant="white"
            type="submit"
            disabled={isButtonDisabled}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              ctaText
            )}
          </Button>
          {shouldRenderFormError && (
            <div className="invalid-feedback text-right">{detail}</div>
          )}
        </div>
      </Form>
    </Container>
  );
};

LogIn.propTypes = {
  ctaText: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      email: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  passwordLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default LogIn;
