import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const createReducer = (initialState, handlers) => (state = initialState, action) => (action.type in handlers ? handlers[action.type](state, action) : state);

const createPersistedReducer = (initialState, handlers, key) => {
  const config = { key, storage };

  return persistReducer(config, createReducer(initialState, handlers));
};

const formatUser = (user) => {
  const {
    first_name: firstName,
    last_name: lastName,
    full_name: fullName,
    alternate_email: alternateEmail,
    is_verified: isVerified,
    ...restOfUser
  } = user;

  return {
    ...restOfUser,
    firstName,
    lastName,
    fullName,
    alternateEmail,
    isVerified,
  };
};

const formatContact = (contact) => {
  if ('first_name' in contact) {
    const {
      first_name: firstName,
      last_name: lastName,
      full_name: fullName,
      check_small: checkSmall,
      check_big: checkBig,
      is_flagged: isFlagged,
      is_invited: isInvited,
      is_user: isUser,
      share_count: shareCount,
      ...restOfContact
    } = contact;

    return {
      ...restOfContact,
      firstName,
      lastName,
      fullName,
      checkSmall,
      checkBig,
      isFlagged,
      isInvited,
      isUser,
      shareCount,
    };
  } else {
    return contact;
  }
};

const formatDeal = (deal) => {
  if ('close_date' in deal) {
    const {
      user: {
        first_name: firstName,
        last_name: lastName,
        full_name: fullName,
        ...restOfUser
      },
      user_deal: userDeal,
      pre_money: preMoney,
      close_date: closeDate,
      ceo_name: ceoName,
      ceo_email: ceoEmail,
      is_flagged: isFlagged,
      ...restOfDeal
    } = deal;

    return {
      ...restOfDeal,
      user: {
        ...restOfUser,
        firstName,
        lastName,
        fullName,
      },
      userDeal,
      preMoney,
      closeDate,
      ceoName,
      ceoEmail,
      isFlagged,
    };
  } else {
    return deal;
  }
};

const formatInvite = (invite) => {
  const { date_sent: dateSent, ...restOfInvite } = invite;

  return { ...restOfInvite, dateSent };
};

const formatShare = (share) => {
  const {
    deal_id: dealId,
    is_shared: isShared,
    recipient_id: recipientId,
    ...restOfShare
  } = share;

  return {
    ...restOfShare,
    dealId,
    isShared,
    recipientId,
  };
};

const formatTask = (task) => {
  if ('is_completed' in task) {
    const {
      contact_id: contactId,
      deal_id: dealId,
      is_completed: isCompleted,
      date_added: dateAdded,
      ...restOfTask
    } = task;

    return {
      ...restOfTask,
      contactId,
      dateAdded,
      dealId,
      isCompleted,
    };
  } else {
    return task;
  }
};

const formatCompany = (company) => {
  const {
    _id: id,
    name,
    homepage_url: homepageUrl = '',
    founded_on: foundedOn = '',
    logo_url: logoUrl = '',
    category_list: categoryList = '',
    category_groups_list: categoryGroupsList = '',
    legal_name: legalName = '',
    location = '',
    short_description: shortDescription = '',
    company_type: companyType = '',
    description = '',
    exited_on: exitedOn = '',
    facebook = '',
    operating_status: operatingStatus = '',
    aliases = '',
    linkedin = '',
    twitter = '',
    last_layoff_date: lastLayoffDate = '',
    equity_funding_total: equityFundingTotal = 0,
    funding_total: fundingTotal = 0,
    investors = '',
    last_funding_at: lastFundingAt = '',
    last_funding_total: lastFundingTotal = 0,
    last_funding_type: lastFundingType = '',
    valuation = 0,
    valuation_date: valuationDate = '',
    user_id: userId,
  } = company;

  return {
    id,
    name,
    homepageUrl,
    foundedOn,
    logoUrl,
    categoryList,
    categoryGroupsList,
    legalName,
    location,
    shortDescription,
    companyType,
    description,
    exitedOn,
    facebook,
    operatingStatus,
    aliases,
    linkedin,
    twitter,
    lastLayoffDate,
    equityFundingTotal,
    fundingTotal,
    investors,
    lastFundingAt,
    lastFundingTotal,
    lastFundingType,
    valuation,
    valuationDate,
    userId,
  };
};

const formatCompanySearchResult = (company) => {
  const {
    id,
    _id,
    name,
    homepage_url: homepageUrl = '',
    founded_on: foundedOn = '',
    image_url: imageUrl = '',
  } = company;

  return {
    id: id || _id,
    name,
    homepageUrl,
    foundedOn,
    imageUrl,
  };
};

const formatSearchResultCompanyDetails = (company) => {
  const {
    id,
    name,
    website_url: homepageUrl = '',
    founded_on: { value: foundedOn } = '',
    image_url: logoUrl = '',
    categories = [],
    category_groups: { categoryGroups } = [],
    legal_name: legalName = '',
    location_group_identifiers: { locationGroupIdentifiers } = [],
    short_description: shortDescription = '',
    company_type: companyType = '',
    description = '',
    exited_on: { value: exitedOn } = '',
    facebook: { value: facebook } = '',
    operating_status: operatingStatus = '',
    aliases: aliasesList = [],
    linkedin: { value: linkedin } = '',
    twitter: { value: twitter } = '',
    last_layoff_date: lastLayoffDate = '',
    equity_funding_total: { value_usd: equityFundingTotal } = 0,
    funding_total: { value_usd: fundingTotal } = 0,
    investor_identifier: { investorIdentifier } = [],
    last_funding_at: lastFundingAt = '',
    last_funding_total: { value_usd: lastFundingTotal } = 0,
    last_funding_type: lastFundingType = '',
    valuation: { value_usd: valuation } = 0,
    valuation_date: valuationDate = '',
  } = company;

  const categoryList = categories
    ? categories.map((category) => category.value).join(', ')
    : '';
  const categoryGroupsList = categoryGroups
    ? categoryGroups.map((categoryGroup) => categoryGroup.value).join(', ')
    : '';
  const location = locationGroupIdentifiers
    ? locationGroupIdentifiers
      .map((locationGroup) => locationGroup.value)
      .join(', ')
    : '';
  const aliases = aliasesList
    ? aliasesList.map((alias) => alias.value).join(', ')
    : '';
  const investors = investorIdentifier
    ? investorIdentifier.map((investor) => investor.value).join(', ')
    : '';

  return {
    id,
    name,
    homepageUrl,
    foundedOn,
    logoUrl,
    categoryList,
    categoryGroupsList,
    legalName,
    location,
    shortDescription,
    companyType,
    description,
    exitedOn,
    facebook,
    operatingStatus,
    aliases,
    linkedin,
    twitter,
    lastLayoffDate,
    equityFundingTotal,
    fundingTotal,
    investors,
    lastFundingAt,
    lastFundingTotal,
    lastFundingType,
    valuation,
    valuationDate,
  };
};

export {
  createReducer,
  createPersistedReducer,
  formatContact,
  formatDeal,
  formatInvite,
  formatShare,
  formatTask,
  formatUser,
  formatCompany,
  formatCompanySearchResult,
  formatSearchResultCompanyDetails,
};
