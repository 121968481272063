import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import CloseButton from 'components/CloseButton';
import Modal from 'components/Modal';
import './styles.scss';

const ConfirmationModal = (props) => {
  const {
    cancelText,
    confirmText,
    header,
    description,
    isLoading,
    isVisible,
    onClose,
    onConfirm,
  } = props;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal centered show={isVisible} onHide={onClose}>
      <Container fluid className="confirmation-modal-container">
        <CloseButton onClick={onClose} />
        <div className="pb-4">
          <h3 className="bold-text dark-blue-text">
            {header}
          </h3>
        </div>
        <div className="pb-3 dark-blue-text">
          {description}
        </div>
        <div className="pt-3 text-center">
          <span className="pr-3">
            <Button
              variant="border"
              type="button"
              onClick={onClose}
            >
              {cancelText}
            </Button>
          </span>
          <span className="pl-3">
            <Button
              variant="gray"
              type="button"
              onClick={handleConfirm}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : confirmText}
            </Button>
          </span>
        </div>
      </Container>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
