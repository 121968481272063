import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import CloseButton from 'components/CloseButton';
import TaskInput from 'components/TaskInput';
import { formatDate } from 'js/utils';
import './styles.scss';

const RelatedTasks = (props) => {
  const {
    tasks,
    onAdd,
    onDelete,
    onUpdate,
  } = props;

  const incompleteTasks = tasks.filter(({ isCompleted }) => !isCompleted);
  const completedTasks = tasks.filter(({ isCompleted }) => isCompleted);

  const rows = (items) => items.map((task) => {
    const {
      id,
      dateAdded,
      description,
      isCompleted,
    } = task;

    return (
      <tr key={id}>
        <td>
          <TaskInput
            isCompleted={isCompleted}
            onToggle={() => onUpdate({ id, isCompleted: !isCompleted })}
            onSave={(text) => onUpdate({ id, description: text })}
            text={description}
          />
        </td>
        <td>
          <div className={isCompleted ? 'completed' : ''}>
            {formatDate(dateAdded)}
          </div>
        </td>
        <td>
          <CloseButton onClick={() => onDelete(id)} />
        </td>
      </tr>
    );
  });

  return (
    <div className="py-3 related-tasks-container">
      <div className="pb-3 d-flex align-items-center justify-content-between">
        <span>
          <h4 className="bold-text dark-blue-text">
            Tasks
          </h4>
        </span>
        <span className="align-self-right">
          <Button
            variant="border"
            type="button"
            onClick={onAdd}
          >
            Add Task
          </Button>
        </span>
      </div>
      <Table responsive className="mt-3">
        <tbody>
          {rows(incompleteTasks)}
          {rows(completedTasks)}
        </tbody>
      </Table>
    </div>
  );
};

RelatedTasks.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default RelatedTasks;
