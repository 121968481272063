import { formatDate } from 'js/utils';

const sentiments = [
  ['Good', 'Good Call 👍'],
  ['Bad', 'Bad Call 👎'],
];

const stages = [
  ['Pre-Seed'],
  ['Seed'],
  ['Series A'],
  ['Series B'],
  ['Series C'],
  ['Series D'],
  ['Growth'],
];

const statusCategories = ['Live', 'On Deck', 'Closed', 'Invested'];

const statuses = [
  {
    value: 'Live - Committed',
    display: 'Committed',
    color: '160, 32, 240',
    type: 'outline',
  },
  {
    value: 'Live - 2nd Call Done',
    display: '2nd Call Done',
    color: '49, 66, 126',
    type: 'outline',
  },
  {
    value: 'Live - 1st Call Done',
    display: '1st Call Done',
    color: '255, 172, 77',
    type: 'outline',
  },
  {
    value: 'Live - Due Diligence',
    display: 'Due Diligence',
    color: '0, 128, 0',
    type: 'outline',
  },
  {
    value: 'Live - Raising Now',
    display: 'Raising Now',
    color: '0, 128, 255',
    type: 'outline',
  },

  {
    value: 'On Deck - Top Priority',
    display: 'Top Priority',
    color: '160, 32, 240',
    type: 'outline',
  },
  {
    value: 'On Deck - Advising',
    display: 'Advising',
    color: '49, 66, 126',
    type: 'outline',
  },
  {
    value: 'On Deck - Punt',
    display: 'Punt',
    color: '255, 172, 77',
    type: 'outline',
  },
  {
    value: 'On Deck - Watch List',
    display: 'Watch List',
    color: '0, 128, 0',
    type: 'outline',
  },
  {
    value: 'Closed - Pass',
    display: 'Pass',
    color: '93, 111, 130',
    type: 'outline',
  },
  {
    value: 'Closed - Lost',
    display: 'Lost',
    color: '211, 215, 217',
    type: 'outline',
  },
  {
    value: 'Closed - Missed',
    display: 'Missed',
    color: '160, 170, 179',
    type: 'outline',
  },
  {
    value: 'Invested',
    display: 'Invested',
    color: '91, 205, 150',
    type: 'outline',
  },
];

const tabs = {
  livedeals: {
    display: 'Live Deals',
    statuses: [
      'Received',
      'Live - Raising Now',
      'Live - Committed',
      'Live - 2nd Call Done',
      'Live - 1st Call Done',
      'Live - Due Diligence',
      'Live - Raising Now',
    ],
  },
  ondeck: {
    display: 'On Deck',
    statuses: ['On Deck - Top Priority', 'On Deck - Advising', 'On Deck - Punt', 'On Deck - Watch List'],
  },
  closeddeals: {
    display: 'Closed Deals',
    statuses: ['Closed - Pass', 'Closed - Lost', 'Closed - Missed'],
  },
  portfolio: {
    display: 'Portfolio',
    statuses: ['Invested'],
  },
};

const dealDetailsTabs = [
  {
    eventKey: 'notes',
    title: 'Notes',
  },
  {
    eventKey: 'intro',
    title: 'Potential Introduction',
  },
  {
    eventKey: 'tasks',
    title: 'Tasks',
  },
];

const getStatusValues = () => statuses.map(({ value }) => [value]);

const getTabStatusValues = (tab) => tabs[tab].statuses.map((status) => [status]);

const dealsTableFields = [
  {
    key: 'company',
    name: 'Company',
    getSortValue: ({ company }) => company,
    getValue: ({ company }) => company,
  },
  {
    key: 'stage',
    name: 'Stage',
    getSortValue: ({ stage }) => (
      stages.findIndex(([value]) => value === stage)
    ),
    getValue: ({ stage }) => stage,
  },
  {
    key: 'status',
    name: 'Status',
    getSortValue: ({ userDeal: { status } }) => (
      statuses.findIndex(({ value }) => value === status)
    ),
    getValue: ({ userDeal: { status } }) => status,
  },
  {
    key: 'model',
    name: 'Model',
    getSortValue: ({ model }) => model,
    getValue: ({ model }) => model,
  },
  {
    key: 'sector',
    name: 'Sector',
    getSortValue: ({ sector }) => sector,
    getValue: ({ sector }) => sector,
  },
  {
    key: 'closeDate',
    name: 'Decision Date',
    getSortValue: ({ closeDate }) => formatDate(closeDate),
    getValue: ({ closeDate }) => formatDate(closeDate),
  },
  {
    key: 'website',
    name: 'Website',
    getSortValue: ({ website }) => website,
    getValue: ({ website }) => website,
  },
  {
    key: 'isFlagged',
    name: 'Flag',
    getSortValue: ({ isFlagged }) => isFlagged,
    getValue: ({ isFlagged }) => isFlagged,
  },
];

const models = [
  ['Consumer'],
  ['B2B'],
];

const sectors = [
  ['Enterprise SaaS'],
  ['Vertical SaaS'],
  ['Marketplace'],
  ['Healthcare'],
  ['FinTech'],
  ['EdTech'],
  ['PropTech'],
  ['E-Commerce'],
  ['AdTech'],
  ['API/Infrastructure'],
  ['Security'],
  ['AI/ML'],
  ['Hardware/IOT'],
  ['Aerospace'],
  ['Energy'],
  ['Transportation'],
  ['Construction'],
  ['Agriculture'],
  ['Health/Wellness'],
  ['Food'],
  ['Media'],
  ['Social'],
  ['Sports/Gaming'],
  ['Consumer Internet'],
  ['Consumer Goods'],
  ['Services'],
  ['Crypto'],
  ['Other'],
];

const structures = [
  ['Direct'],
  ['SPV'],
];

const interests = [
  ['Null', '—'],
  ['Interested'],
  ['Not Interested'],
];

const fieldMap = {
  ceoName: 'ceo_name',
  ceoEmail: 'ceo_email',
  closeDate: 'close_date',
  preMoney: 'pre_money',
  isFlagged: 'is_flagged',
};

const csvHeaders = [
  'company *',
  'stage *',
  'status *',
  'referral',
  'preMoney ($K)',
  'target ($K)',
  'amount ($K)',
  'model',
  'sector',
  'revenue ($K)',
  'structure',
  'website',
  'deck',
  'ceoName',
  'ceoEmail',
  'notes',
];

const fieldOptions = {
  Models: models,
  Sectors: sectors,
  Stages: stages,
  Statuses: statuses.map(({ value }) => [value]),
  Structures: structures,
};

export {
  csvHeaders,
  dealsTableFields,
  fieldMap,
  fieldOptions,
  getStatusValues,
  getTabStatusValues,
  interests,
  models,
  sectors,
  sentiments,
  stages,
  statuses,
  structures,
  tabs,
  dealDetailsTabs,
  statusCategories,
};
