import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ForgotPassword from 'components/ForgotPassword';
import { forgotPasswordAction } from 'js/actions/userActions';

const ForgotPasswordContainer = (props) => {
  const {
    forgotPassword,
    error,
    isLoading,
  } = props;

  return (
    <ForgotPassword
      isLoading={isLoading}
      error={error}
      onForgotPassword={forgotPassword}
    />
  );
};

ForgotPasswordContainer.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { forgotPasswordError },
    loading: { isForgotPasswordLoading },
  } = state;

  return {
    error: forgotPasswordError || {},
    isLoading: isForgotPasswordLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (payload) => dispatch(forgotPasswordAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordContainer);
