const getTasksAction = () => ({ type: 'GET_TASKS' });

const clearTasksAction = () => ({ type: 'CLEAR_TASKS' });

const receiveTasksAction = (payload) => ({
  type: 'RECEIVE_TASKS',
  payload,
});

const addTaskAction = (payload) => ({ type: 'ADD_TASK', payload });

const updateTaskAction = (payload) => ({ type: 'UPDATE_TASK', payload });

const deleteTaskAction = (payload) => ({ type: 'DELETE_TASK', payload });

const receiveTaskAction = (payload) => ({
  type: 'RECEIVE_TASK',
  payload,
});

const removeTaskAction = (payload) => ({ type: 'REMOVE_TASK', payload });

export {
  addTaskAction,
  clearTasksAction,
  deleteTaskAction,
  getTasksAction,
  receiveTaskAction,
  receiveTasksAction,
  removeTaskAction,
  updateTaskAction,
};
