import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import Navbar from 'components/Navbar';
import { logOutAction } from 'js/actions/userActions';
import { tabs } from 'js/constants/deals';
import { searchDealsAndContactsAction } from 'js/actions/searchActions';

const NavbarContainer = (props) => {
  const {
    deals,
    logOut,
    searchDealsAndContacts,
    user,
    search,
    user: { token },
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsExpanded(false);
  }, [location]);

  const onToggle = () => setIsExpanded(!isExpanded);

  const handleSearchDealsAndContacts = (searchTerm) => {
    searchDealsAndContacts({ searchTerm });
  };

  const { pathname } = location;
  const { params: { contactId } = {} } = matchPath(pathname, {
    path: '/contacts/:contactId',
  }) || {};

  const { params: { dealId } = {} } = matchPath(pathname, {
    path: '/deals/:dealId',
  }) || {};

  let back;
  if (contactId && token) {
    back = { display: 'Contacts', path: 'contacts' };
  } else if (dealId && token) {
    const tab = Object.keys(tabs).find((val) => {
      const { userDeal: { status } = {} } = deals.find(({ id }) => (
        id === dealId
      )) || {};
      return tabs[val].statuses.includes(status);
    });
    back = { display: tabs[tab] && tabs[tab].display, path: tab };
  } else if (pathname === '/settings' && token) {
    back = { display: 'Deals', path: 'deals' };
  } else if (pathname.startsWith('/settings') && token) {
    back = { display: 'Settings', path: 'settings' };
  } else if (pathname.startsWith('/deal/') || pathname.startsWith('/company/')) {
    back = { display: 'Back', path: null };
  } else {
    back = {};
  }

  return (
    <Navbar
      currentPath={pathname}
      isExpanded={isExpanded}
      onLogOut={logOut}
      onToggle={onToggle}
      onSearch={handleSearchDealsAndContacts}
      back={back}
      user={user}
      searchResult={search}
    />
  );
};

NavbarContainer.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logOut: PropTypes.func.isRequired,
  searchDealsAndContacts: PropTypes.func.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
  search: PropTypes.shape({ deals: PropTypes.arrayOf(PropTypes.shape({})), contacts: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
};

const mapStateToProps = (state) => {
  const { deals: { deals }, user, search } = state;
  return { deals, user, search };
};

const mapDispatchToProps = (dispatch) => ({
  logOut: (payload) => dispatch(logOutAction(payload)),
  searchDealsAndContacts: (payload) => dispatch(searchDealsAndContactsAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarContainer);
