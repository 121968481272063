import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RelatedTasks from 'components/RelatedTasks';
import Loading from 'components/Loading';
import {
  addTaskAction,
  deleteTaskAction,
  receiveTaskAction,
  updateTaskAction,
} from 'js/actions/taskActions';

const RelatedTasksContainer = (props) => {
  const {
    contactId,
    dealId,
    tasks,
    isLoading,
    addTask,
    deleteTask,
    receiveTask,
    updateTask,
  } = props;

  const relatedTasks = tasks.filter((task) => (
    task.contactId === contactId || task.dealId === dealId
  )).sort((a, b) => a.dateAdded > b.dateAdded);

  const handleAdd = () => addTask({ description: '', contactId, dealId });

  const handleDelete = (id) => deleteTask({ id });

  const handleUpdate = ({ id, ...payload }) => {
    const task = tasks.find(({ id: taskId }) => taskId === id);
    updateTask({ id, ...payload });

    receiveTask({ ...task, ...payload });
  };

  return isLoading && !tasks.length ? <Loading theme="light" /> : (
    <RelatedTasks
      tasks={relatedTasks}
      onAdd={handleAdd}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
    />
  );
};

RelatedTasksContainer.propTypes = {
  contactId: PropTypes.string.isRequired,
  dealId: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  addTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  receiveTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { tasks: { tasks }, loading: { isGetTasksLoading } } = state;

  return { tasks, isLoading: isGetTasksLoading };
};

const mapDispatchToProps = (dispatch) => ({
  addTask: (payload) => dispatch(addTaskAction(payload)),
  deleteTask: (payload) => dispatch(deleteTaskAction(payload)),
  receiveTask: (payload) => dispatch(receiveTaskAction(payload)),
  updateTask: (payload) => dispatch(updateTaskAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatedTasksContainer);
