import { createPersistedReducer, formatTask } from './utils';

const receiveTasks = (taskState, { payload }) => ({
  tasks: payload.map(formatTask),
});

const clearTasks = () => ({ tasks: [] });

const receiveTask = (taskState, { payload }) => {
  const { tasks: prevTasks } = taskState;
  const tasks = prevTasks.filter((task) => (
    task.id !== payload.id
  ));

  return { tasks: [...tasks, formatTask(payload)] };
};

const removeTask = (taskState, { payload: id }) => {
  const { tasks: prevTasks } = taskState;
  const tasks = prevTasks.filter((task) => task.id !== id);

  return { tasks: [...tasks] };
};

const taskReducer = createPersistedReducer({ tasks: [] }, {
  RECEIVE_TASKS: receiveTasks,
  CLEAR_TASKS: clearTasks,
  RECEIVE_TASK: receiveTask,
  REMOVE_TASK: removeTask,
}, 'tasks');

export default taskReducer;
