import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import { receiveDealsAndContactsAction } from 'js/actions/searchActions';
import { getLoggedInHeaders } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const searchDealsAndContacts = async (action, { dispatch, getState }) => {
  const {
    payload: { searchTerm },
  } = action;

  dispatch(setLoadingAction({ isSearchDealsAndContactsLoading: true }));
  const {
    user: { token },
  } = getState();

  const response = await fetch(`${API_URL}/deals/search/?q=${searchTerm}`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ searchDealsAndContactsError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveDealsAndContactsAction(responseJson));
  }

  dispatch(setLoadingAction({ isSearchDealsAndContactsLoading: false }));
};

const searchMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'SEARCH_DEALS_AND_CONTACTS':
      await searchDealsAndContacts(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default searchMiddleware;
