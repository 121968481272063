import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Tabs, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import filterButton from 'assets/images/filter-button.svg';
import filteredButton from 'assets/images/filtered-button.svg';
import flagSelected from 'assets/images/flag-selected.svg';
import flagUnselected from 'assets/images/flag-unselected.svg';
import FilterForm from 'components/FilterForm';
import FormSelect from 'components/FormSelect';
import PillSelect from 'components/PillSelect';
import UploadDealsContainer from 'containers/UploadDealsContainer';
import {
  dealsTableFields,
  getTabStatusValues,
  models,
  sentiments,
  stages,
  statuses,
  tabs,
} from 'js/constants/deals';
import './styles.scss';

const Deals = (props) => {
  const {
    deals,
    filters,
    onSetFilters,
    onSetSort,
    onUpdateDeal,
    onUpdateUserDeal,
    sort,
    tab,
    setTab,
  } = props;

  const [isUploadDealsVisible, setIsUploadDealsVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [search] = useState('');

  const history = useHistory();

  const handleKeyDown = (e) => {
    e.stopPropagation();

    if (e.key === 'Tab') {
      history.push('/contacts');
      e.preventDefault();
    } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault();
      // remove focus from the active element
      document.activeElement.blur();

      const tabKeys = Object.keys(tabs);

      const currentTabIndex = tabKeys.findIndex((key) => key === tab);

      if (currentTabIndex === -1) {
        setTab(tabKeys[0]);
        return;
      }

      if (e.key === 'ArrowLeft' && currentTabIndex !== 0) {
        setTab(tabKeys[currentTabIndex - 1]);
      }
      if (e.key === 'ArrowRight') {
        if (currentTabIndex === tabKeys.length - 1) setTab(tabKeys[0]);
        else {
          setTab(tabKeys[currentTabIndex + 1]);
        }
      }
    } else if (e.key === 'a') {
      history.push('/deal/new');
    }
  };

  const handleRowClick = (dealId) => {
    history.push(`/deals/${dealId}`);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [tab]);

  const { column: sortColumn, isAscending: isSortAscending } = sort;

  const referrals = [
    ...new Set(
      deals.map((deal) => {
        const {
          userDeal: { referral },
        } = deal;
        return referral;
      }),
    ),
  ]
    .filter((referral) => !!referral)
    .map((referral) => [referral]);

  const filterFields = {
    stage: { display: 'Stage', options: stages },
    status: { display: 'Status', options: getTabStatusValues(tab) },
    referral: { display: 'Referrals', options: referrals },
    model: { display: 'Model', options: models },
  };

  const updateFilters = (newFilters) => onSetFilters(newFilters);
  const numFilters = Object.values(filters).reduce(
    (acc, values) => acc + values.length,
    0,
  );

  const tableFields = dealsTableFields.filter(
    ({ key }) => !(
      (key === 'amount' && tab !== 'portfolio')
        || (key === 'sentiment' && tab !== 'closeddeals')
    ),
  );

  const headerColumns = () => tableFields.map((obj) => {
    const { key, name } = obj;

    let className;
    if (key === sortColumn) {
      className = isSortAscending ? 'sorted ascending' : 'sorted descending';
    }

    return (
      <th key={key}>
        <button
          type="button"
          className={className}
          onClick={() => {
            if (key === sortColumn) {
              onSetSort({
                column: sortColumn,
                isAscending: !isSortAscending,
              });
            } else {
              onSetSort({ column: key, isAscending: true });
            }
          }}
        >
          {name}
        </button>
      </th>
    );
  });

  const rows = () => deals
    .filter((deal) => Object.keys(filters).every((field) => {
      const { [field]: selected } = filters;
      const {
        userDeal: { referral, status },
        [field]: value,
      } = deal;

      if (!selected.length) {
        return true;
      } else if (field === 'referral') {
        return selected.includes(referral);
      } else if (field === 'status') {
        return selected.includes(status);
      } else {
        return selected.includes(value);
      }
    }))
    .filter((deal) => dealsTableFields.find(({ getValue }) => String(getValue(deal))
      .toLowerCase()
      .includes(search.trim().toLowerCase())))
    .sort((a, b) => {
      const { getSortValue } = tableFields.find(
        ({ key }) => key === sortColumn,
      );

      let aValue = getSortValue(a);
      let bValue = getSortValue(b);

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
      }

      if (typeof bValue === 'string') {
        bValue = bValue.toLowerCase();
      }

      if (aValue === bValue) {
        return a.id < b.id ? -1 : 1;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      } else {
        return (isSortAscending && aValue < bValue)
            || (!isSortAscending && aValue > bValue)
          ? -1
          : 1;
      }
    })
    .map((deal) => {
      const {
        id,
        userDeal: { status },
      } = deal;

      const cells = tableFields.map((obj) => {
        const { key, getValue } = obj;

        let content;
        if (key === 'company') {
          content = (
            <div className="dark-blue-text no-underline">
              {getValue(deal)}
            </div>
          );
        } else if (key === 'status') {
          content = (
            <PillSelect
              options={statuses}
              currentValue={status}
              onChange={(val) => onUpdateUserDeal({ id, status: val })}
              className="border-0"
            />
          );
        } else if (key === 'sentiment') {
          content = (
            <FormSelect
              placeholder="Select option"
              options={sentiments}
              isInvalid={false}
              size="small"
              value={getValue(deal) || ''}
              onChange={(val) => onUpdateUserDeal({ id, sentiment: val })}
            />
          );
        } else if (key === 'isFlagged') {
          const { isFlagged } = deal;
          content = (
            <Button
              variant="flag"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onUpdateDeal({ id, isFlagged: !isFlagged });
              }}
            >
              <img
                src={isFlagged ? flagSelected : flagUnselected}
                alt="flag"
                className="flag-icon"
              />
            </Button>
          );
        } else {
          content = getValue(deal);
        }

        return <td key={key}>{content}</td>;
      });

      return (
        <tr key={id} onClick={() => handleRowClick(id)}>
          {cells}
        </tr>
      );
    });

  return (
    <Container fluid className="deals-container">
      <UploadDealsContainer
        isVisible={isUploadDealsVisible}
        onClose={() => setIsUploadDealsVisible(false)}
      />
      <FilterForm
        fields={filterFields}
        filters={filters}
        isVisible={isFilterVisible}
        onClose={() => setIsFilterVisible(false)}
        onSubmit={updateFilters}
      />
      <Row noGutters className="py-3" style={{ width: '200px', float: 'right' }}>
        <Col id="deals-buttons" className="pt-1 pb-4 d-flex">
          <span className="mr-4">
            <Button
              variant="icon"
              type="button"
              onClick={() => setIsFilterVisible(true)}
            >
              {numFilters ? (
                <img src={filteredButton} alt="Filter" />
              ) : (
                <img src={filterButton} alt="Filter" />
              )}
            </Button>
          </span>
          <span>
            <DropdownButton variant="gray" title="Add Deal" menuAlign="right">
              <Dropdown.Item
                as="button"
                onClick={() => history.push('/deal/new')}
              >
                Add Deal
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                as="button"
                onClick={() => setIsUploadDealsVisible(true)}
              >
                Bulk Upload (CSV File)
              </Dropdown.Item>
            </DropdownButton>
          </span>
        </Col>
      </Row>
      <Tabs id="deals-tabs" activeKey={tab} onSelect={(key) => setTab(key)}>
        {Object.keys(tabs).map((key) => (
          <Tab tabClassName="deal-tab" eventKey={key} title={tabs[key].display}>
            <Table responsive>
              <thead>
                <tr>{headerColumns()}</tr>
              </thead>
              <tbody>{rows()}</tbody>
            </Table>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

Deals.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onSetSort: PropTypes.func.isRequired,
  onUpdateDeal: PropTypes.func.isRequired,
  onUpdateUserDeal: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.string,
    isAscending: PropTypes.bool,
  }).isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default Deals;
