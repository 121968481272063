import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import LogIn from 'components/LogIn';
import { resetPasswordAction } from 'js/actions/userActions';

const ResetPasswordContainer = (props) => {
  const {
    resetPassword,
    error,
    isLoading,
  } = props;

  const { token } = useParams();

  return (
    <LogIn
      ctaText="Submit"
      isLoading={isLoading}
      error={error}
      onSubmit={resetPassword}
      title="Reset password"
      emailLabel="CONFIRM EMAIL"
      passwordLabel="NEW PASSWORD"
      token={token}
    />
  );
};

ResetPasswordContainer.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { resetPasswordError },
    loading: { isResetPasswordLoading },
  } = state;

  return {
    error: resetPasswordError || {},
    isLoading: isResetPasswordLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (payload) => dispatch(resetPasswordAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordContainer);
