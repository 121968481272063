import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import DealForm from 'components/DealForm';
import { updateDealAction } from 'js/actions/dealActions';
import {
  clearCompaniesSearchResultsAction, clearSearchResultCompanyDetailsAction,
} from 'js/actions/companyActions';

const UpdateDealContainer = (props) => {
  const {
    deals,
    error,
    isLoading,
    updateDeal,
    searchResultCompanyDetail,
    isGetCompanyLoading,
    clearCompaniesSearchResults,
    clearSearchResultCompanyDetail,
  } = props;

  const { dealId } = useParams();

  const deal = deals.find(({ id }) => id === dealId);
  const { id } = deal;

  const handleUpdateDeal = (payload) => {
    updateDeal({ id, ...payload });
  };

  return (
    <DealForm
      deal={deal}
      error={error}
      header="Update deal"
      isLoading={isLoading}
      onSubmit={handleUpdateDeal}
      submitText="Update Deal"
      searchResultCompanyDetail={searchResultCompanyDetail}
      isGetCompanyLoading={isGetCompanyLoading}
      clearCompaniesSearchResults={clearCompaniesSearchResults}
      clearSearchResultCompanyDetail={clearSearchResultCompanyDetail}
    />
  );
};

UpdateDealContainer.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateDeal: PropTypes.func.isRequired,
  searchResultCompanyDetail: PropTypes.shape({}).isRequired,
  isGetCompanyLoading: PropTypes.bool.isRequired,
  clearCompaniesSearchResults: PropTypes.func.isRequired,
  clearSearchResultCompanyDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    deals: { deals },
    errors: { updateDealError },
    loading: { isUpdateDealLoading, isGetCompanyLoading },
    companies: { searchResultCompanyDetail },
  } = state;

  return {
    deals,
    error: updateDealError || {},
    isLoading: isUpdateDealLoading,
    isGetCompanyLoading,
    searchResultCompanyDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateDeal: (payload) => dispatch(updateDealAction(payload)),
  clearCompaniesSearchResults: () => dispatch(clearCompaniesSearchResultsAction()),
  clearSearchResultCompanyDetail: () => dispatch(clearSearchResultCompanyDetailsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateDealContainer);
