const getSharesAction = () => ({ type: 'GET_SHARES' });

const clearSharesAction = () => ({ type: 'CLEAR_SHARES' });

const receiveSharesAction = (payload) => ({ type: 'RECEIVE_SHARES', payload });

const receiveShareAction = (payload) => ({ type: 'RECEIVE_SHARE', payload });

const removeShareAction = (payload) => ({ type: 'REMOVE_SHARE', payload });

const sendShareAction = (payload) => ({ type: 'SEND_SHARE', payload });

const updateShareAction = (payload) => ({ type: 'UPDATE_SHARE', payload });

export {
  getSharesAction,
  clearSharesAction,
  receiveShareAction,
  receiveSharesAction,
  removeShareAction,
  sendShareAction,
  updateShareAction,
};
