import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FeedbackForm from 'components/FeedbackForm';
import { sendFeedbackAction } from 'js/actions/userActions';

const FeedbackFormContainer = (props) => {
  const {
    sendFeedback,
    error,
    isLoading,
    isVisible,
    onClose,
  } = props;

  return (
    <FeedbackForm
      error={error}
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSubmit={sendFeedback}
    />
  );
};

FeedbackFormContainer.propTypes = {
  sendFeedback: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { sendFeedbackError },
    loading: { isSendFeedbackLoading },
  } = state;

  return {
    error: sendFeedbackError || {},
    isLoading: isSendFeedbackLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendFeedback: (payload) => dispatch(sendFeedbackAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeedbackFormContainer);
