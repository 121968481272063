import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const Loading = (props) => {
  const { theme } = props;

  let className;
  let textColor;

  if (theme === 'dark') {
    className = 'loading-dark';
    textColor = 'white-text';
  } else {
    className = 'loading-light';
    textColor = 'blue-gray-text';
  }

  return (
    <div className={className}>
      <div className="text-center loading-container">
        <Spinner animation="border" role="status" className="loading-spinner" />
        <div className={`pt-4 large-text bold-text ${textColor}`}>
          Loading...
        </div>
        <div className={`normal-text ${textColor}`}>
          Please be patient as the page loads.
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Loading;
