import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useHistory } from 'react-router-dom';
import filterButton from 'assets/images/filter-button.svg';
import filteredButton from 'assets/images/filtered-button.svg';
import flagSelected from 'assets/images/flag-selected.svg';
import flagUnselected from 'assets/images/flag-unselected.svg';
import FilterForm from 'components/FilterForm';
import FormSelect from 'components/FormSelect';
import NoteForm from 'components/NoteForm';
import AddContactContainer from 'containers/AddContactContainer';
import UploadContactsContainer from 'containers/UploadContactsContainer';
import {
  contactsTableFields,
  flags,
  frequencies,
  types,
  stages,
} from 'js/constants/contacts';
import { getInviteNote } from 'js/utils';
import './styles.scss';

const Contacts = (props) => {
  const {
    contacts,
    filters,
    onSendInvite,
    onSetFilters,
    onSetSort,
    onUpdate,
    sort,
    user: { fullName },
  } = props;

  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isUploadContactsVisible, setIsUploadContactsVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isSendInviteVisible, setIsSendInviteVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [search] = useState('');

  const history = useHistory();

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      history.push('/tasks');
    } else if (e.key === 'a') {
      setIsAddContactVisible(true);
    }

    e.stopPropagation();
  };

  const handleRowClick = (contactId) => {
    history.push(`/contacts/${contactId}`);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { column: sortColumn, isAscending: isSortAscending } = sort;

  const filterFields = {
    type: { display: 'Type', options: types },
    stage: { display: 'Stage', options: stages },
    frequency: { display: 'Frequency', options: frequencies },
    isFlagged: { display: 'Flagged', options: flags },
  };

  const updateFilters = (newFilters) => onSetFilters(newFilters);
  const numFilters = Object.values(filters).reduce((acc, values) => (
    acc + values.length
  ), 0);

  const getHeader = (key, name) => {
    let className;
    if (key === sortColumn) {
      className = (
        isSortAscending
          ? 'sorted ascending'
          : 'sorted descending'
      );
    }

    return (
      <th key={key}>
        <button
          type="button"
          className={className}
          onClick={() => {
            if (key === sortColumn) {
              onSetSort({ column: sortColumn, isAscending: !isSortAscending });
            } else {
              onSetSort({ column: key, isAscending: true });
            }
          }}
        >
          {name}
        </button>
      </th>
    );
  };

  const headerColumns = () => (
    <>
      {contactsTableFields.map(({ key, name }) => getHeader(key, name))}
    </>
  );

  const rows = () => contacts.filter((contact) => (
    Object.keys(filters).every((field) => {
      const { [field]: selected } = filters;
      return selected.length ? selected.includes(contact[field]) : true;
    })
  )).filter((contact) => (
    Object.values(contact).find((value) => (
      String(value).toLowerCase().includes(search.trim().toLowerCase())
    ))
  )).sort((a, b) => {
    let aValue;
    let bValue;

    if (sortColumn === 'invite') {
      if (a.isUser) {
        aValue = 2;
      } else if (a.isInvited) {
        aValue = 1;
      } else {
        aValue = 0;
      }

      if (b.isUser) {
        bValue = 2;
      } else if (b.isInvited) {
        bValue = 1;
      } else {
        bValue = 0;
      }
    } else {
      const { getSortValue } = contactsTableFields.find(({ key }) => (
        key === sortColumn
      ));

      aValue = getSortValue(a);
      bValue = getSortValue(b);

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
      }

      if (typeof bValue === 'string') {
        bValue = bValue.toLowerCase();
      }
    }

    if (aValue === bValue) {
      return a.id < b.id ? -1 : 1;
    } else if (aValue === null) {
      return 1;
    } else if (bValue === null) {
      return -1;
    } else {
      return ((
        (isSortAscending && aValue < bValue)
        || (!isSortAscending && aValue > bValue)
      ) ? -1 : 1);
    }
  }).map((contact) => {
    const { id, isInvited, isUser } = contact;

    const cells = contactsTableFields.map((obj) => {
      const { key, getValue } = obj;

      let content;

      if (key === 'fullName') {
        content = (
          <div className="dark-blue-text no-underline">
            {getValue(contact)}
          </div>
        );
      } else if (key === 'frequency') {
        content = (
          <FormSelect
            placeholder="Select option"
            options={frequencies}
            isInvalid={false}
            size="small"
            value={getValue(contact) || ''}
            onChange={(val) => onUpdate({ id, frequency: val })}
          />
        );
      } else if (key === 'isFlagged') {
        const { isFlagged } = contact;
        content = (
          <Button
            variant="flag"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onUpdate({ id, isFlagged: !isFlagged });
            }}
          >
            <img
              src={isFlagged ? flagSelected : flagUnselected}
              alt="flag"
              className="flag-icon"
            />
          </Button>
        );
      } else if (key === 'invite') {
        if (isUser) {
          content = 'Joined';
        } else {
          content = (
            <Button
              size="small"
              variant="gray"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedContact(contact);
                setIsSendInviteVisible(true);
              }}
            >
              {isInvited ? 'Resend' : 'Invite'}
            </Button>
          );
        }
      } else {
        content = getValue(contact);
      }

      return (
        <td key={key}>
          {content}
        </td>
      );
    });

    return (
      <tr key={id} onClick={() => handleRowClick(id)}>
        {cells}
      </tr>
    );
  });

  const { firstName = '' } = selectedContact || {};

  return (
    <Container fluid className="contacts-container">
      <AddContactContainer
        isVisible={isAddContactVisible}
        onClose={() => setIsAddContactVisible(false)}
      />
      <UploadContactsContainer
        isVisible={isUploadContactsVisible}
        onClose={() => setIsUploadContactsVisible(false)}
      />
      <FilterForm
        fields={filterFields}
        filters={filters}
        isVisible={isFilterVisible}
        onClose={() => setIsFilterVisible(false)}
        onSubmit={updateFilters}
      />
      <NoteForm
        title="Confirm invite"
        description="Your contact will receive an email inviting them to join the Pipeline platform."
        disclaimer=""
        initialNote={
          getInviteNote(firstName, fullName)
        }
        isLoading={false}
        isVisible={isSendInviteVisible}
        onClose={() => setIsSendInviteVisible(false)}
        onSubmit={() => onSendInvite(selectedContact)}
      />
      <Row noGutters className="py-3 full-width">
        <Col className="py-1 d-flex justify-content-end">
          <span className="mr-4">
            <Button
              variant="icon"
              type="button"
              onClick={() => setIsFilterVisible(true)}
            >
              {numFilters ? (
                <img src={filteredButton} alt="Filter" />
              ) : (
                <img src={filterButton} alt="Filter" />
              )}
            </Button>
          </span>
          <span>
            <DropdownButton
              variant="gray"
              title="Add Contact"
              menuAlign="right"
            >
              <Dropdown.Item
                as="button"
                onClick={() => setIsAddContactVisible(true)}
              >
                Add Contact
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                as="button"
                onClick={() => setIsUploadContactsVisible(true)}
              >
                Bulk Upload (CSV File)
              </Dropdown.Item>
            </DropdownButton>
          </span>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            {headerColumns()}
          </tr>
        </thead>
        <tbody>
          {rows()}
        </tbody>
      </Table>
    </Container>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  onSendInvite: PropTypes.func.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onSetSort: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.string,
    isAscending: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string,
  }).isRequired,
};

export default Contacts;
