import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import CloseButton from 'components/CloseButton';
import FormSelect from 'components/FormSelect';
import Modal from 'components/Modal';
import './styles.scss';

const ShareForm = (props) => {
  const {
    options,
    filterFields,
    filterOptions,
    title,
    header,
    isLoading,
    isVisible,
    onClose,
    onSubmit,
  } = props;

  const handleFilterInit = () => {
    const filters = [];
    filterOptions.forEach((filterOption) => {
      filters.push(filterOption[0][0]);
    });
    return filters;
  };

  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState(handleFilterInit());

  const clearSelected = () => setSelected([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selected);
    clearSelected();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const updateSelected = (newId) => {
    const newSelected = selected.includes(newId)
      ? selected.filter((val) => val !== newId)
      : [...selected, newId];

    setSelected(newSelected);
  };

  const handleFiltersChange = (value, index) => {
    const newFilters = [...filters];
    newFilters[index] = value;
    setFilters(newFilters);
  };

  // filter options based on all the filters.
  const content = options.filter((option) => {
    let isMatch = true;
    filters.forEach((filter, index) => {
      if (filter !== filterOptions[index][0][0]) {
        isMatch = isMatch && option[filterFields[index]] === filter;
      }
    });
    return isMatch;
  }).map(({ id, display }) => (
    <Form.Group key={id} controlId={id}>
      <Form.Check type="checkbox">
        <Form.Check.Input
          type="checkbox"
          checked={selected.includes(id)}
          onChange={() => updateSelected(id)}
          className="form-check-gray"
        />
        <Form.Check.Label className="dark-blue-text ml-3">
          {display}
        </Form.Check.Label>
      </Form.Check>
    </Form.Group>
  ));

  return (
    <Modal centered show={isVisible} onHide={handleClose}>
      <Container fluid className="share-form-container">
        <CloseButton onClick={handleClose} />
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-3">
            <h4 className="bold-text dark-blue-text">{title}</h4>
          </div>
          <div className="pb-3">
            <h5 className="bold-text dark-blue-text">{header}</h5>
          </div>
          <div className="d-flex pb-4">
            <span className="bold-text dark-blue-text mr-4">Filters: </span>
            {filterOptions.map((filterOption, index) => (
              <FormSelect
                placeholder={filterOption[0][0]}
                options={filterOption}
                isInvalid={false}
                size="small"
                value={filters[index]}
                onChange={(value) => handleFiltersChange(value, index)}
              />
            ))}
            <button
              type="button"
              className="clear-button"
              onClick={clearSelected}
            >
              Clear All
            </button>
          </div>
          {content}
          <div className="pt-3 text-center">
            <span className="pr-3">
              <Button variant="border" type="button" onClick={handleClose}>
                Cancel
              </Button>
            </span>
            <span className="pl-3">
              <Button
                variant="gray"
                type="submit"
                disabled={!selected.length || isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Confirm'
                )}
              </Button>
            </span>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

ShareForm.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      display: PropTypes.string,
    }),
  ).isRequired,
  filterFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  ).isRequired,
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ShareForm;
