import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import AcceptInviteContainer from 'containers/AcceptInviteContainer';
import AuthenticatedRouteContainer from 'containers/AuthenticatedRouteContainer';
import ContactDetailsContainer from 'containers/ContactDetailsContainer';
import ContactsContainer from 'containers/ContactsContainer';
import ChangeEmailContainer from 'containers/ChangeEmailContainer';
import ChangePasswordContainer from 'containers/ChangePasswordContainer';
import DealDetailsContainer from 'containers/DealDetailsContainer';
import DealsContainer from 'containers/DealsContainer';
import AddDealContainer from 'containers/AddDealContainer';
import UpdateDealContainer from 'containers/UpdateDealContainer';
import FooterContainer from 'containers/FooterContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';
import LogInContainer from 'containers/LogInContainer';
import NavbarContainer from 'containers/NavbarContainer';
import PublicRouteContainer from 'containers/PublicRouteContainer';
import ResetPasswordContainer from 'containers/ResetPasswordContainer';
import ScrollToTopContainer from 'containers/ScrollToTopContainer';
import SettingsContainer from 'containers/SettingsContainer';
import SignUpContainer from 'containers/SignUpContainer';
import TasksContainer from 'containers/TasksContainer';
import SidebarContainer from 'containers/SidebarContainer';
import ResendVerificationEmailContainer from 'containers/ResendVerificationEmailContainer';
import { clearErrorsAction } from 'js/actions/errorActions';
import { resetLoadingAction } from 'js/actions/loadingActions';

const RootContainer = (props) => {
  const { clearErrors, resetLoading, user: { token, isVerified } } = props;

  const history = useHistory();

  useEffect(() => history.listen(() => {
    clearErrors();
    resetLoading();
  }), []);

  const headerClass = token ? ' fixed-header' : '';

  return (
    <div className={`full-height${headerClass}`}>
      {token && <NavbarContainer />}
      <ScrollToTopContainer>
        {!token && <NavbarContainer />}
        <div className={`d-flex ${token && 'fixed-content'}`}>
          {token && <SidebarContainer />}
          <div className={`full-width ${token && 'overflow-auto'}`}>
            {token && !isVerified ? (
              <AuthenticatedRouteContainer>
                <ResendVerificationEmailContainer />
              </AuthenticatedRouteContainer>
            )
              : (
                <Switch>
                  <PublicRouteContainer path="/sign-up">
                    <SignUpContainer />
                  </PublicRouteContainer>
                  <PublicRouteContainer path="/log-in">
                    <LogInContainer />
                  </PublicRouteContainer>
                  <PublicRouteContainer path="/password/forgot">
                    <ForgotPasswordContainer />
                  </PublicRouteContainer>
                  <PublicRouteContainer path="/password/reset/:token">
                    <ResetPasswordContainer />
                  </PublicRouteContainer>
                  <PublicRouteContainer path="/invites/:inviteId">
                    <AcceptInviteContainer />
                  </PublicRouteContainer>
                  <AuthenticatedRouteContainer path="/contacts/:contactId">
                    <ContactDetailsContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer path="/contacts">
                    <ContactsContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer exact path="/deals/:dealId">
                    <DealDetailsContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer path="/deal/new">
                    <AddDealContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer path="/deals/:dealId/update">
                    <UpdateDealContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer path="/deals">
                    <DealsContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer path="/tasks">
                    <TasksContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer
                    path={['/settings/email', '/settings/alternate']}
                  >
                    <ChangeEmailContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer path="/settings/password">
                    <ChangePasswordContainer />
                  </AuthenticatedRouteContainer>
                  <AuthenticatedRouteContainer path="/settings">
                    <SettingsContainer />
                  </AuthenticatedRouteContainer>
                  <Route path="/">
                    <Redirect to="/sign-up" />
                  </Route>
                </Switch>
              )}
          </div>
        </div>
        <FooterContainer />
      </ScrollToTopContainer>
    </div>
  );
};

RootContainer.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  resetLoading: PropTypes.func.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
    isVerified: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrorsAction()),
  resetLoading: () => dispatch(resetLoadingAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RootContainer);
