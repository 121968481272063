import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import SignUp from 'components/SignUp';
import { acceptInviteAction, getInviteAction } from 'js/actions/inviteActions';

const AcceptInviteContainer = (props) => {
  const {
    acceptInvite,
    error,
    getInvite,
    getInviteError: { detail },
    invite,
    isLoading,
    isGetInviteLoading,
  } = props;

  const { inviteId } = useParams();

  useEffect(() => {
    if (inviteId) {
      getInvite({ id: inviteId });
    }
  }, [inviteId]);

  const handleAcceptInvite = (payload) => acceptInvite({
    ...payload,
    id: inviteId,
  });

  const content = isGetInviteLoading ? <Loading theme="dark" /> : (
    <SignUp
      invite={invite}
      isLoading={isLoading}
      isWaitlist={false}
      error={error}
      onSignUp={handleAcceptInvite}
      title="Accept invitation"
    />
  );

  return detail ? <Redirect to="/sign-up" /> : content;
};

AcceptInviteContainer.propTypes = {
  acceptInvite: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  getInvite: PropTypes.func.isRequired,
  getInviteError: PropTypes.shape({
    detail: PropTypes.string,
  }).isRequired,
  invite: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isGetInviteLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    invite,
    errors: { acceptInviteError, getInviteError },
    loading: { isAcceptInviteLoading, isGetInviteLoading },
  } = state;

  return {
    error: acceptInviteError || {},
    getInviteError: getInviteError || {},
    invite,
    isLoading: isAcceptInviteLoading,
    isGetInviteLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  acceptInvite: (payload) => dispatch(acceptInviteAction(payload)),
  getInvite: (payload) => dispatch(getInviteAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptInviteContainer);
