import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import PasswordInput from 'components/PasswordInput';
import './styles.scss';

const ChangePassword = (props) => {
  const {
    changePassword,
    error,
    isLoading,
  } = props;

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const isButtonDisabled = isLoading || !(currentPassword && newPassword);

  const {
    detail,
    errors: {
      password: [passwordError] = [],
    } = {},
  } = error;

  const shouldRenderFormError = detail && !passwordError;

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword({ currentPassword, newPassword });
  };

  return (
    <Container fluid className="change-password-container">
      <div className="change-password-view-container">
        <div className="pb-4">
          <h3 className="bold-text dark-blue-text">
            Password
          </h3>
        </div>
        <Form noValidate onSubmit={handleSubmit} className="change-password-form">
          <div className="pb-3">
            <h4 className="bold-text dark-blue-text">
              Change Password
            </h4>
          </div>
          <Form.Group controlId="current-password">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              Current password
            </Form.Label>
            <PasswordInput
              theme="light"
              isInvalid={!!passwordError}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            {passwordError && (
              <Form.Control.Feedback type="invalid">
                {passwordError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="new-password">
            <Form.Label
              className="small-text bold-text transparent-blue-gray-text"
            >
              New password
            </Form.Label>
            <PasswordInput
              theme="light"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
          <div className="pt-4 text-right">
            <Button
              variant="border"
              type="submit"
              disabled={isButtonDisabled}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Save'
              )}
            </Button>
            {shouldRenderFormError && (
              <div className="invalid-feedback text-right">{detail}</div>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      password: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ChangePassword;
