import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import { clearInviteAction, receiveInviteAction } from 'js/actions/inviteActions';
import { receiveUserAction } from 'js/actions/userActions';
import { getLoggedInHeaders, publicHeaders, formatUserPayload } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const sendInvite = async (action, { dispatch, getState }) => {
  const { payload } = action;
  const { user: { token } } = getState();

  dispatch(setLoadingAction({ isSendInviteLoading: true }));

  const response = await fetch(`${API_URL}/invites/`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ sendInviteError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
  }

  dispatch(setLoadingAction({ isSendInviteLoading: false }));
};

const getInvite = async (action, { dispatch }) => {
  const { payload: { id } } = action;

  dispatch(setLoadingAction({ isGetInviteLoading: true }));

  const response = await fetch(`${API_URL}/invites/${id}/`, {
    method: 'GET',
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getInviteError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveInviteAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetInviteLoading: false }));
};

const acceptInvite = async (action, { dispatch }) => {
  const { payload: { id, ...restOfPayload } } = action;

  dispatch(setLoadingAction({ isAcceptInviteLoading: true }));

  const response = await fetch(`${API_URL}/invites/${id}/accept/`, {
    method: 'POST',
    body: JSON.stringify(formatUserPayload(restOfPayload)),
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ acceptInviteError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(clearInviteAction());
    dispatch(receiveUserAction({ ...user, token }));
  }

  dispatch(setLoadingAction({ isAcceptInviteLoading: false }));
};

const userMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'SEND_INVITE':
      await sendInvite(action, store);
      break;
    case 'GET_INVITE':
      await getInvite(action, store);
      break;
    case 'ACCEPT_INVITE':
      await acceptInvite(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default userMiddleware;
