import React from 'react';
import PropTypes from 'prop-types';
import { Editor, RichUtils } from 'draft-js';
import './styles.scss';

const RichTextField = (props) => {
  const {
    editorState, onChange, placeholder, readOnly = false,
  } = props;

  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      onChange(newState);
      return 'handled';
    } else {
      return 'not-handled';
    }
  };

  return (
    <Editor
      editorState={editorState}
      handleKeyCommand={handleKeyCommand}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
    />
  );
};

RichTextField.propTypes = {
  editorState: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

RichTextField.defaultProps = {
  readOnly: false,
};

export default RichTextField;
