import { createPersistedReducer, formatInvite } from './utils';

const receiveInvite = (inviteState, { payload }) => formatInvite(payload);

const clearInvite = () => ({});

const contactReducer = createPersistedReducer({}, {
  RECEIVE_INVITE: receiveInvite,
  CLEAR_INVITE: clearInvite,
}, 'invite');

export default contactReducer;
