import { fieldMap as contactFieldMap } from 'js/constants/contacts';
import { fieldMap as dealFieldMap } from 'js/constants/deals';
import { fieldMap as taskFieldMap } from 'js/constants/tasks';
import { fieldMap as userFieldMap } from 'js/constants/users';

const publicHeaders = { 'Content-Type': 'application/json' };

const getLoggedInHeaders = (token) => ({
  ...publicHeaders,
  Authorization: `Token ${token}`,
});

const formatPayload = (payload, fieldMap) => (
  Object.keys(payload).reduce((acc, field) => {
    const { [field]: formattedField = field } = fieldMap;
    acc[formattedField] = payload[field];
    return acc;
  }, {})
);

const formatUserPayload = (payload) => formatPayload(payload, userFieldMap);

const formatContactPayload = (payload) => formatPayload(
  payload,
  contactFieldMap,
);

const formatDealPayload = (payload) => formatPayload(payload, dealFieldMap);

const formatDealPayloadWithFiles = (payload) => {
  const formData = new FormData();
  const { files, ...restOfPayload } = payload;

  if (files && files.length) {
    files.forEach((file) => {
      formData.append('files', file);
    });
  }

  const formattedPayload = formatDealPayload(restOfPayload);

  Object.keys(formattedPayload).forEach((key) => {
    formData.append(key, formattedPayload[key]);
  });

  return formData;
};

const formatTaskPayload = (payload) => formatPayload(payload, taskFieldMap);

export {
  publicHeaders,
  getLoggedInHeaders,
  formatContactPayload,
  formatDealPayload,
  formatDealPayloadWithFiles,
  formatTaskPayload,
  formatUserPayload,
};
