import { createPersistedReducer, formatContact } from './utils';

const receiveContacts = (contactState, { payload }) => ({
  contacts: payload.map(formatContact),
});

const clearContacts = () => ({ contacts: [] });

const receiveContact = (contactState, { payload }) => {
  const { contacts: prevContacts } = contactState;
  const contacts = prevContacts.filter((contact) => (
    contact.id !== payload.id
  ));

  return { contacts: [...contacts, formatContact(payload)] };
};

const removeContact = (contactState, { payload: id }) => {
  const { contacts: prevContacts } = contactState;
  const contacts = prevContacts.filter((contact) => contact.id !== id);

  return { contacts: [...contacts] };
};

const contactReducer = createPersistedReducer({ contacts: [] }, {
  RECEIVE_CONTACTS: receiveContacts,
  CLEAR_CONTACTS: clearContacts,
  RECEIVE_CONTACT: receiveContact,
  REMOVE_CONTACT: removeContact,
}, 'contacts');

export default contactReducer;
