import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChangePassword from 'components/ChangePassword';
import { changePasswordAction } from 'js/actions/userActions';

const ChangePasswordContainer = (props) => {
  const {
    changePassword,
    error,
    isLoading,
  } = props;

  return (
    <ChangePassword
      changePassword={changePassword}
      error={error}
      isLoading={isLoading}
    />
  );
};

ChangePasswordContainer.propTypes = {
  changePassword: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { changePasswordError },
    loading: { isChangePasswordLoading },
  } = state;

  return {
    error: changePasswordError || {},
    isLoading: isChangePasswordLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePassword: (payload) => dispatch(changePasswordAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordContainer);
