import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import deleteButton from 'assets/images/delete-button.svg';
import editButton from 'assets/images/edit-button.svg';
import viewOnlyIcon from 'assets/images/view-only-icon.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import DealMemo from 'components/DealMemo';
import FormSelect from 'components/FormSelect';
import NoteForm from 'components/NoteForm';
import PillSelect from 'components/PillSelect';
import RichTextField from 'components/RichTextField';
import ShareForm from 'components/ShareForm';
import RelatedTasksContainer from 'containers/RelatedTasksContainer';
import { types } from 'js/constants/contacts';
import { interests, statuses, tabs } from 'js/constants/deals';
import {
  formatDate,
  formatMillions,
  formatThousands,
  getShareNote,
  isEditorStateEqual,
} from 'js/utils';
import './styles.scss';

const DealDetails = (props) => {
  const {
    contacts,
    deal,
    deal: {
      id,
      user: { id: dealUserId },
      userDeal: { status },
      company,
      stage,
      amount,
      model,
      sector,
      closeDate,
      revenue,
      structure,
      website,
      deck,
      dropbox,
      memo,
      ceoName,
      ceoEmail,
    },
    error,
    isDeleteDealLoading,
    isSendShareLoading,
    isShareDealLoading,
    isUpdateDealLoading,
    isUpdateUserDealNotesLoading,
    onDelete,
    onSaveNotes,
    onSendShare,
    onShare,
    onUpdate,
    onUpdateInterest,
    onUpdateStatus,
    richNotes,
    shares,
    user: { id: userId },
  } = props;

  const [newNotes, setNewNotes] = useState(richNotes);
  const [selectedShareId, setSelectedShareId] = useState(null);
  const [isDeleteDealVisible, setIsDeleteDealVisible] = useState(false);
  const [isMemoVisible, setIsMemoVisible] = useState(false);
  const [isSendShareVisible, setIsSendShareVisible] = useState(false);
  const [isShareDealVisible, setIsShareDealVisible] = useState(false);

  const history = useHistory();

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      const tab = Object.keys(tabs).find((val) => (
        tabs[val].statuses.includes(status)
      ));
      history.push(`/${tab}`);
    }
  };

  const handleRowClick = (recipientId) => {
    history.push(`/contacts/${recipientId}`);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const isOwner = userId === dealUserId;

  const shareContent = shares.map((share) => {
    const {
      recipientId,
      id: shareId,
      interest,
      isShared,
    } = share;

    const {
      company: contactCompany,
      fullName: contactName,
    } = contacts.find(({ id: contactId }) => recipientId === contactId);

    return (
      <div
        key={recipientId}
        onClick={() => handleRowClick(recipientId)}
        role="presentation"
        className="share-row"
      >
        <div className="deal-contact">
          <div
            className="dark-blue-text d-flex justify-content-between"
          >
            <span>
              <span className="bold-text">
                {contactName}
              </span>
              {` — ${contactCompany}`}
            </span>
            <span className="pl-2">
              {isShared ? (
                <FormSelect
                  placeholder="Interest Level"
                  options={interests}
                  isInvalid={false}
                  size="small"
                  value={interest}
                  onChange={(val) => onUpdateInterest(shareId, val)}
                />
              ) : (
                <Button
                  size="small"
                  variant="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedShareId(shareId);
                    setIsSendShareVisible(true);
                  }}
                >
                  Share
                </Button>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  });

  const shareOptions = contacts.filter(({ id: contactId }) => (
    !shares.find(({ recipientId }) => recipientId === contactId)
  )).map((contact) => {
    const {
      id: contactId,
      fullName: contactName,
      company: contactCompany,
      type: contactType,
    } = contact;

    const display = `${contactName} — ${contactCompany}`;

    return {
      id: contactId,
      display: contactType ? `${display} — ${contactType}` : display,
      type: contactType,
    };
  });

  const selectedContact = selectedShareId ? contacts.find((contact) => {
    const { id: contactId } = contact;

    const { recipientId: selectedContactId } = shares.find((share) => (
      share.id === selectedShareId
    )) || {};
    return contactId === selectedContactId;
  }) : {};

  const { firstName = '' } = selectedContact;

  return (
    <Container fluid className="deal-details-container">
      {isOwner && (
        <>
          <ConfirmationModal
            cancelText="Cancel"
            confirmText="Delete"
            header="Are you sure?"
            description="Deleting a deal cannot be undone."
            isLoading={isDeleteDealLoading}
            isVisible={isDeleteDealVisible}
            onClose={() => setIsDeleteDealVisible(false)}
            onConfirm={() => onDelete(id)}
          />
          <ShareForm
            options={shareOptions}
            filterField="type"
            filterOptions={[['All Types'], ...types]}
            title="Add Potential Introductions"
            header="List of Potential Introductions"
            isLoading={isShareDealLoading}
            isVisible={isShareDealVisible}
            onClose={() => setIsShareDealVisible(false)}
            onSubmit={onShare}
          />
          <NoteForm
            title="Share deal"
            description="This will share Deal Details, Deal Memo, and Supporting Documents
            with recipient(s). It will not share your Personal Notes."
            disclaimer="I agree that I have permission to share this information with this person"
            initialNote={getShareNote(firstName, company, sector, stage)}
            isLoading={isSendShareLoading}
            isVisible={isSendShareVisible}
            onClose={() => {
              setIsSendShareVisible(false);
              setSelectedShareId(null);
            }}
            onSubmit={(note) => onSendShare({ id: selectedShareId, note })}
          />
        </>
      )}
      <DealMemo
        deal={deal}
        error={error}
        isEditable={isOwner}
        isLoading={isUpdateDealLoading}
        isVisible={isMemoVisible}
        onClose={() => setIsMemoVisible(false)}
        onSave={onUpdate}
      />
      <Row noGutters className="py-3 full-width">
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="bold-text dark-blue-text">
              Deal details
            </h4>
          </div>
          {isOwner ? (
            <div>
              <span className="mr-3">
                <Button
                  variant="icon"
                  type="button"
                  onClick={() => history.push(`/deals/${id}/update`)}
                >
                  <img src={editButton} alt="Edit" />
                </Button>
              </span>
              <span>
                <Button
                  variant="icon"
                  type="button"
                  onClick={() => setIsDeleteDealVisible(true)}
                >
                  <img src={deleteButton} alt="Delete" />
                </Button>
              </span>
            </div>
          ) : (
            <div className="view-only-tag">
              <img
                alt="View Only"
                src={viewOnlyIcon}
                className="view-only-icon"
              />
              VIEW ONLY
            </div>
          )}
        </Col>
      </Row>
      <Row className="mx-md-n4">
        <Col md={5} className="px-md-4">
          <div className="detail-section">
            <div className="detail-row">
              <span className="blue-gray-text">Company</span>
              <span className="dark-blue-text">{company}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Status</span>
              <span className="pl-1">
                <PillSelect
                  options={statuses}
                  currentValue={status}
                  onChange={(val) => onUpdateStatus(val)}
                />
              </span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">My investment amount</span>
              <span className="dark-blue-text">
                {formatThousands(amount)}
              </span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Stage</span>
              <span className="dark-blue-text">{stage}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Buyer Profile</span>
              <span className="dark-blue-text">{model}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Sector</span>
              <span className="dark-blue-text">{sector}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Annualized net revenue</span>
              <span className="dark-blue-text">
                {formatMillions(revenue)}
              </span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Deal structure</span>
              <span className="dark-blue-text">{structure}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">Decision Date</span>
              <span className="dark-blue-text">{formatDate(closeDate)}</span>
            </div>
            {website && (
              <div className="detail-row">
                <span className="blue-gray-text">Website</span>
                <span className="dark-blue-text">
                  <a
                    href={website}
                    target="_blank"
                    rel="noreferrer"
                    className="bold-text cool-blue-text underlined"
                  >
                    {website}
                  </a>
                </span>
              </div>
            )}
            {deck && (
              <div className="detail-row">
                <span className="blue-gray-text">Deck</span>
                <span className="dark-blue-text">
                  <a
                    href={deck}
                    target="_blank"
                    rel="noreferrer"
                    className="bold-text cool-blue-text underlined"
                  >
                    View Deck
                  </a>
                </span>
              </div>
            )}
            {dropbox && (
              <div className="detail-row">
                <span className="blue-gray-text">Documents</span>
                <span className="dark-blue-text">
                  <a
                    href={dropbox}
                    target="_blank"
                    rel="noreferrer"
                    className="bold-text cool-blue-text underlined"
                  >
                    Download supporting documents
                  </a>
                </span>
              </div>
            )}
            <div className="detail-row">
              <span className="blue-gray-text">CEO Name</span>
              <span className="dark-blue-text">{ceoName}</span>
            </div>
            <div className="detail-row">
              <span className="blue-gray-text">CEO Email</span>
              <span className="dark-blue-text">{ceoEmail}</span>
            </div>
            {(memo || isOwner) && (
              <div className="detail-row">
                <span className="blue-gray-text">Organizer Memo</span>
                <span className="dark-blue-text">
                  <button
                    type="button"
                    className="deal-details-link"
                    onClick={() => setIsMemoVisible(true)}
                  >
                    View organizer memo
                  </button>
                </span>
              </div>
            )}
          </div>
        </Col>
        <Col md={7} className="px-md-4 notes-container">
          <div>
            <h4 className="bold-text dark-blue-text">
              Notes
            </h4>
          </div>
          <div className="notes-section">
            <div className="my-2">
              <RichTextField
                editorState={newNotes}
                onChange={setNewNotes}
                placeholder="Your notes about this deal go here (optional)"
              />
            </div>
            {!isEditorStateEqual(newNotes, richNotes) && (
              <div className="text-right mb-2">
                <span className="mr-4">
                  <Button
                    variant="border"
                    type="button"
                    onClick={() => setNewNotes(richNotes)}
                  >
                    Cancel
                  </Button>
                </span>
                <span>
                  <Button
                    variant="gray"
                    type="button"
                    disabled={isUpdateUserDealNotesLoading}
                    onClick={() => onSaveNotes(newNotes)}
                  >
                    {isUpdateUserDealNotesLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : 'Save'}
                  </Button>
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          {isOwner && (
            <div className="py-3">
              <div className="pb-3 d-flex align-items-center justify-content-between">
                <span>
                  <h4 className="bold-text dark-blue-text">
                    Potential Introductions
                  </h4>
                </span>
                <span className="align-self-right">
                  <Button
                    variant="border"
                    type="button"
                    onClick={() => setIsShareDealVisible(true)}
                  >
                    Add Potential Introductions
                  </Button>
                </span>
              </div>
              {!!shareContent.length && (
                <div className="share-section deal-contacts">
                  {shareContent}
                </div>
              )}
            </div>
          )}
        </Col>
        <Col md={7}>
          <div className="related-tasks-section">
            <RelatedTasksContainer contactId="" dealId={id} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

DealDetails.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    company: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  deal: PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    }),
    userDeal: PropTypes.shape({
      status: PropTypes.string,
    }),
    company: PropTypes.string,
    status: PropTypes.string,
    stage: PropTypes.string,
    amount: PropTypes.number,
    model: PropTypes.string,
    sector: PropTypes.string,
    closeDate: PropTypes.string,
    revenue: PropTypes.number,
    structure: PropTypes.string,
    website: PropTypes.string,
    deck: PropTypes.string,
    dropbox: PropTypes.string,
    memo: PropTypes.string,
    notes: PropTypes.string,
    ceoName: PropTypes.string,
    ceoEmail: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({}).isRequired,
  isDeleteDealLoading: PropTypes.bool.isRequired,
  isSendShareLoading: PropTypes.bool.isRequired,
  isShareDealLoading: PropTypes.bool.isRequired,
  isUpdateDealLoading: PropTypes.bool.isRequired,
  isUpdateUserDealNotesLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSaveNotes: PropTypes.func.isRequired,
  onSendShare: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateInterest: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  richNotes: PropTypes.shape({}).isRequired,
  shares: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default DealDetails;
