import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './styles.scss';

const Footer = ({ theme }) => {
  const bgColor = theme === 'dark' ? 'clay-blue-background' : 'white-background';

  return (
    <Container fluid className={`footer-container ${bgColor}`}>
      <Row className="full-height align-items-center">
        <Col>
          <div className="light-gray-text">
            © 2023 PIPELINE
          </div>
        </Col>
        <Col>
          <div className="text-right light-gray-text">
            <a
              href="https://app.termly.io/document/terms-of-use-for-saas/f1bfb67d-7921-4f39-b026-2f5ec74e59e4"
              className="light-gray-text"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
            <span className="mx-2">|</span>
            <a
              href="https://app.termly.io/document/privacy-policy/bbcf0235-ccca-4888-927c-c472b2127691"
              className="light-gray-text"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Footer.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Footer;
