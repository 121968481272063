import { combineReducers } from 'redux';
import contactReducer from './contactReducer';
import errorReducer from './errorReducer';
import dealReducer from './dealReducer';
import filterReducer from './filterReducer';
import inviteReducer from './inviteReducer';
import loadingReducer from './loadingReducer';
import shareReducer from './shareReducer';
import sortReducer from './sortReducer';
import taskReducer from './taskReducer';
import userReducer from './userReducer';
import searchReducer from './searchReducer';
import companyReducer from './companyReducer';

const rootReducer = combineReducers({
  contacts: contactReducer,
  deals: dealReducer,
  errors: errorReducer,
  filters: filterReducer,
  invite: inviteReducer,
  loading: loadingReducer,
  shares: shareReducer,
  sort: sortReducer,
  tasks: taskReducer,
  user: userReducer,
  search: searchReducer,
  companies: companyReducer,
});

export default rootReducer;
