import contactMiddleware from './contactMiddleware';
import dealMiddleware from './dealMiddleware';
import inviteMiddleware from './inviteMiddleware';
import loggingMiddleware from './loggingMiddleware';
import shareMiddleware from './shareMiddleware';
import taskMiddleware from './taskMiddleware';
import userMiddleware from './userMiddleware';
import searchMiddleware from './searchMiddleware';
import companyMiddleware from './companyMiddleware';

const middlewares = [
  loggingMiddleware,
  userMiddleware,
  contactMiddleware,
  dealMiddleware,
  inviteMiddleware,
  taskMiddleware,
  shareMiddleware,
  searchMiddleware,
  companyMiddleware,
];

export default middlewares;
