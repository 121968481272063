import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import FeedbackFormContainer from 'containers/FeedbackFormContainer';
import './styles.scss';

const Settings = (props) => {
  const {
    user: {
      alternateEmail,
      email,
      fullName,
    },
  } = props;

  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);

  return (
    <Container fluid className="settings-container">
      <div className="settings-view-container">
        <div className="pb-4">
          <h3 className="bold-text dark-blue-text">
            Settings
          </h3>
        </div>
        <div className="settings-info-container">
          <div className="pb-4">
            <div className="pb-3">
              <h4 className="bold-text dark-blue-text">
                Personal Info
              </h4>
            </div>
            <div className="pb-2 medium-text blue-gray-text">
              Name
            </div>
            <div className="pb-2 medium-text dark-blue-text">
              {fullName}
            </div>
          </div>
          <div className="pb-4">
            <div className="pb-3">
              <h4 className="bold-text dark-blue-text">
                Email
              </h4>
            </div>
            <div className="pb-2 medium-text blue-gray-text">
              Current Email
            </div>
            <div className="pb-2 medium-text dark-blue-text">
              Your current email address is
              {' '}
              <span className="bold-text">
                {email}
              </span>
            </div>
            <div className="pb-4">
              <Link
                to="/settings/email"
                className="bold-text cool-blue-text underlined"
              >
                Change Email
              </Link>
            </div>
            <div className="pb-2 medium-text blue-gray-text">
              Alternate Email
            </div>
            {alternateEmail && (
              <div className="pb-3 medium-text dark-blue-text">
                Your alternate email address is
                {' '}
                <span className="bold-text">
                  {alternateEmail}
                </span>
              </div>
            )}
            <div className="pb-2">
              <Link
                to="/settings/alternate"
                className="bold-text cool-blue-text underlined"
              >
                {alternateEmail ? 'Change Alternate Email' : 'Add Email'}
              </Link>
            </div>
          </div>
          <div className="pb-4">
            <h4 className="bold-text dark-blue-text">
              Password
            </h4>
            <div className="pb-2">
              <Link
                to="/settings/password"
                className="bold-text cool-blue-text underlined"
              >
                Change Password
              </Link>
            </div>
          </div>
          <div className="pb-2">
            <h4 className="bold-text dark-blue-text">
              Feedback
            </h4>
            <FeedbackFormContainer
              isVisible={isFeedbackVisible}
              onClose={() => setIsFeedbackVisible(false)}
            />
            <button
              type="button"
              onClick={() => setIsFeedbackVisible(true)}
              className="bold-text cool-blue-text underlined bg-white border-0 p-0"
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

Settings.propTypes = {
  user: PropTypes.shape({
    alternateEmail: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
};

export default Settings;
