import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { ReactComponent as CSVIcon } from 'assets/images/csv-icon.svg';
import CloseButton from 'components/CloseButton';
import Dropzone from 'components/Dropzone';
import Modal from 'components/Modal';
import './styles.scss';

const UploadForm = (props) => {
  const {
    error: { detail, errors: uploadErrors = [] },
    fieldOptions,
    headers,
    isLoading,
    isVisible,
    onClose,
    onSubmit,
    template,
  } = props;

  const [files, setFiles] = useState([]);
  const [rows, setRows] = useState([]);
  const [readError, setReadError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  let index;
  const error = uploadErrors.find((err, i) => {
    if (Object.values(err).length) {
      index = i;
      return true;
    } else {
      return false;
    }
  }) || [];

  const errorValues = Object.values(error);
  const errorDetail = errorValues.length ? `Line ${index + 2}: ${errorValues[0]}` : '';

  const handleClose = () => {
    setFiles([]);
    setRows([]);
    setReadError('');
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(rows);
    setSubmitted(true);
  };

  const handleChange = (newFiles) => {
    setReadError('');
    const [file] = newFiles;
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: 'greedy',
        transformHeader: (header, i) => {
          if (i >= headers.length || header !== headers[i]) {
            setReadError('Invalid headers');
          }

          return header.split(' ')[0];
        },
        complete: ({ data, errors }) => {
          setRows(data);

          if (errors.length) {
            const errorRows = [];
            const rowString = errors.reduce((acc, { row }) => {
              if (!errorRows.includes(row)) {
                errorRows.push(row);
                return (
                  acc ? `${acc}, ${row + 2}` : row + 2
                );
              } else {
                return acc;
              }
            }, '');

            setReadError(`The following rows are invalid: ${rowString}`);
          }
        },
      });
    } else {
      setRows([]);
    }

    setFiles(newFiles);
  };

  useEffect(() => {
    if (submitted && !isLoading && !detail) {
      handleClose();
    }
  }, [isLoading]);

  const dropzoneContent = files.length ? (
    <div>
      <div className="bold-text">{files[0].path}</div>
      <div className="normal-text small-text">{`${rows.length} row(s) found`}</div>
    </div>
  ) : (
    <div className="bold-text">Choose CSV file</div>
  );

  const isButtonDisabled = !rows.length || isLoading || readError;

  return (
    <Modal centered show={isVisible} onHide={handleClose}>
      <Container fluid className="filter-form-container">
        <CloseButton onClick={handleClose} />
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-3">
            <h3 className="bold-text dark-blue-text">
              Select file to upload
            </h3>
          </div>
          <div className="py-3">
            <h4 className="bold-text dark-blue-text">
              Please make sure your file is in CSV format.
            </h4>
          </div>
          <div className="pb-4">
            <Link
              to={template}
              target="_blank"
              className="bold-text cool-blue-text underlined"
            >
              Download template
            </Link>
          </div>
          <div className="pb-3">
            <div>
              <h6 className="bold-text">Field Options:</h6>
            </div>
            {Object.entries(fieldOptions).map(([key, value]) => {
              const options = value.reduce((acc, [val]) => (
                acc ? `${acc}, ${val}` : val
              ), '');

              return (
                <div key={key}>
                  <strong className="field-name">{key}</strong>
                  {`: ${options}`}
                </div>
              );
            })}
          </div>
          <div className="pt-2 pb-3">
            <Dropzone
              accept=".csv"
              files={files}
              rows={rows}
              onChange={handleChange}
            >
              {dropzoneContent}
              <div>
                <CSVIcon />
              </div>
            </Dropzone>
            {readError && (
              <div className="invalid-feedback text-right">{readError}</div>
            )}
          </div>
          <div className="pt-4 text-center">
            <span className="pr-3">
              <Button
                variant="border"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </span>
            <span className="pl-3">
              <Button
                variant="gray"
                type="submit"
                disabled={isButtonDisabled}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Upload'
                )}
              </Button>
            </span>
            {detail && (
              <div className="invalid-feedback text-right">{errorDetail}</div>
            )}
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

UploadForm.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  fieldOptions: PropTypes.shape({}).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  template: PropTypes.string.isRequired,
};

export default UploadForm;
