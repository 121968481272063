import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResendVerificationEmail from 'components/ResendVerificationEmail';
import { resendVerificationEmailAction } from 'js/actions/userActions';

const ResendVerificationEmailContainer = (props) => {
  const {
    resendVerificationEmail,
    error,
    isLoading,
  } = props;

  return (
    <ResendVerificationEmail
      resendVerificationEmail={resendVerificationEmail}
      error={error}
      isLoading={isLoading}
    />
  );
};

ResendVerificationEmailContainer.propTypes = {
  resendVerificationEmail: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { resendVerificationEmailError },
    loading: { isResendVerificationEmailLoading },
  } = state;

  return {
    error: resendVerificationEmailError || {},
    isLoading: isResendVerificationEmailLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resendVerificationEmail: () => dispatch(resendVerificationEmailAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResendVerificationEmailContainer);
