const frequencies = [
  ['Low'],
  ['Medium'],
  ['High'],
];

const contactsTableFields = [
  {
    key: 'fullName',
    name: 'Name',
    getSortValue: ({ fullName }) => fullName,
    getValue: ({ fullName }) => fullName,
  },
  {
    key: 'company',
    name: 'Company',
    getSortValue: ({ company }) => company,
    getValue: ({ company }) => company,
  },
  {
    key: 'type',
    name: 'Type',
    getSortValue: ({ type }) => type,
    getValue: ({ type }) => type,
  },
  {
    key: 'model',
    name: 'Model',
    getSortValue: ({ model }) => model,
    getValue: ({ model }) => model,
  },
  {
    key: 'stage',
    name: 'Stage',
    getSortValue: ({ stage }) => stage,
    getValue: ({ stage }) => stage,
  },
  {
    key: 'invite',
    name: 'Invite',
  },
  {
    key: 'isFlagged',
    name: 'Flag',
    getSortValue: ({ isFlagged }) => isFlagged,
    getValue: ({ isFlagged }) => isFlagged,
  },
];

const types = [
  ['Angel'],
  ['Family Office'],
  ['VC'],
  ['Private Equity'],
  ['LP'],
  ['Other'],
];

const stages = [
  ['Pre-Seed'],
  ['Early'],
  ['Late'],
  ['Growth'],
  ['Multi-Stage'],
  ['Seed'],
];

const models = [
  ['Consumer'],
  ['B2B'],
  ['Infrastructure'],
  ['Healthcare'],
  ['Generalist'],
  ['Other'],
];

const flags = [
  [true, 'Yes'],
  [false, 'No'],
];

const fieldMap = {
  firstName: 'first_name',
  lastName: 'last_name',
  checkSmall: 'check_small',
  checkBig: 'check_big',
  isFlagged: 'is_flagged',
};

const csvHeaders = [
  'firstName *',
  'lastName *',
  'email *',
  'company',
  'type',
  'stage',
  'model',
  'frequency',
  'linkedin',
];

const fieldOptions = {
  Frequencies: frequencies,
  Types: types,
  Stages: stages,
  Models: models,
};

export {
  contactsTableFields,
  csvHeaders,
  fieldMap,
  fieldOptions,
  flags,
  frequencies,
  types,
  stages,
  models,
};
