import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  receiveTaskAction,
  receiveTasksAction,
  removeTaskAction,
} from 'js/actions/taskActions';
import { getLoggedInHeaders, formatTaskPayload } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const getTasks = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetTasksLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/tasks/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getTasksError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveTasksAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetTasksLoading: false }));
};

const addTask = async (action, { dispatch, getState }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isAddTaskLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/tasks/`, {
    method: 'POST',
    body: JSON.stringify(formatTaskPayload(payload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ addTaskError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveTaskAction(responseJson));
  }

  dispatch(setLoadingAction({ isAddTaskLoading: false }));
};

const updateTask = async (action, { dispatch, getState }) => {
  const { payload: { id, ...restOfPayload } } = action;

  dispatch(setLoadingAction({ isUpdateTaskLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/tasks/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(formatTaskPayload(restOfPayload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateTaskError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveTaskAction(responseJson));
  }

  dispatch(setLoadingAction({ isUpdateTaskLoading: false }));
};

const deleteTask = async (action, { dispatch, getState }) => {
  const { payload: { id } } = action;

  dispatch(setLoadingAction({ isDeleteTaskLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/tasks/${id}/`, {
    method: 'DELETE',
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ removeTaskError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(removeTaskAction(id));
  }

  dispatch(setLoadingAction({ isDeleteTaskLoading: false }));
};

const taskMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'GET_TASKS':
      await getTasks(action, store);
      break;
    case 'ADD_TASK':
      await addTask(action, store);
      break;
    case 'UPDATE_TASK':
      await updateTask(action, store);
      break;
    case 'DELETE_TASK':
      await deleteTask(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default taskMiddleware;
