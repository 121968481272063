import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import CloseButton from 'components/CloseButton';
import Modal from 'components/Modal';
import './styles.scss';

const FeedbackForm = (props) => {
  const {
    error,
    isLoading,
    isVisible,
    onClose,
    onSubmit,
  } = props;

  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const isButtonDisabled = isLoading || !feedback;

  const {
    detail,
    errors: {
      feedback: [feedbackError] = [],
    } = {},
  } = error;

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);

      if (!detail) {
        setFeedback('');
        setIsSuccessful(true);
      }
    }
  }, [isLoading]);

  const shouldRenderFormError = detail && !feedbackError;

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { feedback };

    setIsSubmitted(true);
    onSubmit(payload);
  };

  const handleClose = () => {
    setFeedback('');
    setIsSubmitted(false);
    setIsSuccessful(false);
    onClose();
  };

  const content = isSuccessful ? (
    <div className="text-center success-container">
      <div className="pt-4">
        <h1 className="bold-text dark-blue-text">Success!</h1>
      </div>
      <div className="pt-3 pb-5 dark-blue-text">
        Thanks for your sending your feedback and helping Pipeline improve!
      </div>
    </div>
  ) : (
    <Form noValidate onSubmit={handleSubmit}>
      <div className="pb-4">
        <h3 className="bold-text dark-blue-text">
          Send Feedback
        </h3>
      </div>
      <Form.Group controlId="feedback">
        <Form.Label
          className="small-text bold-text transparent-blue-gray-text"
        >
          Feedback
        </Form.Label>
        <Form.Control
          as="textarea"
          isInvalid={!!feedbackError}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        {feedbackError && (
          <Form.Control.Feedback type="invalid">
            {feedbackError}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <div className="pt-3 text-center">
        <span className="pr-3">
          <Button
            variant="border"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </span>
        <span className="pl-3">
          <Button
            variant="gray"
            type="submit"
            disabled={isButtonDisabled}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Submit'
            )}
          </Button>
        </span>
        {shouldRenderFormError && (
          <div className="invalid-feedback text-right">{detail}</div>
        )}
      </div>
    </Form>
  );

  return (
    <Modal centered show={isVisible} onHide={handleClose}>
      <Container fluid className="feedback-form-container">
        <CloseButton onClick={handleClose} />
        {content}
      </Container>
    </Modal>
  );
};

FeedbackForm.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      feedback: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FeedbackForm;
