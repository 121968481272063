import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContactForm from 'components/ContactForm';
import { updateContactAction } from 'js/actions/contactActions';

const UpdateContactContainer = (props) => {
  const {
    contact,
    error,
    isLoading,
    isVisible,
    onClose,
    updateContact,
  } = props;

  const { id } = contact;

  const handleUpdateContact = (payload) => {
    updateContact({ id, loadingKey: 'isUpdateContactLoading', ...payload });
  };

  return (
    <ContactForm
      contact={contact}
      error={error}
      header="Update contact"
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSubmit={handleUpdateContact}
      submitText="Update Contact"
    />
  );
};

UpdateContactContainer.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { updateContactError },
    loading: { isUpdateContactLoading },
  } = state;

  return {
    error: updateContactError || {},
    isLoading: isUpdateContactLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateContact: (payload) => dispatch(updateContactAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateContactContainer);
