import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseButtonSvg } from 'assets/images/close-icon.svg';
import './styles.scss';

const CloseButton = ({ onClick }) => (
  <button type="button" className="close-button" onClick={onClick}>
    <CloseButtonSvg />
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
