const receiveUserAction = (payload) => ({ type: 'RECEIVE_USER', payload });

const clearUserAction = () => ({ type: 'CLEAR_USER' });

const waitlistAction = (payload) => ({ type: 'WAITLIST', payload });

const signUpAction = (payload) => ({ type: 'SIGN_UP', payload });

const logInAction = (payload) => ({ type: 'LOG_IN', payload });

const logOutAction = () => ({ type: 'LOG_OUT' });

const getUserAction = (payload) => ({ type: 'GET_USER', payload });

const forgotPasswordAction = (payload) => ({ type: 'FORGOT_PASSWORD', payload });

const resetPasswordAction = (payload) => ({ type: 'RESET_PASSWORD', payload });

const changeEmailAction = (payload) => ({ type: 'CHANGE_EMAIL', payload });

const changePasswordAction = (payload) => ({ type: 'CHANGE_PASSWORD', payload });

const updateUserAction = (payload) => ({ type: 'UPDATE_USER', payload });

const sendFeedbackAction = (payload) => ({ type: 'SEND_FEEDBACK', payload });

const resendVerificationEmailAction = () => ({ type: 'RESEND_VERIFICATION_EMAIL' });

export {
  changeEmailAction,
  changePasswordAction,
  clearUserAction,
  forgotPasswordAction,
  getUserAction,
  logInAction,
  logOutAction,
  receiveUserAction,
  resetPasswordAction,
  sendFeedbackAction,
  signUpAction,
  waitlistAction,
  updateUserAction,
  resendVerificationEmailAction,
};
