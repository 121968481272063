const sendInviteAction = (payload) => ({ type: 'SEND_INVITE', payload });

const getInviteAction = (payload) => ({ type: 'GET_INVITE', payload });

const clearInviteAction = () => ({ type: 'CLEAR_INVITE' });

const receiveInviteAction = (payload) => ({ type: 'RECEIVE_INVITE', payload });

const acceptInviteAction = (payload) => ({ type: 'ACCEPT_INVITE', payload });

export {
  acceptInviteAction,
  clearInviteAction,
  getInviteAction,
  receiveInviteAction,
  sendInviteAction,
};
