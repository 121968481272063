import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import './style.scss';

const Dropzone = ({ accept, children, onChange }) => {
  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
  });

  let className = 'dropzone';
  if (isDragActive) {
    className += ' dragging';
  }

  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      <div className="p-2 dark-blue-text dropzone-content">
        {children}
      </div>
    </div>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Dropzone;
