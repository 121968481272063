const getCompaniesAction = () => ({ type: 'GET_COMPANIES' });

const searchCompaniesAction = (payload) => ({
  type: 'SEARCH_COMPANIES',
  payload,
});

const receiveCompaniesSearchResultsAction = (payload) => ({
  type: 'RECEIVE_COMPANIES_SEARCH_RESULTS',
  payload,
});

const receiveSearchResultCompanyDetailsAction = (payload) => ({
  type: 'RECEIVE_SEARCH_RESULT_COMPANY_DETAILS',
  payload,
});

const clearCompaniesSearchResultsAction = () => ({
  type: 'CLEAR_COMPANIES_SEARCH_RESULTS',
});

const clearSearchResultCompanyDetailsAction = () => ({
  type: 'CLEAR_SEARCH_RESULT_COMPANY_DETAILS',
});

const getCompanyAction = (payload) => ({
  type: 'GET_COMPANY',
  payload,
});

const receiveCompanyAction = (payload) => ({
  type: 'RECEIVE_COMPANY',
  payload,
});

export {
  getCompaniesAction,
  searchCompaniesAction,
  receiveCompaniesSearchResultsAction,
  receiveSearchResultCompanyDetailsAction,
  clearCompaniesSearchResultsAction,
  clearSearchResultCompanyDetailsAction,
  getCompanyAction,
  receiveCompanyAction,
};
