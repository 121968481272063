import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tasks from 'components/Tasks';
import Loading from 'components/Loading';
import { setSortAction } from 'js/actions/sortActions';
import {
  deleteTaskAction,
  receiveTaskAction,
  updateTaskAction,
} from 'js/actions/taskActions';

const TasksContainer = (props) => {
  const {
    contacts,
    deals,
    tasks,
    isLoading,
    deleteTask,
    receiveTask,
    updateTask,
    setSort,
    sort,
  } = props;

  const handleDelete = (id) => deleteTask({ id });

  const handleSetSort = (payload) => setSort({ taskSort: payload });

  const handleUpdate = ({ id, ...payload }) => {
    const task = tasks.find(({ id: taskId }) => taskId === id);
    updateTask({ id, ...payload });

    receiveTask({ ...task, ...payload });
  };

  return isLoading && !tasks.length ? <Loading theme="light" /> : (
    <Tasks
      tasks={tasks}
      contacts={contacts}
      deals={deals}
      onDelete={handleDelete}
      onSetSort={handleSetSort}
      onUpdate={handleUpdate}
      sort={sort}
    />
  );
};

TasksContainer.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sort: PropTypes.shape({}).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  deleteTask: PropTypes.func.isRequired,
  receiveTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    contacts: { contacts },
    deals: { deals },
    tasks: { tasks },
    loading: { isGetContactsLoading, isGetDealsLoading, isGetTasksLoading },
    sort: { taskSort: sort },
  } = state;

  return {
    contacts,
    deals,
    tasks,
    isLoading: isGetContactsLoading || isGetDealsLoading || isGetTasksLoading,
    sort,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteTask: (payload) => dispatch(deleteTaskAction(payload)),
  receiveTask: (payload) => dispatch(receiveTaskAction(payload)),
  updateTask: (payload) => dispatch(updateTaskAction(payload)),
  setSort: (payload) => dispatch(setSortAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
