import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Dropzone from 'components/Dropzone';
import FormSelect from 'components/FormSelect';
import {
  getStatusValues,
  models,
  sectors,
  stages,
  structures,
} from 'js/constants/deals';
import { formatExternalLink } from 'js/utils';
import './styles.scss';

const DealForm = (props) => {
  const {
    deal: {
      userDeal: { referral: existingReferral, status: existingStatus } = {},
      company: existingCompany,
      stage: existingStage,
      preMoney: existingPreMoney,
      amount: existingAmount,
      model: existingModel,
      sector: existingSector,
      closeDate: existingCloseDate,
      target: existingTarget,
      revenue: existingRevenue,
      structure: existingStructure,
      website: existingWebsite,
      deck: existingDeck,
      ceoName: existingCeoName,
      ceoEmail: existingCeoEmail,
    } = {},
    error,
    header,
    submitText,
    isLoading,
    onSubmit,
    isGetCompanyLoading,
    clearCompaniesSearchResults,
    clearSearchResultCompanyDetail,
  } = props;

  const history = useHistory();

  const VIEWS = [
    {
      name: 'BASIC',
      displayName: 'Basic Details',
    },
    {
      name: 'FOUNDER',
      displayName: 'Founder Details',
    },
    {
      name: 'DETAILS',
      displayName: 'Other Details',
    },
    {
      name: 'FINANCIALS',
      displayName: 'Company Financials',
    },
  ];

  const [companyName, setCompanyName] = useState(existingCompany || '');
  const [referral, setReferral] = useState(existingReferral || '');
  const [status, setStatus] = useState(existingStatus || '');
  const [stage, setStage] = useState(existingStage || '');
  const [preMoney, setPreMoney] = useState(existingPreMoney / 1000 || '');
  const [amount, setAmount] = useState(existingAmount / 1000 || '');
  const [model, setModel] = useState(existingModel || '');
  const [sector, setSector] = useState(existingSector || '');
  const [closeDate, setCloseDate] = useState(existingCloseDate || '');
  const [target, setTarget] = useState(existingTarget / 1000 || '');
  const [revenue, setRevenue] = useState(existingRevenue / 1000 || '');
  const [structure, setStructure] = useState(existingStructure || '');
  const [website, setWebsite] = useState(existingWebsite || '');
  const [deck, setDeck] = useState(existingDeck || '');
  const [ceoName, setCeoName] = useState(existingCeoName || '');
  const [ceoEmail, setCeoEmail] = useState(existingCeoEmail || '');
  const [files, setFiles] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [currentView, setCurrentView] = useState(VIEWS[0]);

  const handleBack = () => {
    const currentIndex = VIEWS.findIndex(
      (view) => view.name === currentView.name,
    );

    if (currentIndex === -1) return;

    if (currentIndex === 0) {
      history.goBack();
    }

    setCurrentView(VIEWS[currentIndex - 1]);
  };

  const handleNext = (e) => {
    e.preventDefault();

    const currentIndex = VIEWS.findIndex(
      (view) => view.name === currentView.name,
    );

    if (currentIndex === -1 || currentIndex === VIEWS.length - 1) {
      return;
    }

    setCurrentView(VIEWS[currentIndex + 1]);
  };

  const isButtonDisabled = isLoading || !(companyName && status && stage);

  const {
    detail,
    errors: {
      company: [companyError] = [],
      referral: [referralError] = [],
      status: [statusError] = [],
      stage: [stageError] = [],
      pre_money: [preMoneyError] = [],
      amount: [amountError] = [],
      model: [modelError] = [],
      sector: [sectorError] = [],
      close_date: [closeDateError] = [],
      target: [targetError] = [],
      revenue: [revenueError] = [],
      structure: [structureError] = [],
      website: [websiteError] = [],
      deck: [deckError] = [],
      ceoName: [ceoNameError] = [],
      ceoEmail: [ceoEmailError] = [],
    } = {},
  } = error;

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);
    }
  }, [isLoading]);

  const shouldRenderFormError = detail
    && !(
      companyError
      && referralError
      && statusError
      && stageError
      && preMoneyError
      && amountError
      && modelError
      && sectorError
      && closeDateError
      && targetError
      && revenueError
      && structureError
      && websiteError
      && deckError
      && ceoNameError
      && ceoEmailError
    );

  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearCompaniesSearchResults();
    clearSearchResultCompanyDetail();

    const payload = {
      company: companyName,
      referral,
      stage,
      preMoney: preMoney * 1000,
      amount: amount * 1000,
      model,
      sector,
      target: target * 1000,
      revenue: revenue * 1000,
      structure,
      website: formatExternalLink(website),
      deck: formatExternalLink(deck),
      ceoName,
      ceoEmail,
    };

    if (!existingStatus) {
      payload.status = status;
    }

    if (closeDate) {
      payload.closeDate = closeDate;
    }

    if (files.length) {
      payload.files = files;
    }

    setIsSubmitted(true);
    onSubmit(payload);
    history.goBack();
  };

  const statuses = getStatusValues();

  const renderFormFields = () => {
    // If no view is selected, render the default view.
    if (!currentView) {
      setCurrentView(VIEWS[0]);
      return null;
    }

    if (currentView.name === 'BASIC') {
      return (
        <>
          <Form.Group controlId="company">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Company
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter company name"
              isInvalid={!!companyError}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            {(companyError) && (
              <Form.Control.Feedback type="invalid">
                {companyError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="stage">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Stage
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={stages}
              isInvalid={!!stageError}
              size="medium"
              value={stage}
              onChange={setStage}
            />
            {stageError && (
              <Form.Control.Feedback type="invalid">
                {stageError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {!existingStatus && (
            <Form.Group controlId="status">
              <Form.Label className="small-text bold-text transparent-blue-gray-text">
                Status
              </Form.Label>
              <FormSelect
                placeholder="Select option"
                options={statuses}
                isInvalid={!!statusError}
                size="medium"
                value={status}
                onChange={setStatus}
              />
              {statusError && (
                <Form.Control.Feedback type="invalid">
                  {statusError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}
          <Form.Group controlId="referral">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Referral (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!referralError}
              value={referral}
              onChange={(e) => setReferral(e.target.value)}
            />
            {referralError && (
              <Form.Control.Feedback type="invalid">
                {referralError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </>
      );
    }

    if (currentView.name === 'FINANCIALS') {
      return (
        <>
          <Form.Group controlId="revenue">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Annualized net revenue (in thousands, optional)
            </Form.Label>
            <Form.Control
              type="number"
              isInvalid={!!revenueError}
              value={revenue}
              onChange={(e) => setRevenue(e.target.value)}
            />
            {revenueError && (
            <Form.Control.Feedback type="invalid">
              {revenueError}
            </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="preMoney">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Pre-Money (in thousands, optional)
            </Form.Label>
            <Form.Control
              type="number"
              isInvalid={!!preMoneyError}
              value={preMoney}
              onChange={(e) => setPreMoney(e.target.value)}
            />
            {preMoneyError && (
              <Form.Control.Feedback type="invalid">
                {preMoneyError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="target">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Target raise (in thousands, optional)
            </Form.Label>
            <Form.Control
              type="number"
              isInvalid={!!targetError}
              value={target}
              onChange={(e) => setTarget(e.target.value)}
            />
            {targetError && (
              <Form.Control.Feedback type="invalid">
                {targetError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              My investment amount (in thousands, optional)
            </Form.Label>
            <Form.Control
              type="number"
              isInvalid={!!amountError}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            {amountError && (
              <Form.Control.Feedback type="invalid">
                {amountError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </>
      );
    }

    if (currentView.name === 'DETAILS') {
      return (
        <>
          <Form.Group controlId="model">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Business model (optional)
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={models}
              isInvalid={!!modelError}
              size="medium"
              value={model}
              onChange={setModel}
            />
            {modelError && (
              <Form.Control.Feedback type="invalid">
                {modelError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="sector">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Sector (optional)
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={sectors}
              isInvalid={!!sectorError}
              size="medium"
              value={sector}
              onChange={setSector}
            />
            {sectorError && (
              <Form.Control.Feedback type="invalid">
                {sectorError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="structure">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Deal structure (optional)
            </Form.Label>
            <FormSelect
              placeholder="Select option"
              options={structures}
              isInvalid={!!structureError}
              size="medium"
              value={structure}
              onChange={setStructure}
            />
            {structureError && (
              <Form.Control.Feedback type="invalid">
                {structureError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="close-date">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Expected Close Date (optional)
            </Form.Label>
            <Form.Control
              type="date"
              isInvalid={!!closeDateError}
              value={closeDate}
              onChange={(e) => setCloseDate(e.target.value)}
            />
            {closeDateError && (
              <Form.Control.Feedback type="invalid">
                {closeDateError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="website">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Website URL (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!websiteError}
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
            {websiteError && (
              <Form.Control.Feedback type="invalid">
                {websiteError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </>
      );
    }

    if (currentView.name === 'FOUNDER') {
      return (
        <>
          <Form.Group controlId="ceoName">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              CEO Name (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!ceoNameError}
              value={ceoName}
              onChange={(e) => setCeoName(e.target.value)}
            />
            {ceoNameError && (
              <Form.Control.Feedback type="invalid">
                {ceoNameError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="ceoEmail">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              CEO Email (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!ceoEmailError}
              value={ceoEmail}
              onChange={(e) => setCeoEmail(e.target.value)}
            />
            {ceoEmailError && (
              <Form.Control.Feedback type="invalid">
                {ceoEmailError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="deck">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Deck URL (optional)
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!deckError}
              value={deck}
              onChange={(e) => setDeck(e.target.value)}
            />
            {deckError && (
              <Form.Control.Feedback type="invalid">
                {deckError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="pb-3">
            <Form.Label className="small-text bold-text transparent-blue-gray-text">
              Documents (optional)
            </Form.Label>
            <Dropzone accept="" onChange={handleFileChange}>
              <div className="bold-text">
                {files.length
                  ? files.reduce(
                    (acc, file) => (acc ? `${acc}, ${file.name}` : file.name),
                    '',
                  )
                  : 'Click or drag here to upload supporting documents'}
              </div>
            </Dropzone>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Container fluid className="add-deal-container">
      <Form noValidate onSubmit={handleSubmit}>
        <div className="pb-4">
          <h3 className="bold-text dark-blue-text">{header}</h3>
        </div>
        <div className="pb-2">
          <h4 className="bold-text dark-blue-text">
            {currentView && currentView.displayName}
          </h4>
        </div>
        {renderFormFields()}
        <div className="pt-3 text-center">
          <span className="pr-3">
            <Button variant="border" type="button" onClick={() => handleBack()}>
              {currentView && currentView.name !== VIEWS[0].name
                ? 'Back'
                : 'Cancel'}
            </Button>
          </span>
          <span className="pl-3">
            {currentView
            && currentView.name !== VIEWS[VIEWS.length - 1].name ? (
              <Button variant="gray" type="button" onClick={handleNext} disabled={isGetCompanyLoading}>
                {isGetCompanyLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Next'
                )}
              </Button>
              ) : (
                <Button variant="gray" type="submit" disabled={isButtonDisabled}>
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    submitText
                  )}
                </Button>
              )}
          </span>
          {shouldRenderFormError && (
            <div className="invalid-feedback text-right">{detail}</div>
          )}
        </div>
      </Form>
    </Container>
  );
};

DealForm.propTypes = {
  deal: PropTypes.shape({
    userDeal: PropTypes.shape({
      referral: PropTypes.string,
      status: PropTypes.string,
    }),
    company: PropTypes.string,
    stage: PropTypes.string,
    preMoney: PropTypes.number,
    amount: PropTypes.number,
    model: PropTypes.string,
    sector: PropTypes.string,
    closeDate: PropTypes.string,
    target: PropTypes.number,
    revenue: PropTypes.number,
    structure: PropTypes.string,
    website: PropTypes.string,
    deck: PropTypes.string,
    ceoName: PropTypes.string,
    ceoEmail: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      company: PropTypes.arrayOf(PropTypes.string),
      referral: PropTypes.arrayOf(PropTypes.string),
      status: PropTypes.arrayOf(PropTypes.string),
      stage: PropTypes.arrayOf(PropTypes.string),
      pre_money: PropTypes.arrayOf(PropTypes.string),
      post_money: PropTypes.arrayOf(PropTypes.string),
      amount: PropTypes.arrayOf(PropTypes.string),
      model: PropTypes.arrayOf(PropTypes.string),
      sector: PropTypes.arrayOf(PropTypes.string),
      close_date: PropTypes.arrayOf(PropTypes.string),
      target: PropTypes.arrayOf(PropTypes.string),
      revenue: PropTypes.arrayOf(PropTypes.string),
      structure: PropTypes.arrayOf(PropTypes.string),
      website: PropTypes.arrayOf(PropTypes.string),
      deck: PropTypes.arrayOf(PropTypes.string),
      ceoName: PropTypes.arrayOf(PropTypes.string),
      ceoEmail: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  header: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchResultCompanyDetail: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    homepageUrl: PropTypes.string,
    foundedOn: PropTypes.string,
    categoryList: PropTypes.string,
    categoryGroupsList: PropTypes.string,
    legalName: PropTypes.string,
    location: PropTypes.string,
    shortDescription: PropTypes.string,
    companyType: PropTypes.string,
    exitedOn: PropTypes.string,
    facebook: PropTypes.string,
    operatingStatus: PropTypes.string,
    aliases: PropTypes.string,
    linkedin: PropTypes.string,
    twitter: PropTypes.string,
    lastLayoffDate: PropTypes.string,
    equityFundingTotal: PropTypes.number,
    fundingTotal: PropTypes.number,
    investors: PropTypes.string,
    lastFundingAt: PropTypes.string,
    lastFundingTotal: PropTypes.number,
    lastFundingType: PropTypes.string,
    valuation: PropTypes.number,
    valuationDate: PropTypes.string,
  }).isRequired,
  isGetCompanyLoading: PropTypes.bool.isRequired,
  clearCompaniesSearchResults: PropTypes.func.isRequired,
  clearSearchResultCompanyDetail: PropTypes.func.isRequired,
};

export default DealForm;
