import { createPersistedReducer, formatDeal } from './utils';

const receiveDeals = (dealState, { payload }) => ({
  deals: payload.map(formatDeal),
});

const clearDeals = () => ({ deals: [] });

const receiveDeal = (dealState, { payload }) => {
  const { deals: prevDeals } = dealState;
  const deals = prevDeals.filter((deal) => (
    deal.id !== payload.id
  ));

  return { deals: [...deals, formatDeal(payload)] };
};

const removeDeal = (dealState, { payload: id }) => {
  const { deals: prevDeals } = dealState;
  const deals = prevDeals.filter((deal) => deal.id !== id);

  return { deals: [...deals] };
};

const dealReducer = createPersistedReducer({ deals: [] }, {
  RECEIVE_DEALS: receiveDeals,
  CLEAR_DEALS: clearDeals,
  RECEIVE_DEAL: receiveDeal,
  REMOVE_DEAL: removeDeal,
}, 'deals');

export default dealReducer;
