import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ReactComponent as SuccessIcon } from 'assets/images/success-icon.svg';
import PasswordInput from 'components/PasswordInput';
import './styles.scss';

const SignUp = (props) => {
  const {
    error,
    invite: { email: inviteEmail },
    isLoading,
    isWaitlist,
    onSignUp,
    title,
  } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(inviteEmail || '');
  const [password, setPassword] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ type: '', message: '' });

  const {
    detail,
    errors: {
      first_name: [firstNameError] = [],
      last_name: [lastNameError] = [],
      email: [emailError] = [],
      password: [passwordError] = [],
    } = {},
  } = error;

  const shouldRenderFormError = detail && !(
    firstNameError
    || lastNameError
    || emailError
    || passwordError
  );

  const isButtonDisabled = (isLoading || shouldRenderFormError || errorMessage.message);

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);

      if (!detail && isWaitlist) {
        setFirstName('');
        setLastName('');
        setEmail('');
        setIsSuccessful(true);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    setErrorMessage({ type: '', message: '' });
  }, [password, isTermsChecked]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      firstName,
      lastName,
      password,
    };

    if (!inviteEmail) {
      payload.email = email;
    }

    // Perform password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x21-\x7E]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setErrorMessage(
        {
          type: 'password',
          message: 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
        },
      );
      return;
    }

    // Perform terms & condition validation
    if (!isTermsChecked) {
      setErrorMessage({ type: 'terms', message: 'Please agree to the Terms & Conditions and Privacy Policy.' });
      return;
    }

    setIsSubmitted(true);
    onSignUp(payload);
  };

  const content = isSuccessful ? (
    <div className="text-center align-self-center success-container">
      <div className="success-icon-container">
        <SuccessIcon />
      </div>
      <div className="pt-4">
        <h1 className="bold-text white-text">Success!</h1>
      </div>
      <div className="pt-3 pb-5 white-text">
        Congratulations, you have successfully signed up for the waitlist!
        We'll be in touch soon when we're ready for more users.
      </div>
    </div>
  ) : (
    <Form noValidate onSubmit={handleSubmit} className="sign-up-form">
      <div className="pb-2">
        <h2 className="white-text bold-text">{title}</h2>
      </div>
      <div className="pb-4">
        <hr className="m-0 hr-short" />
      </div>
      <Form.Group controlId="first-name" className="pt-1">
        <Form.Label className="small-text bold-text transparent-white-text">
          FIRST NAME
        </Form.Label>
        <Form.Control
          type="text"
          isInvalid={!!firstNameError}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="form-control-white"
        />
        {firstNameError && (
          <Form.Control.Feedback type="invalid">
            {firstNameError}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group controlId="last-name" className="pt-1">
        <Form.Label className="small-text bold-text transparent-white-text">
          LAST NAME
        </Form.Label>
        <Form.Control
          type="text"
          isInvalid={!!lastNameError}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="form-control-white"
        />
        {lastNameError && (
          <Form.Control.Feedback type="invalid">
            {lastNameError}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group controlId="email" className="pt-1">
        <Form.Label className="small-text bold-text transparent-white-text">
          EMAIL
        </Form.Label>
        <Form.Control
          disabled={!!inviteEmail}
          type="email"
          isInvalid={!!emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control-white"
        />
        {emailError && (
          <Form.Control.Feedback type="invalid">
            {emailError}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {!isWaitlist && (
        <>
          <div>
            <Form.Group controlId="password" className="pt-1">
              <Form.Label className="small-text bold-text transparent-white-text">
                PASSWORD
              </Form.Label>
              <PasswordInput
                theme="dark"
                isInvalid={!!passwordError}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control-white"
              />
              {passwordError && (
              <Form.Control.Feedback type="invalid">
                {passwordError}
              </Form.Control.Feedback>
              )}
            </Form.Group>
            {errorMessage.type === 'password' && errorMessage.message && (
              <div className="invalid-feedback">{errorMessage.message}</div>
            )}
          </div>
          <div>
            <Form.Group controlId="terms" className="pt-3">
              <Form.Check type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  checked={isTermsChecked}
                  onChange={() => setIsTermsChecked(!isTermsChecked)}
                  className="form-check-white"
                />
                <Form.Check.Label className="terms">
                  <span className="transparent-white-text">I agree to the </span>
                  <a
                    href="https://app.termly.io/document/terms-of-use-for-saas/f1bfb67d-7921-4f39-b026-2f5ec74e59e4"
                    className="underlined white-text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  <span className="transparent-white-text"> and </span>
                  <a
                    href="https://app.termly.io/document/privacy-policy/bbcf0235-ccca-4888-927c-c472b2127691"
                    className="underlined white-text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            {errorMessage.type === 'terms' && errorMessage.message && (
            <div className="invalid-feedback">{errorMessage.message}</div>
            )}
          </div>

        </>
      )}
      <div className="pt-2 text-right">
        <Button
          size="sm"
          variant="white"
          type="submit"
          disabled={isButtonDisabled}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Sign Up'
          )}
        </Button>
        {shouldRenderFormError && (
          <div className="invalid-feedback text-right">{detail}</div>
        )}
      </div>
    </Form>
  );

  return (
    <Container
      fluid
      className="sign-up-container d-flex justify-content-center"
    >
      {content}
    </Container>
  );
};

SignUp.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      first_name: PropTypes.arrayOf(PropTypes.string),
      last_name: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  invite: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isWaitlist: PropTypes.bool.isRequired,
  onSignUp: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default SignUp;
