import { createReducer } from './utils';

const initialState = {
  isSignUpLoading: false,
  isLogInLoading: false,
  isGetUserLoading: false,
  isForgotPasswordLoading: false,
  isResetPasswordLoading: false,
  isChangeEmailLoading: false,
  isChangePasswordLoading: false,
  isUpdateUserLoading: false,
  isSendFeedbackLoading: false,
  isGetContactsLoading: false,
  isAddContactLoading: false,
  isUploadContactsLoading: false,
  isUpdateContactLoading: false,
  isUpdateContactNotesLoading: false,
  isDeleteContactLoading: false,
  isShareWithContactLoading: false,
  isGetDealsLoading: false,
  isAddDealLoading: false,
  isUploadDealsLoading: false,
  isUpdateDealLoading: false,
  isShareDealLoading: false,
  isUpdateUserDealNotesLoading: false,
  isDeleteDealLoading: false,
  isSendInviteLoading: false,
  isGetInviteLoading: false,
  isAcceptInviteLoading: false,
  isGetSharesLoading: false,
  isSendShareLoading: false,
  isUpdateShareLoading: false,
  isRemoveShareLoading: false,
  isGetTasksLoading: false,
  isAddTaskLoading: false,
  isUploadTasksLoading: false,
  isUpdateTaskLoading: false,
  isDeleteTaskLoading: false,
  isSearchDealsAndContactsLoading: false,
  isGetCompaniesLoading: false,
  isSearchCompaniesLoading: false,
  isGetCompanyLoading: false,
};

const resetLoadingState = {
  isSignUpLoading: false,
  isLogInLoading: false,
  isGetUserLoading: false,
  isForgotPasswordLoading: false,
  isResetPasswordLoading: false,
  isChangeEmailLoading: false,
  isChangePasswordLoading: false,
  isUpdateUserLoading: false,
  isSendFeedbackLoading: false,
  isGetContactsLoading: false,
  isAddContactLoading: false,
  isUploadContactsLoading: false,
  isUpdateContactLoading: false,
  isUpdateContactNotesLoading: false,
  isDeleteContactLoading: false,
  isShareWithContactLoading: false,
  isGetDealsLoading: false,
  isAddDealLoading: false,
  isUploadDealsLoading: false,
  isUpdateDealLoading: false,
  isShareDealLoading: false,
  isUpdateUserDealNotesLoading: false,
  isDeleteDealLoading: false,
  isSendInviteLoading: false,
  isGetInviteLoading: false,
  isAcceptInviteLoading: false,
  isGetSharesLoading: false,
  isSendShareLoading: false,
  isUpdateShareLoading: false,
  isRemoveShareLoading: false,
  isGetTasksLoading: false,
  isAddTaskLoading: false,
  isUploadTasksLoading: false,
  isUpdateTaskLoading: false,
  isDeleteTaskLoading: false,
  isSearchDealsAndContactsLoading: false,
};

const resetLoading = (loadingState) => ({
  ...loadingState,
  ...resetLoadingState,
});

const setLoading = (loadingState, { payload }) => ({
  ...loadingState,
  ...payload,
});

const loadingReducer = createReducer(initialState, {
  RESET_LOADING: resetLoading,
  SET_LOADING: setLoading,
});

export default loadingReducer;
