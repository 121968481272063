import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Settings from 'components/Settings';

const SettingsContainer = (props) => {
  const { user } = props;

  return <Settings user={user} />;
};

SettingsContainer.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps)(SettingsContainer);
