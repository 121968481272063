const getContactsAction = () => ({ type: 'GET_CONTACTS' });

const clearContactsAction = () => ({ type: 'CLEAR_CONTACTS' });

const receiveContactsAction = (payload) => ({
  type: 'RECEIVE_CONTACTS',
  payload,
});

const addContactAction = (payload) => ({ type: 'ADD_CONTACT', payload });

const updateContactAction = (payload) => ({ type: 'UPDATE_CONTACT', payload });

const deleteContactAction = (payload) => ({ type: 'DELETE_CONTACT', payload });

const receiveContactAction = (payload) => ({
  type: 'RECEIVE_CONTACT',
  payload,
});

const removeContactAction = (payload) => ({ type: 'REMOVE_CONTACT', payload });

const shareWithContactAction = (payload) => ({
  type: 'SHARE_WITH_CONTACT',
  payload,
});

const uploadContactsAction = (payload) => ({ type: 'UPLOAD_CONTACTS', payload });

export {
  addContactAction,
  clearContactsAction,
  deleteContactAction,
  getContactsAction,
  receiveContactAction,
  receiveContactsAction,
  removeContactAction,
  shareWithContactAction,
  updateContactAction,
  uploadContactsAction,
};
