import { clearContactsAction } from 'js/actions/contactActions';
import { clearDealsAction } from 'js/actions/dealActions';
import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import { clearSharesAction } from 'js/actions/shareActions';
import {
  logOutAction,
  receiveUserAction,
  clearUserAction,
} from 'js/actions/userActions';
import { clearCompaniesSearchResultsAction, clearSearchResultCompanyDetailsAction } from 'js/actions/companyActions';
import { formatUserPayload, getLoggedInHeaders, publicHeaders } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const waitlist = async (action, { dispatch }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isSignUpLoading: true }));

  const response = await fetch(`${API_URL}/accounts/waitlist/`, {
    method: 'POST',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: publicHeaders,
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ signUpError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
  }

  dispatch(setLoadingAction({ isSignUpLoading: false }));
};

const signUp = async (action, { dispatch }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isSignUpLoading: true }));

  const response = await fetch(`${API_URL}/accounts/`, {
    method: 'POST',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ signUpError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token }));
  }

  dispatch(setLoadingAction({ isSignUpLoading: false }));
};

const logIn = async (action, { dispatch }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isLogInLoading: true }));

  const response = await fetch(`${API_URL}/accounts/login/`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ logInError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token }));
  }

  dispatch(setLoadingAction({ isLogInLoading: false }));
};

const logOut = (action, { dispatch }) => {
  dispatch(clearUserAction());
  dispatch(clearContactsAction());
  dispatch(clearDealsAction());
  dispatch(clearSharesAction());
  dispatch(clearSearchResultCompanyDetailsAction());
  dispatch(clearCompaniesSearchResultsAction());
};

const getUser = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetUserLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/accounts/retrieve/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getUserError: responseJson }));
    dispatch(logOutAction());
  } else {
    const { user, token: newToken } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token: newToken }));
  }

  dispatch(setLoadingAction({ isGetUserLoading: false }));
};

const forgotPassword = async (action, { dispatch }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isForgotPasswordLoading: true }));

  const response = await fetch(`${API_URL}/accounts/password/forgot/`, {
    method: 'POST',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: publicHeaders,
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ forgotPasswordError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
  }

  dispatch(setLoadingAction({ isForgotPasswordLoading: false }));
};

const resetPassword = async (action, { dispatch }) => {
  const { payload: { token: verificationToken, ...restOfPayload } } = action;

  dispatch(setLoadingAction({ isResetPasswordLoading: true }));

  const response = await fetch(`${API_URL}/accounts/password/reset/`, {
    method: 'POST',
    body: JSON.stringify({
      ...restOfPayload,
      verification_token: verificationToken,
    }),
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ resetPasswordError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token }));
  }

  dispatch(setLoadingAction({ isResetPasswordLoading: false }));
};

const changeEmail = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isChangeEmailLoading: true }));

  const { payload } = action;
  const { user: { token: existingToken } } = getState();

  const response = await fetch(`${API_URL}/accounts/email/change/`, {
    method: 'PATCH',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: getLoggedInHeaders(existingToken),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ changeEmailError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token }));
  }

  dispatch(setLoadingAction({ isChangeEmailLoading: false }));
};

const changePassword = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isChangePasswordLoading: true }));

  const { payload } = action;
  const { user: { token: existingToken } } = getState();

  const response = await fetch(`${API_URL}/accounts/password/change/`, {
    method: 'PATCH',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: getLoggedInHeaders(existingToken),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ changePasswordError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token }));
  }

  dispatch(setLoadingAction({ isChangePasswordLoading: false }));
};

const updateUser = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isUpdateUserLoading: true }));

  const { payload } = action;
  const { user: { token: existingToken } } = getState();

  const response = await fetch(`${API_URL}/accounts/update/`, {
    method: 'PATCH',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: getLoggedInHeaders(existingToken),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateUserError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token }));
  }

  dispatch(setLoadingAction({ isUpdateUserLoading: false }));
};

const sendFeedback = async (action, { dispatch, getState }) => {
  const { payload } = action;

  dispatch(setLoadingAction({ isSendFeedbackLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/accounts/feedback/`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ sendFeedbackError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
  }

  dispatch(setLoadingAction({ isSendFeedbackLoading: false }));
};

const resendVerificationEmail = async ({ dispatch, getState }) => {
  dispatch(setLoadingAction({ isResendVerificationEmailLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/accounts/verify/resend/`, {
    method: 'POST',
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ resendVerificationEmailError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
  }

  dispatch(setLoadingAction({ isResendVerificationEmailLoading: false }));
};

const userMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'WAITLIST':
      await waitlist(action, store);
      break;
    case 'SIGN_UP':
      await signUp(action, store);
      break;
    case 'LOG_IN':
      await logIn(action, store);
      break;
    case 'LOG_OUT':
      await logOut(action, store);
      break;
    case 'GET_USER':
      await getUser(action, store);
      break;
    case 'FORGOT_PASSWORD':
      await forgotPassword(action, store);
      break;
    case 'RESET_PASSWORD':
      await resetPassword(action, store);
      break;
    case 'CHANGE_EMAIL':
      await changeEmail(action, store);
      break;
    case 'CHANGE_PASSWORD':
      await changePassword(action, store);
      break;
    case 'UPDATE_USER':
      await updateUser(action, store);
      break;
    case 'SEND_FEEDBACK':
      await sendFeedback(action, store);
      break;
    case 'RESEND_VERIFICATION_EMAIL':
      await resendVerificationEmail(store);
      break;
    default:
      break;
  }

  return next(action);
};

export default userMiddleware;
