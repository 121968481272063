import React from 'react';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import DealDetails from 'components/DealDetails';
import {
  deleteDealAction,
  receiveDealAction,
  shareDealAction,
  updateDealAction,
  updateUserDealAction,
} from 'js/actions/dealActions';
import {
  receiveShareAction,
  sendShareAction,
  updateShareAction,
} from 'js/actions/shareActions';

const DealDetailsContainer = (props) => {
  const { dealId } = useParams();
  const {
    contacts,
    deals,
    deleteDeal,
    error,
    isDeleteDealLoading,
    isSendShareLoading,
    isShareDealLoading,
    isUpdateDealLoading,
    isUpdateUserDealNotesLoading,
    receiveDeal,
    receiveShare,
    sendShare,
    shareDeal,
    shares: allShares,
    updateDeal,
    updateShare,
    updateUserDeal,
    user,
  } = props;

  const shares = allShares.filter(({ dealId: shareDealId }) => (
    dealId === shareDealId
  ));

  const deal = deals.find(({ id }) => id === dealId);

  const {
    userDeal: { id: userDealId, notes, ...restOfUserDeal } = {},
  } = deal || {};

  const getRichNotes = () => {
    try {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(notes)),
      );
    } catch (e) {
      return EditorState.createWithContent(ContentState.createFromText(notes));
    }
  };

  const handleSaveNotes = (editorState) => {
    updateUserDeal({
      id: userDealId,
      loadingKey: 'isUpdateUserDealNotesLoading',
      notes: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const handleUpdate = (payload) => {
    updateDeal({ ...payload, id: dealId });
  };

  const handleUpdateStatus = (status) => {
    updateUserDeal({ id: userDealId, status });
    receiveDeal({
      ...deal,
      userDeal: {
        ...restOfUserDeal,
        id: userDealId,
        notes,
        status,
      },
    });
  };

  const handleSendShare = (payload) => {
    sendShare(payload);
  };

  const handleShare = (ids) => {
    shareDeal({ id: dealId, recipientIds: ids });
  };

  const handleUpdateInterest = (shareId, interest) => {
    const share = shares.find(({ id }) => shareId === id);
    updateShare({ id: shareId, interest });
    receiveShare({ ...share, interest });
  };

  return deal ? (
    <DealDetails
      contacts={contacts}
      deal={deal}
      error={error}
      isDeleteDealLoading={isDeleteDealLoading}
      isSendShareLoading={isSendShareLoading}
      isShareDealLoading={isShareDealLoading}
      isUpdateDealLoading={isUpdateDealLoading}
      isUpdateUserDealNotesLoading={isUpdateUserDealNotesLoading}
      onDelete={() => deleteDeal({ id: dealId })}
      onSaveNotes={handleSaveNotes}
      onSendShare={handleSendShare}
      onShare={handleShare}
      onUpdate={handleUpdate}
      onUpdateInterest={handleUpdateInterest}
      onUpdateStatus={handleUpdateStatus}
      richNotes={getRichNotes()}
      shares={shares}
      user={user}
    />
  ) : <Redirect to="/deals" />;
};

DealDetailsContainer.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteDeal: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isDeleteDealLoading: PropTypes.bool.isRequired,
  isSendShareLoading: PropTypes.bool.isRequired,
  isShareDealLoading: PropTypes.bool.isRequired,
  isUpdateDealLoading: PropTypes.bool.isRequired,
  isUpdateUserDealNotesLoading: PropTypes.bool.isRequired,
  receiveDeal: PropTypes.func.isRequired,
  receiveShare: PropTypes.func.isRequired,
  sendShare: PropTypes.func.isRequired,
  shareDeal: PropTypes.func.isRequired,
  shares: PropTypes.arrayOf(PropTypes.shape({
    dealId: PropTypes.string,
    recipientId: PropTypes.string,
  })).isRequired,
  updateDeal: PropTypes.func.isRequired,
  updateShare: PropTypes.func.isRequired,
  updateUserDeal: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    contacts: { contacts },
    deals: { deals },
    errors: { updateDealError },
    loading: {
      isDeleteDealLoading,
      isSendShareLoading,
      isShareDealLoading,
      isUpdateDealLoading,
      isUpdateUserDealNotesLoading,
    },
    shares: { shares },
    user,
  } = state;

  return {
    contacts,
    deals,
    error: updateDealError || {},
    isDeleteDealLoading,
    isSendShareLoading,
    isShareDealLoading,
    isUpdateDealLoading,
    isUpdateUserDealNotesLoading,
    shares,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteDeal: (payload) => dispatch(deleteDealAction(payload)),
  receiveDeal: (payload) => dispatch(receiveDealAction(payload)),
  receiveShare: (payload) => dispatch(receiveShareAction(payload)),
  sendShare: (payload) => dispatch(sendShareAction(payload)),
  shareDeal: (payload) => dispatch(shareDealAction(payload)),
  updateDeal: (payload) => dispatch(updateDealAction(payload)),
  updateUserDeal: (payload) => dispatch(updateUserDealAction(payload)),
  updateShare: (payload) => dispatch(updateShareAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealDetailsContainer);
