import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UploadForm from 'components/UploadForm';
import { uploadDealsAction } from 'js/actions/dealActions';
import { csvHeaders, fieldOptions } from 'js/constants/deals';

const UploadDealsContainer = (props) => {
  const {
    error,
    isLoading,
    isVisible,
    onClose,
    uploadDeals,
  } = props;

  return (
    <UploadForm
      error={error}
      fieldOptions={fieldOptions}
      headers={csvHeaders}
      template="/files/deals.csv"
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSubmit={uploadDeals}
    />
  );
};

UploadDealsContainer.propTypes = {
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadDeals: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { uploadDealsError },
    loading: { isUploadDealsLoading },
  } = state;

  return {
    error: uploadDealsError || {},
    isLoading: isUploadDealsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadDeals: (payload) => dispatch(uploadDealsAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadDealsContainer);
