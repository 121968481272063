import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import Search from 'components/Search';
import { ReactComponent as ChevronLeft } from 'assets/images/chevron-left.svg';
import logoColouredBlue from 'assets/images/logo-coloured-blue.png';
import logoColouredWhite from 'assets/images/logo-coloured-white.png';
import './styles.scss';

const Navbar = (props) => {
  const {
    isExpanded,
    onLogOut,
    onToggle,
    onSearch,
    back: { display, path },
    user: { firstName, lastName, token },
    searchResult,
  } = props;

  const history = useHistory();

  const [search, setSearch] = useState('');

  const handleSearch = (val) => {
    setSearch(val);

    if (!val) onSearch('');
    if (val.length <= 3) return;

    onSearch(val);
  };

  const clearSearch = () => {
    setSearch('');
    onSearch(null);
  };

  let authenticatedLinks;

  if (display) {
    authenticatedLinks = (
      <Nav className="navbar-middle flex-grow-1">
        <div className="mb-3 mb-md-0 px-5">
          {path ? (
            <Link
              to={`/${path}`}
              className="large-text dark-blue-text no-underline"
            >
              <ChevronLeft className="back-icon" />
              Back to {display}
            </Link>
          ) : (
            <button
              type="button"
              className="large-text dark-blue-text no-underline"
              onClick={() => history.goBack()}
            >
              <ChevronLeft className="back-icon" />
              Go Back
            </button>
          )}
        </div>
      </Nav>
    );
  }

  return token ? (
    <BootstrapNavbar
      expand="md"
      expanded={isExpanded}
      onToggle={onToggle}
      fixed="top"
      className="small-navbar"
    >
      <BootstrapNavbar.Brand className="align-self-center">
        <Link to="/">
          <img src={logoColouredBlue} alt="logo" className="full-width" />
        </Link>
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        {authenticatedLinks}
        <div className="flex-grow-1 search-container">
          <span className="mx-5 search-content">
            <Search
              displayValue={search}
              onChange={(val) => handleSearch(val)}
            />
            {search && (
              <div className="p-2 mt-1 search-result">
                {searchResult
                && ((searchResult.deals && searchResult.deals.length > 0)
                  || (searchResult.contacts
                    && searchResult.contacts.length > 0)) ? (
                      <>
                        <div className="pl-1 search-head">Deals</div>
                        {searchResult.deals.length > 0 ? (
                          searchResult.deals.map((deal) => (
                            <Link
                              to={`/deals/${deal.id}`}
                              onClick={() => clearSearch('')}
                              className="d-block py-2 px-3"
                              key={deal.id}
                            >
                              {deal.company}
                            </Link>
                          ))
                        ) : (
                          <div className="py-2 text-center">No deals found</div>
                        )}
                        <div className="pl-1 pt-2 search-head">Contacts</div>
                        {searchResult.contacts.length > 0 ? (
                          searchResult.contacts.map((contact) => (
                            <Link
                              to={`/contacts/${contact.id}`}
                              onClick={() => clearSearch('')}
                              className="d-block py-2 px-3"
                              key={contact.id}
                            >
                              {contact.firstName} {contact.lastName}
                            </Link>
                          ))
                        ) : (
                          <div className="py-2 text-center">No contacts found</div>
                        )}
                      </>
                  ) : (
                    <div className="py-2 text-center">
                      {search.length > 3
                        ? 'No results found'
                        : 'Please enter at least 4 characters to search.'}
                    </div>
                  )}
              </div>
            )}
          </span>
        </div>
        <Nav className="navbar-right">
          <div className="mb-3 mb-md-0">
            <Dropdown>
              <Dropdown.Toggle className="dropdown-nav">
                <span className="name-icon">
                  {firstName[0]}
                  {lastName[0]}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu align="right">
                <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                <Dropdown.Item as="button" onClick={onLogOut}>
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  ) : (
    <BootstrapNavbar
      expand="md"
      expanded={isExpanded}
      onToggle={onToggle}
      className="large-navbar"
    >
      <BootstrapNavbar.Brand className="align-self-center">
        <Link to="/">
          <img src={logoColouredWhite} alt="logo" className="full-width" />
        </Link>
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="text-right navbar-right">
          <div className="mb-3 mb-md-0">
            <Link to="/sign-up" className="small-text light-gray-text">
              Sign Up
            </Link>
          </div>
          <span className="mx-md-3 small-text light-gray-text">|</span>
          <div className="mb-3 mb-md-0">
            <Link to="/log-in" className="small-text light-gray-text">
              Log In
            </Link>
          </div>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

Navbar.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onLogOut: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  back: PropTypes.shape({
    display: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    token: PropTypes.string,
  }).isRequired,
  searchResult: PropTypes.shape({
    deals: PropTypes.arrayOf(PropTypes.shape({})),
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default Navbar;
