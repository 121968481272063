import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BootstrapModal from 'react-bootstrap/Modal';

const Modal = (props) => {
  const { onHide, show } = props;

  const handleKeyDown = (e) => {
    e.stopPropagation();

    if (e.key === 'Escape') {
      onHide();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('keydown', handleKeyDown, true);
    } else {
      document.removeEventListener('keydown', handleKeyDown, true);
    }

    return () => document.removeEventListener('keydown', handleKeyDown, true);
  }, [show]);

  return (
    <BootstrapModal {...props} />
  );
};

Modal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Modal;
