import React from 'react';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import ContactDetails from 'components/ContactDetails';
import {
  deleteContactAction,
  receiveContactAction,
  shareWithContactAction,
  updateContactAction,
} from 'js/actions/contactActions';
import {
  receiveShareAction,
  sendShareAction,
  updateShareAction,
} from 'js/actions/shareActions';
import { sendInviteAction } from 'js/actions/inviteActions';

const ContactDetailsContainer = (props) => {
  const { contactId } = useParams();
  const {
    contacts,
    deals,
    deleteContact,
    isDeleteContactLoading,
    isSendInviteLoading,
    isSendShareLoading,
    isShareWithContactLoading,
    isUpdateContactNotesLoading,
    receiveContact,
    receiveShare,
    sendInvite,
    sendShare,
    shares: allShares,
    shareWithContact,
    updateContact,
    updateShare,
    user,
  } = props;

  const shares = allShares.filter(({ recipientId }) => (
    contactId === recipientId
  ));

  const contact = contacts.find(({ id }) => id === contactId);

  const { notes } = contact || {};

  const getRichNotes = () => {
    try {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(notes)),
      );
    } catch (e) {
      return EditorState.createWithContent(ContentState.createFromText(notes));
    }
  };

  const handleSaveNotes = (editorState) => {
    updateContact({
      id: contactId,
      loadingKey: 'isUpdateContactNotesLoading',
      notes: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const handleSendInvite = (payload) => {
    sendInvite(payload);
    receiveContact({ ...contact, isInvited: true });
  };

  const handleSendShare = (payload) => {
    sendShare(payload);
  };

  const handleShare = (ids) => {
    shareWithContact({ id: contactId, dealIds: ids });
  };

  const handleUpdateInterest = (shareId, interest) => {
    const share = shares.find(({ id }) => shareId === id);
    updateShare({ id: shareId, interest });
    receiveShare({ ...share, interest });
  };

  return contact ? (
    <ContactDetails
      contact={contact}
      deals={deals}
      isDeleteContactLoading={isDeleteContactLoading}
      isSendInviteLoading={isSendInviteLoading}
      isSendShareLoading={isSendShareLoading}
      isShareWithContactLoading={isShareWithContactLoading}
      isUpdateContactNotesLoading={isUpdateContactNotesLoading}
      onDelete={() => deleteContact({ id: contactId })}
      onSaveNotes={handleSaveNotes}
      onSendInvite={handleSendInvite}
      onSendShare={handleSendShare}
      onShare={handleShare}
      richNotes={getRichNotes()}
      shares={shares}
      onUpdateInterest={handleUpdateInterest}
      user={user}
    />
  ) : <Redirect to="/contacts" />;
};

ContactDetailsContainer.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteContact: PropTypes.func.isRequired,
  isDeleteContactLoading: PropTypes.bool.isRequired,
  isSendInviteLoading: PropTypes.bool.isRequired,
  isSendShareLoading: PropTypes.bool.isRequired,
  isShareWithContactLoading: PropTypes.bool.isRequired,
  isUpdateContactNotesLoading: PropTypes.bool.isRequired,
  receiveContact: PropTypes.func.isRequired,
  receiveShare: PropTypes.func.isRequired,
  sendInvite: PropTypes.func.isRequired,
  sendShare: PropTypes.func.isRequired,
  shares: PropTypes.arrayOf(PropTypes.shape({
    dealId: PropTypes.string,
    recipientId: PropTypes.string,
  })).isRequired,
  shareWithContact: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  updateShare: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    contacts: { contacts },
    deals: { deals },
    loading: {
      isDeleteContactLoading,
      isSendInviteLoading,
      isSendShareLoading,
      isShareWithContactLoading,
      isUpdateContactNotesLoading,
    },
    shares: { shares },
    user,
  } = state;

  return {
    contacts,
    deals,
    isDeleteContactLoading,
    isSendInviteLoading,
    isSendShareLoading,
    isShareWithContactLoading,
    isUpdateContactNotesLoading,
    shares,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteContact: (payload) => dispatch(deleteContactAction(payload)),
  receiveContact: (payload) => dispatch(receiveContactAction(payload)),
  receiveShare: (payload) => dispatch(receiveShareAction(payload)),
  sendInvite: (payload) => dispatch(sendInviteAction(payload)),
  sendShare: (payload) => dispatch(sendShareAction(payload)),
  shareWithContact: (payload) => dispatch(shareWithContactAction(payload)),
  updateContact: (payload) => dispatch(updateContactAction(payload)),
  updateShare: (payload) => dispatch(updateShareAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactDetailsContainer);
