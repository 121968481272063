import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import './styles.scss';

const PasswordInput = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const type = isVisible ? 'text' : 'password';
  const { theme } = props;
  const textColor = theme === 'light' ? 'blue-gray-text' : 'white-text';

  return (
    <>
      <Form.Control {...props} type={type} />
      <button
        type="button"
        onClick={() => setIsVisible(!isVisible)}
        className={`show-password-button ${textColor}`}
      >
        {isVisible ? 'HIDE' : 'SHOW'}
      </button>
    </>
  );
};

PasswordInput.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default PasswordInput;
